import { createSlice } from '@reduxjs/toolkit';
import actions from './actions';
import { NAME_REDUCER } from './constants';
import { IState } from './types/reducer';

const initialState: IState = {
  shopId: '',
  staffs: [],
  staffTickets: [],
  turns: [],
  loading: true,
};

export const Slice = createSlice({
  name: NAME_REDUCER,
  initialState,
  reducers: {
    initData: (state, { payload }: { payload: string }) => {
      state.shopId = payload;
      state.loading = true;
    },
    setLoading: (state, { payload }) => {
      state.loading = payload;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(actions.data.turns.success, (state, { payload }) => {
        state.turns = payload;
      })
      .addCase(actions.getOpenTicketsByStaffId.success, (state, { payload }) => {
        state.staffTickets = payload;
      })
      .addCase(actions.data.listStaffs.success, (state, { payload }) => {
        state.staffs = payload;
      })
      .addCase(actions.data.listStaffs.fail, (state) => {
        state.staffs = [];
      })
      ;
  },
});

export const turnUIActions = Slice.actions;
const turnServiceReducer = Slice.reducer;
export default turnServiceReducer;
