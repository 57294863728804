import { IStaffUI } from 'types/staff';
import avatar from 'assets/anyone.png';

export const ANYONE_ID = '0';

export const ANYONE: IStaffUI = {
  avatar,
  extraData: null,
  id: ANYONE_ID,
  name: 'Anyone',
};