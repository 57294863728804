import { Button } from 'antd';
import Text from 'components/Text';
import React from 'react';
import styled from 'styled-components';
import { IServiceUI } from 'types/service';
import { formatCurrency } from 'utils/formatCurrency';

type Props = {
  data: IServiceUI;
  active?: boolean;
  onClick?: () => void;
};

const SelectedService = ({ data, active, onClick }: Props) => {
  return (
    <ServiceStyled className={`${active ? 'active' : ''}`}>
      <Content>
        <Text className="name">{data.name}</Text>
        <Text className="price">{formatCurrency(data.price)}</Text>
        <Button onClick={onClick} style={{ height: '35px', width: '35px', display: 'flex', alignItems: 'center', justifyContent: 'center', padding: 0 }}><IconRemove /></Button>
      </Content>
    </ServiceStyled>
  );
};

export const SelectedServiceEmpty = () => {
  return (
    <ServiceStyled className='empty'>
      <Text className="label" style={{ textAlign: 'center' }}>{'Empty'}</Text>
    </ServiceStyled>
  );
};

export default SelectedService;

export const ServiceStyled = styled.button`
width: 100%;
display: flex;
align-items: center;
border-radius: 5px;
border: 1px solid #CECECE;
background: #F7F9FA;
height: 50px;
padding: 0 5px;

  img {
    width: 75px;
    height: 75px;
    border-radius: 5px;
  }

  &:hover {
    background: #FFEFEB;
  }

  &.active {
    background: #c5ddf86e;
    border: 1px solid #148CF1;
  }
  &.empty {
    border-radius: 5px;
    border: 1px solid #7B7B7B;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #EEE;
    pointer-events: none;
    .label {
      overflow: hidden;
      color: #7B7B7B;
      text-align: center;
      text-overflow: ellipsis;
      font-family: Poppins;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
    &:hover {
      border: 1px solid #7B7B7B;
      background: #EEE;
    }
  }
`;
const Content = styled.div`
  flex-grow: 1;
  padding-left: 8px;
  display: flex;
  justify-content: space-between;
  gap: 8px;
  .name {
    overflow: hidden;
    color: #1D2129;
    text-overflow: ellipsis;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-align: left;
    flex:1;

    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    align-self: stretch;
  }

  .price {
    color: #1D2129;
    font-family: Poppins;
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
`;

const IconRemove = () => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="Group 625458">
      <path id="Vector (Stroke)" fillRule="evenodd" clipRule="evenodd" d="M17.1823 0.817268C17.6342 1.26917 17.6342 2.00185 17.1823 2.45376L2.45385 17.1822C2.00194 17.6341 1.26926 17.6341 0.817358 17.1822C0.365454 16.7303 0.365453 15.9976 0.817358 15.5457L15.5458 0.817268C15.9977 0.365364 16.7304 0.365364 17.1823 0.817268Z" fill="#1D2129" />
      <path id="Vector (Stroke)_2" fillRule="evenodd" clipRule="evenodd" d="M0.817741 0.817268C1.26965 0.365364 2.00233 0.365364 2.45423 0.817268L17.1826 15.5457C17.6345 15.9976 17.6345 16.7303 17.1826 17.1822C16.7307 17.6341 15.9981 17.6341 15.5462 17.1822L0.817741 2.45376C0.365837 2.00185 0.365837 1.26917 0.817741 0.817268Z" fill="#1D2129" />
    </g>
  </svg>
);
