import Text from 'components/Text';
import { memo, useCallback, useMemo } from 'react';
import CalendarStyled, { fixedStyle } from '../styles';
import CalendarItem from './CalendarItem';
import Droppable from './DnD/Droppable';
import { get } from 'lodash';
import moment from 'moment';
import { FORMAT_TIME } from 'utils/format';
import { IScheduleBooking, IScheduleBookingDateItem } from 'services/types/shop';
import { ICalendarDndData, ICalendarViewColItem, ICalendarViewRowItem, ICalendarViewRowSpanInfoItem } from 'Calendar/types';
import { ANYONE_ID } from 'Calendar/anyone';
import clsx from 'clsx';

const TIME_VALUE_FORMAT = 'HH:mm';

type Props = {
  data: ICalendarViewRowItem,
  rowsSpanInfo: ICalendarViewRowSpanInfoItem[],
  isSingleCol?: boolean;
  distance: number;
  index: number;
  scheduleBooking: IScheduleBooking | null;
  setActiveTimeRow: (value: string) => void;
};
const CalendarViewRow = ({ data, rowsSpanInfo, index, scheduleBooking, setActiveTimeRow }: Props) => {
  const { colData, rowTime } = data;

  const renderColumn = useCallback((col: ICalendarViewColItem, index: number) => {
    const dropId = data.id + '/' + col.id;
    const dropData: ICalendarDndData = { rowId: data.id, colData: col, rowTime: data.rowTime };

    const scheduleBookingDate: IScheduleBookingDateItem | null = get(scheduleBooking, [col.rowTime.format('ddd')], null) || null;
    let disable = false;
    if (scheduleBookingDate) {
      const colTime = moment(col.rowTime.format(TIME_VALUE_FORMAT), TIME_VALUE_FORMAT);
      const startWorkingDate = moment(scheduleBookingDate?.startTime, 'HH:mm');

      const isBetweenWorkingHour = colTime.isSame(startWorkingDate) || colTime.isBetween(
        startWorkingDate,
        moment(scheduleBookingDate?.endTime, 'HH:mm')
      );
      disable = !scheduleBookingDate?.enable || !isBetweenWorkingHour;
    }

    const isFuture = col.rowTime.isSameOrAfter(moment());

    if (!isFuture) {
      disable = true;
    }

    const orderGroup = rowsSpanInfo.find(it =>
      rowTime.isSameOrBefore(it?.rowEnd.value) &&
      it.colId === col.id &&
      col.data.some(i => it.orderIds.includes(i?.id?.toString())),
    );

    const isExact = !!orderGroup && orderGroup.rowStart.id === data.id;
    if (isExact) {
      return (
        <CalendarStyled.TD anyone={col.id === ANYONE_ID} key={col.id + 'col'} rowSpan={orderGroup?.rowSpan || 1}
          className={clsx(
            col.rowTime.format('DD-MM-YYYY HH:mm'), 'CalendarStyled_isExact', dropId, col.id,
            index === 0 ? 'first-col' : '', col.id === ANYONE_ID ? 'fixed-time-anyone' : '',
            disable && 'disable',
          )}
        >
          <Droppable disabled={disable} rowSpan={orderGroup?.rowSpan || 1} key={dropId} id={dropId} data={dropData}>
            <CalendarItem disabled={disable} data={col.data} headerData={col.headerData} />
          </Droppable>
        </CalendarStyled.TD>
      );
    }
    const isBefore = !!orderGroup;

    if (isBefore) return <CalendarStyled.TD
      id={dropId}
      key={col.id + 'col'}
      className={clsx(
        col.rowTime.format('DD-MM-YYYY HH:mm'), 'CalendarStyled_isBefore', dropId, col.id, index === 0 ? 'first-col' : '',
        disable && 'disable',
      )}
    >
      <Droppable disabled={disable} key={dropId} id={dropId} data={dropData} />
    </CalendarStyled.TD>;

    return (
      <CalendarStyled.TD
        anyone={col.id === ANYONE_ID} id={dropId} key={col.id}
        className={clsx(
          col.rowTime.format('DD-MM-YYYY HH:mm'), `to_${col.nextRowTime}`, 'CalendarStyled_none',
          dropId, col.id,
          index === 0 ? 'first-col' : '',
          col.id === ANYONE_ID ? 'fixed-time-anyone' : '',
          disable && 'disable',
        )}
      >{
          <Droppable disabled={disable} key={dropId} id={dropId} data={dropData} />
        }</CalendarStyled.TD>
    );
  }, [data, rowsSpanInfo, scheduleBooking]);

  const time = useMemo(() => {
    return ({
      label: data.rowTime.format(FORMAT_TIME),
      strong: data.rowTime.format('mm') === '00',
      value: `to_${data.rowTime.format('hh-mm-A')}`,
    });
  }, [data]);

  return (
    <CalendarStyled.TRow className={`${index === 0 ? 'first-row' : ''}`}>
      <CalendarStyled.TD className={`fixed-time-td ${time.value}_time`} fixed time style={fixedStyle}>
        <div className="box-time" style={{ textAlign: 'right' }}>
          <button type='button' onClick={() => setActiveTimeRow(time.value)}>
            <Text className='text-time' textAlign='right' variant='CAPTION_3' style={{ fontWeight: time.strong ? '700' : '400', fontSize: time.strong ? '0.85rem' : '0.75rem' }} >{time.label}</Text>
          </button>
        </div>
      </CalendarStyled.TD>
      {colData.length > 0 ? colData.map(renderColumn) : <CalendarStyled.TD className='first-col' />}
    </CalendarStyled.TRow>
  );
};

export default memo(CalendarViewRow);
