import { Row } from 'antd';
import CalendarStyled, { fixedStyle, isModeFull } from './styles';
import { ICalendarHeader, ICalendarSchema } from './types';
import { ANYONE } from './anyone';
import { getColorSet } from './helper';
type Props = {
  headers: ICalendarHeader<any>[];
  renderHeader: ICalendarSchema['renderHeader'];
};
const CalendarViewHeader = ({ headers = [], renderHeader = () => undefined }: Props) => {
  return (
    <CalendarStyled.THead>
      <CalendarStyled.TRow>
        <CalendarStyled.TH className='fixed-time-td' fixedHigh style={fixedStyle}>
          <Row className='actions' justify={'space-between'} />
        </CalendarStyled.TH>
        {headers.length > 0 ? headers.map((item) => {
          const colorSet = getColorSet(item.typeColor);
          return (
            <CalendarStyled.TH
              key={item.id}
              fixed
              className='fixed-time-anyone'
              anyone={item.id === ANYONE.id}
              style={isModeFull.value ? {
                width: (100 / headers.length) + '%',
              } : {}}
              colorSet={colorSet}
            >
              <div className='wrap'>
                {renderHeader(item)}
              </div>
            </CalendarStyled.TH>
          );
        }) : <>
          <CalendarStyled.TH fixed />
        </>}
      </CalendarStyled.TRow>
    </CalendarStyled.THead>
  );
};

export default CalendarViewHeader;
