import turnActions from 'Turn/services/actions';
import turnApis from 'Turn/services/apis';
import { IStaffTurnItem, ITicketTurnItem } from 'Turn/services/types/staff';
import { Spin } from 'antd';
import { AxiosResponse } from 'axios';
import { useMemo, useState } from 'react';
import { useAppDispatch } from 'store/hooks';
import styled from 'styled-components';
import Modal from '../Modal';
import usePassCodeVerify from '../PassCodeVerify';
import TurnDashboardTable from './TurnTable';
import { turnDashboard } from './signal';

const TurnDashboard = () => {
  const [loading, setLoading] = useState(false);
  const dispatch = useAppDispatch();
  const [verifyRef, pinCodeContext] = usePassCodeVerify();
  const onRefresh = async () => {
    setLoading(true);
    try {
      const [res, resTurns]: [AxiosResponse<{ payload: IStaffTurnItem[] }>, AxiosResponse<{ payload: ITicketTurnItem[] }>] = await Promise.all([turnApis.getListStaff(), turnApis.getAllTurnsData()]);
      if (res.data.payload) {
        dispatch(turnActions.data.listStaffs.success(res.data.payload));
      }
      if (resTurns.data.payload) {
        dispatch(turnActions.data.turns.success(resTurns.data.payload));
      }
    } catch (error) { }
    finally {
      setLoading(false);
    }
  };

  const onClearTurn = () => {
    verifyRef.current?.checkAdmin(async () => {
      setLoading(true);
      try {
        const res: { data: { payload: boolean } } = await turnApis.clearTurn();
        if (res.data.payload) {
          await onRefresh();
        }
      } catch (error) {
      }
      finally {
        setLoading(false);
      }
    }, {
      title: 'CLEAR TURN',
      placeholder: 'Enter Admin Pin Code',
    });
  };

  const rightButton = useMemo(() => {
    return (
      <>
        <button onClick={onClearTurn} style={{ width: 'unset', padding: '0 16px' }} >
          <p className="text-action">Clear</p></button>
        <button onClick={onRefresh} style={{ width: 'unset', padding: '0 16px' }} >
          <p className="text-action">Refresh</p>
        </button>
      </>
    );
  }, []);

  return (
    <Modal onCancel={turnDashboard.close} open={turnDashboard.openSignal.value} title='TURN DASHBOARD' width='auto' height={'auto'} rightButton={rightButton}>
      <Spin spinning={loading}>
        <Container>
          <TurnDashboardTable />
        </Container>
      </Spin>
      {pinCodeContext}
    </Modal>
  );
};

export default TurnDashboard;

const Container = styled.div`
  max-height: 80vh;
  position: relative;
`;

