import { Row } from 'antd';
import DatePicker from './DatePicker';
import SettingConfigs from './SettingConfigs';
import styled from 'styled-components';
import AppointmentHint from './AppointmentHint';

const CalendarFilter = () => {
  return (
    <CalendarFilterStyled justify={'space-between'}>
      <DatePicker />
      <SettingConfigs />
      <AppointmentHint />
    </CalendarFilterStyled>
  );
};

export default CalendarFilter;
const CalendarFilterStyled = styled(Row)`
  padding: 0 1.5rem;
  background: #EAEDED;
  position: relative;
`;
