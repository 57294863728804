
import React, { useEffect, useMemo, useState } from 'react';
import Modal from '../Modal';
import { IStaffTurnItem, IStaffTurnTicketItem } from 'Turn/services/types/staff';
import { Avatar, Form, Radio, Spin } from 'antd';
import styled from 'styled-components';
import { formatCurrency } from 'utils/formatCurrency';
export type FinishTurnModalProps = {
  visible: boolean;
  detail: IStaffTurnItem | null;
  onClose: () => void;
  ticketData: IStaffTurnTicketItem | null;
  onApply: (data: IStaffTurnTicketItem, turnValue: number) => void;
};
const FinishTurnModal = ({ visible, onClose, detail, onApply, ticketData }: FinishTurnModalProps) => {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const onFinishForm = async (values: any) => {
    if (!ticketData) return;
    setLoading(true);
    try {
      await onApply(ticketData, values.turn);
    } catch (error) { } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    if (!visible) return;
    form.resetFields();
  }, [visible]);

  const rightButton = useMemo(() => {
    return (
      <>
        <button onClick={() => form.submit()} style={{ width: 'unset', padding: '0 16px' }} >
          <p className="text-action">Apply</p>
        </button>
      </>
    );
  }, []);

  return (
    <Modal width='900px' height='auto' title={'FINISH TICKET'} open={visible} onCancel={onClose} rightButton={rightButton}>
      <Spin spinning={loading}>
        <FormStyled form={form} onFinish={onFinishForm} initialValues={{ turn: 0 }}>
          <StaffInfoStyled>
            <div className="staff-info">
              <Avatar src={detail?.urlImage} style={{ backgroundColor: '#f56a00' }}>{detail?.firstName?.[0] || 'A'}</Avatar>
            </div>
            <div>
              <div className="staff-name">{detail?.firstName} {detail?.lastName}</div>

              <div className="turn-info">
                <div className="turn-info-item turn">
                  <div>
                    <div className="turn-label">T</div>
                    <div className="turn-label-full">Turn</div>
                  </div>
                  <div className="turn-info-value">{detail?.turn || 0}</div>
                </div>
                <div className="turn-info-item bonus">
                  <div>
                    <div className="turn-label">B</div>
                    <div className="turn-label-full">Bonus</div>
                  </div>
                  <div className="turn-info-value">{detail?.totalBonus || 0}</div>
                </div>
                <div className="turn-info-item partial">
                  <div>
                    <div className="turn-label">P</div>
                    <div className="turn-label-full">Partial</div>
                  </div>
                  <div className="turn-info-value">{detail?.totalPartial || 0}</div>
                </div>
              </div>
            </div>
          </StaffInfoStyled>
          <div className='text-select'>Select turn value update for staff: <span className="box-sale"><span>Sale: {formatCurrency(ticketData?.total)}</span></span> </div>
          <Form.Item name={'turn'}>
            <Radio.Group size='large'>
              <Radio value={0}>0 Turn</Radio>
              <Radio value={0.5}>Haft Turn</Radio>
              <Radio value={1}>Complete Turn</Radio>
            </Radio.Group>
          </Form.Item>
        </FormStyled>
      </Spin>
    </Modal>
  );
};

export default FinishTurnModal;
const FormStyled = styled(Form)`
  padding: 0 16px;
  padding-top: 32px;
  .text-select {
    color: #7B7B7B;
    text-overflow: ellipsis;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin: 16px 0;
  }
  .box-sale {
    background: #7B7B7B;
    padding: 4px 8px;
    border-radius: 4px;
    span {
      color: #fff;
      text-overflow: ellipsis;
      font-family: Poppins;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
  }
  .ant-radio-group {
    width: 100%;
    display: flex;
    align-items: center;
    .ant-radio-wrapper {
      flex: 1;
      span {
        color: #1D2129;
        text-overflow: ellipsis;
        font-family: Poppins;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }
    }
  }
`;

const StaffInfoStyled = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 16px;
  border-bottom: 1px solid #CECECE;
  margin-bottom: 8px;

  .staff-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 116px;
  width: 116px;
  gap: 8px;
  position: relative;

  .ant-avatar {
    height: 100px;
    width: 100px;
    display: flex;
    align-items: center;
    z-index: 1;
    justify-content: center;

    .ant-avatar-string {
      font-size: 2.5rem;
    }
  }
}
.staff-name {
  color: #1D2129;
  text-overflow: ellipsis;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 16px;
}
.turn-info {
  display: flex;
  align-items: center;
  gap: 24px;
  .turn-info-item {
    display: flex;
    align-items: center;
    padding: 8px;
    border-radius: 4px;
    .turn-info-value, .turn-label {
      color: #1D2129;
      text-overflow: ellipsis;
      font-family: Poppins;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
    .turn-info-value {
      font-size: 24px;
      min-width: 100px;
      text-align: right;
    }

    &.turn {
      border: 1px solid #0CB76F;
      background: #e2fcf1;
    }
    &.bonus {
      border: 1px solid #148CF1;
      background: #d5e8f8;
    }
    &.partial {
      border: 1px solid #F05326;
      background: #f6e5e0;
    }
  }
}

`;