import React from 'react';
import MaskedInput from 'react-text-mask';
import styled from 'styled-components';
import createAutoCorrectedDatePipe from 'text-mask-addons/dist/createAutoCorrectedDatePipe';
const mask = [/\d/, /\d/, '/', /\d/, /\d/];
const hint = 'MM/DD';
const pipe = createAutoCorrectedDatePipe('mm/dd');

const getValue = value => value;

class BirthdayInput extends React.Component {

  static defaultProps = {};

  getValue = () => {
    if (!this.input) {
      return null;
    }

    return this.input.getValue();
  };

  triggerFocus = () => this.input.FormComponent.triggerFocus();

  render() {
    return (
      <CardNumberInputStyled>
        <MaskedInput
          mask={mask}
          maskHint={hint}
          pipe={pipe}
          getValue={getValue}
          ref={ref => (this.input = ref)}
          {...this.props}
          placeholder='MM/DD'
        />
      </CardNumberInputStyled>
    );
  }
}

export default BirthdayInput;


const CardNumberInputStyled = styled.div`
display: flex;
flex:1;
input {
  border-radius: 6px;
  border: 1px solid #86909C;
  background: #FFF;
  display: flex;
  width: 100%;
  height: 44px;
  align-items: center;
  padding: 0px 11px;

  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  line-height: 1.5;
  outline: none;
  &:focus, &:hover {
    outline: none;
    border-color: #fc7c51;
  }
  &::placeholder {
    color:#dadada;
  }
}
`;