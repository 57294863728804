import Text from 'components/Text';
import styled from 'styled-components';
import { ICustomerUI } from 'types/customer';
import { maskPhone } from 'utils/formatPhone';

type Props = {
  data: ICustomerUI;
  active: boolean;
  onClick?: () => void;
};

const Customer = ({ data, active, onClick }: Props) => {
  return (
    <CustomerStyled className={`${active ? 'active' : ''}`} onClick={onClick}>
      <Text className='name'>{data.name}</Text>
      <Text className='phone'>{maskPhone(data.phone)}</Text>
    </CustomerStyled>
  );
};

export default Customer;

const CustomerStyled = styled.button`
  width: 100%;
  border-radius: 13.584px;
  background: #FFF;
  box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.15);
  height: 15vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px solid #fff;

  &:hover {
    background: #FFEFEB;
  }

  .name {
    overflow: hidden;
    color: #1D2129;
    text-align: center;
    text-overflow: ellipsis;
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  .phone {
    overflow: hidden;
    color: #1D2129;
    text-align: center;
    text-overflow: ellipsis;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  &.active {
    border: 1px solid #148CF1;
    background: #148CF1;
    .name, .phone {
      color: #fff;
    }
  }
`;