import React from 'react';
import styled from 'styled-components';
import DatePicker from './DatePicker';
import qbSignal from './signal';
import { IDatePickerValue } from 'components/DatePicker';
import { Select } from 'antd';
import getTimeLines, { setTime } from 'utils/getTimeLines';

const timeLines = getTimeLines(setTime(9, 0), setTime(21, 0), 15).map(o => ({
  value: o.value.format('hh:mm A'),
  label: o.value.format('hh:mm A'),
}));

const durations = [
  { label: '15 minutes', value: 15 },
  { label: '30 minutes', value: 30 },
  { label: '45 minutes', value: 45 },
  { label: '1 hour', value: 60 },
  { label: '1.5 hours', value: 90 },
  { label: '3 hours', value: 180 },
];

const TimeSection = () => {
  const setCurrentDate = (date: IDatePickerValue) => {
    qbSignal.currentDate.value = date;
  };
  const handleChangeStartTime = (value: string) => {
    qbSignal.startTime.value = value;
  };
  const handleChangeDuration = (value: number) => {
    qbSignal.duration.value = value;
  };

  return (
    <TimeContainer>
      <div className='time-picker-item'>
        <div className="section-label">DATE</div>
        <DatePicker
          value={qbSignal.currentDate.value}
          onChange={setCurrentDate}
        />
      </div>
      <div className='time-picker-item'>
        <div className="section-label">START TIME</div>
        <Select
          value={qbSignal.startTime.value}
          onChange={handleChangeStartTime}
          options={timeLines}
        />
      </div>
      <div className='time-picker-item'>
        <div className="section-label">DURATION</div>
        <Select
          value={qbSignal.duration.value}
          onChange={handleChangeDuration}
          options={durations}
        />
      </div>
    </TimeContainer>
  );
};

export default TimeSection;

const TimeContainer = styled.div`
  display: grid; 
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 24px;
  .time-picker-item {
    display: flex;
    flex-direction: column; 
    gap: 8px;
    .ant-select-single {
      width: 100%;
      height: 50px;
      .ant-select-selection-item {
        font-size: 22px;
      }
    }
    .WrapDateTime {
      height: 50px;
      input {
        font-size: 22px;
      }
    }
    &.ant-form-large .ant-form-item .ant-form-item-label>label {
      height: 24px;
    }
  }
`;