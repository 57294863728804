import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { Client, Message, Subscription } from 'stompjs';
import { useAppDispatch } from 'store/hooks';
import storage from 'utils/sessionStorage';
import { APPOINTMENT_TOPIC, SYNC_TURN, initSocket } from 'utils/socket';
import aptActions from './actions/apt';
import turnActions from 'Turn/services/actions';

const getTopic = (topic: string) => {
  return `${topic}/${storage.shopId.get()}`;
};

type SocketContextProps = {
  socket: Client | null,
  connect: (shop_id: string) => void;
  unsubscribeAll: () => void;
};
export const SocketContext = React.createContext<SocketContextProps>({
  socket: null,
  connect: () => undefined,
  unsubscribeAll: () => undefined,
});

export const useSocketContext = () => React.useContext(SocketContext);

const useInitSocket = (): SocketContextProps => {
  const [socket, setSocket] = useState<Client | null>(null);
  const dispatch = useAppDispatch();

  const unSubArrays = useRef<Subscription[]>([]);
  const unSubPassDataArrays = useRef<Subscription[]>([]);
  const countingReconnect = useRef<number>(0);
  const waitingSubscribeArrays = useRef<{
    topic: string, listeningData: (message: Message) => void, disableDeviceId?: boolean;
  }[]>([]);

  const callBackReConnectSuccess = (_socket: Client, shopId: string) => {
    countingReconnect.current = 0;
    if (!_socket) return;
    if (waitingSubscribeArrays.current.length) {
      waitingSubscribeArrays.current.map(waitItem => {
        unSubPassDataArrays.current.push(
          _socket.subscribe(getTopic(waitItem.topic), waitItem.listeningData)
        );
      });
      waitingSubscribeArrays.current = [];
    }

    unSubArrays.current.push(
      _socket.subscribe(`${APPOINTMENT_TOPIC}/${shopId}`, () => dispatch(aptActions.calendar.getData.fetch({
        shopId,
        startTime: moment().format('YYYY-MM-DD')
      })))
    );
    unSubArrays.current.push(
      _socket.subscribe(`${SYNC_TURN}/${shopId}`, () => dispatch(turnActions.data.listStaffs.fetch()))
    );
  };


  const reconnect = (shopId: string) => {
    if (countingReconnect.current > 2) return;
    console.log('reconnect', countingReconnect.current);
    countingReconnect.current += 1;

    setTimeout(() => {
      const _socket = initSocket();
      setSocket(_socket);
      _socket.connect({}, () => callBackReConnectSuccess(_socket, shopId), () => reconnect(shopId));
    }, 120000);
  };

  const connect = (shopId: string) => {
    if (socket) {
      callBackReConnectSuccess(socket, shopId);
      return;
    }
    const _socket = initSocket();
    setSocket(_socket);
    _socket.connect({}, () => callBackReConnectSuccess(_socket, shopId), () => reconnect(shopId));
  };

  useEffect(() => {
    return () => socket?.disconnect(() => undefined);
  }, []);

  const unsubscribeAll = () => {
    unSubArrays.current.map(o => o.unsubscribe());
    unSubArrays.current = [];
  };

  return ({
    socket,
    connect,
    unsubscribeAll,
  });
};

export default useInitSocket;
