import { Row } from 'antd';
import styled from 'styled-components';

type TabLabelProps = {
  tabIndex: number;
  label: string;
  active?: boolean;
  completed?: boolean;
};
const TabLabel = ({ tabIndex, label, active, completed }: TabLabelProps) => (
  <TabLabelStyled align={'middle'} className={`prevent-select ${active ? 'active' : ''} ${completed ? 'completed' : ''}`}>
    <Row align={'middle'} justify={'center'} className="tabIndexBox">
      {completed ? <TickIcon /> : <p className="tabIndex">{tabIndex}</p>}
    </Row>
    <p className="label">{label}</p>
  </TabLabelStyled>
);

export default TabLabel;

const TabLabelStyled = styled(Row)`
  gap: 10px;
  padding: 0 8px;
  .tabIndexBox {
    border-radius: 32px;
    background: #CECECE;
    width: 28px;
    height: 28px;
    flex-shrink: 0;

    .tabIndex {
      color: #7B7B7B;
      text-align: center;
      font-family: Poppins;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px; /* 150% */
    }
  }

  .label {
    color: #7B7B7B;
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  &.active {
    .tabIndexBox {
      background: #232F3E;
      .tabIndex {
        color: #FFFFFF;
      }
    }

    .label {
      color: #1D2129;
    }
  }
  &.completed {
    .tabIndexBox {
      background: #232F3E;
      .tabIndex {
        color: #FFFFFF;
      }
    }

    .label {
      color: #1D2129;
    }
  }
`;

const TickIcon = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="tips/check">
      <path id="Vector" fillRule="evenodd" clipRule="evenodd" d="M6.34979 10.282L13.4209 3.21094L14.3637 4.15375L6.34979 12.1676L1.63574 7.45358L2.57855 6.51077L6.34979 10.282Z" fill="white" />
    </g>
  </svg>
);