import { Select } from 'antd';
import { APPOINTMENT_SOURCE_TYPE } from 'constants/calendar';
import aptActions from 'services/actions/apt';
import { tableSelectors } from 'services/selectors/apt';
import { useAppDispatch } from 'store/hooks';
import styled from 'styled-components';

const AptTypePicker = () => {
  const dispatch = useAppDispatch();
  const sourceType = tableSelectors.params();

  const value = sourceType.type === undefined || sourceType.type === -1 ? -1 : sourceType.type;

  const handleChange = (value: number) => {
    dispatch(aptActions.table.setParams({ type: value }));
  };

  return (
    <AptTypePickerStyled>
      <Select
        defaultValue={-1}
        value={value}
        style={{ width: '100%', height: '56px' }}
        onChange={handleChange}
        options={[
          { value: -1, label: 'All Type' },
          { value: APPOINTMENT_SOURCE_TYPE.CALl_IN, label: 'Call-In' },
          { value: APPOINTMENT_SOURCE_TYPE.WEBSITE, label: 'Website' },
        ]}
        suffixIcon={<DropdownIcon />}
      />
    </AptTypePickerStyled>
  );
};

export default AptTypePicker;

const AptTypePickerStyled = styled.div`
  flex:1;
  flex-grow: 1;
.ant-select .ant-select-selection-item {
  color: #1D2129 !important;
  text-overflow: ellipsis;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
`;

const DropdownIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path fillRule="evenodd" clipRule="evenodd" d="M3.49219 9.42969L4.9064 8.01547L11.9775 15.0865L19.0485 8.01548L20.4627 9.42969L12.6846 17.2078C12.6846 17.2078 12.6846 17.2079 11.9775 16.5008L12.6846 17.2078L11.9775 17.915L3.49219 9.42969Z" fill="#7B7B7B" />
  </svg>
);

