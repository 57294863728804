import { Form, Input, InputRef, Row, Select, Spin } from 'antd';
import { AxiosResponse } from 'axios';
import DateMonthInput from 'components/BirthdayInput';
import Modal from '../Modal';
import PhoneInput from 'components/PhoneInput';
import { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import shopActions from 'services/actions/shop';
import apis from 'services/apis';
import { IPayloadAddNewCustomer } from 'services/types/request';
import { IAddNewCustomerResData } from 'services/types/response';
import { useAppDispatch } from 'store/hooks';
import styled from 'styled-components';
import { ICustomerUI } from 'types/customer';
import storage from 'utils/sessionStorage';
import { formatPhone } from 'utils/formatPhone';

interface IAddCustomerFormValues {
  firstName: string;
  lastName: string;
  phoneNumber: string;
  email: string;
  birthday: string;
  gender: string;
}

type Props = {
  onSelect: (data: ICustomerUI) => void;
  onClick?: () => void;
};
type Ref = {
  setPhone: (value: string) => void;
};
export const useAddCustomerRef = () => useRef<Ref>(null);
const AddCustomer = forwardRef<Ref, Props>(({ onSelect, onClick = () => undefined }, ref) => {
  const inputRef = useRef<InputRef>(null);
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const [open, setOpen] = useState(false);

  const handleFinish = async (_values: any) => {
    const values = _values as IAddCustomerFormValues;
    const body: IPayloadAddNewCustomer = {
      birthDay: values.birthday,
      email: values.email,
      firstName: values.firstName,
      lastName: values.lastName,
      gender: values.gender,
      phone: values.phoneNumber?.replace(/[^0-9]/g, '')
    };
    setLoading(true);
    try {
      const res: AxiosResponse<{ payload: IAddNewCustomerResData | null }> = await apis.addNewCustomer(storage.shopId.get(), body);
      const data = res?.data?.payload;
      if (data) {
        const newCus: ICustomerUI = {
          id: data.id?.toString(),
          name: data.name,
          phone: data.phone,
        };
        dispatch(shopActions.afterCreateCustomer(newCus));
        onSelect(newCus);
        handleClose();
      }
    } catch (error) { } finally {
      setLoading(false);
    }
  };

  const handleOpen = () => {
    setOpen(true);
    onClick();
    setTimeout(() => {
      inputRef.current?.focus();
    }, 100);
  };

  const handleClose = () => {
    setOpen(false);
    form.resetFields();
  };

  useImperativeHandle(ref, () => ({
    setPhone: (value) => form.setFieldValue('phoneNumber', formatPhone(value)),
  }));

  return (
    <>
      <ButtonAddStyled onClick={handleOpen}>
        <IconButtonAdd />
      </ButtonAddStyled>
      <Modal
        open={open}
        onCancel={handleClose}
        onOk={() => form.submit()}
        title="ADD CUSTOMER"
        width={'60vw'}
        height={'auto'}
        okText="ADD"
        disabledOk={loading}
      >
        <Spin spinning={loading}>
          <FormStyled form={form} layout='vertical' size='large' onFinish={handleFinish} validateTrigger={['onSubmit']}>
            <Row className='group'>
              <Form.Item className='col-item' name={'firstName'} label={'FIRST NAME'} rules={[{ required: true }]}
              >
                <Input ref={inputRef} placeholder='Please enter' />
              </Form.Item>
              <Form.Item className='col-item' name={'lastName'} label={'LAST NAME'} rules={[{ required: true }]}
              >
                <Input placeholder='Please enter' />
              </Form.Item>
            </Row>
            <Row className='group'>
              <Form.Item className='col-item' name={'phoneNumber'} label={'PHONE NUMBER'} rules={[{ required: true }]}
              >
                <PhoneInput />
              </Form.Item>
              <Form.Item className='col-item' name={'email'} label={'EMAIL'} rules={[{ type: 'email' }]}>
                <Input placeholder='Please enter' />
              </Form.Item>
            </Row>
            <Row className='group'>
              <Form.Item className='col-item' name={'birthday'} label={'BIRTHDAY'}>
                <DateMonthInput />
              </Form.Item>
              <Form.Item className='col-item' name={'gender'} label={'GENDER'}>
                <Select
                  defaultValue="none"
                  options={[
                    { value: 'male', label: 'Male' },
                    { value: 'female', label: 'Female' },
                    { value: 'none', label: 'None' },
                  ]}
                />
              </Form.Item>
            </Row>
          </FormStyled>
        </Spin>
      </Modal>
    </>
  );
});

export default AddCustomer;
AddCustomer.displayName = 'AddCustomer';
const ButtonAddStyled = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const FormStyled = styled(Form)`
  padding: 1rem 1.5rem;
  .group {
    gap: 1.5rem;
  }
  .col-item {
    flex:1;
  }
  input.ant-input {
    line-height: 2;
    &::placeholder {
      color:#dadada;
    }
  }
  .ant-select-single.ant-select-lg {
    height: 46px;
  }
  &.ant-form-large .ant-form-item .ant-form-item-label>label {
    height: 24px;
  }
`;

const IconButtonAdd = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="46" height="46" viewBox="0 0 46 46" fill="none">
    <rect x="0.335938" y="0.335938" width="44.8641" height="44.8641" rx="5" fill="#232F3E" />
    <path d="M32.3745 25.322H25.5047V32.3735H20.7067V25.322H13.8369V20.8875H20.7067V13.8359H25.5047V20.8875H32.3745V25.322Z" fill="white" />
  </svg>
);
