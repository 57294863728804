import moment, { Moment } from 'moment';

export const getNextActiveTimeLot = () => {
  const now = moment();
  const nowTime = [0, 15, 30, 45, 60].map(o => ({ key: o, value: o - (now.get('minute')) })).filter(o => o.value >= 0);

  const minute = nowTime.find(o => o.value === Math.min(...nowTime.map(o => o.value)))?.key;

  const startTime = moment().set({
    hour: now.get('hour'),
    minute: minute ?? now.get('minute'),
    second: 0,
  });
  return startTime;
};

type ITimelineItem = {
  id: string;
  value: Moment;
}

export const getTimeLines = (startTime: Moment, endTime: Moment, distance?: number) => {
  const _distance = distance || 30;
  const times: ITimelineItem[] = [];
  let time = startTime;

  while (time <= endTime) {
    times.push({
      id: time.format('hh:mm A'),
      value: time,
    });
    time = time.clone().add(_distance, 'minute');
  }
  return times;
};

export const getActiveTimeSlots = (date: Moment | null | undefined, full?: boolean) => {
  if (!date) return [];
  const now = moment();
  let startTime = moment().set({
    hour: 9,
    minute: 0,
    second: 0,
  });
  const endTime = moment().set({
    hour: 21,
    minute: 0,
    second: 0,
  });
  if (date.isSame(now, 'date') && !full) {
    startTime = getNextActiveTimeLot();
  }

  return getTimeLines(startTime, endTime, 15);
};

export const ALL_DAY_FLAT = 9999;
export const FORMAT_DATE_PAYLOAD = 'MM-DD-YYYY HH:mm:ss';
export const timeLogOpts = [
  { label: '15 mintutes', value: 15 },
  { label: '30 mintutes', value: 30 },
  { label: '45 mintutes', value: 45 },
  { label: '60 mintutes', value: 60 },
  { label: '2 hours', value: 2 * 60 },
  { label: '4 hours', value: 4 * 60 },
  { label: 'All day', value: ALL_DAY_FLAT },
];
