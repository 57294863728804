import turnActions from 'Turn/services/actions';
import turnApis from 'Turn/services/apis';
import turnSelectors from 'Turn/services/selectors';
import { IStaffTurnTicketItem } from 'Turn/services/types/staff';
import { Avatar, Row, Spin } from 'antd';
import { useMemo, useState } from 'react';
import { useAppDispatch } from 'store/hooks';
import styled from 'styled-components';
import AdjustTip from '../AdjustTip';
import Modal from '../Modal';
import usePassCodeVerify from '../PassCodeVerify';
import InfoTurn from './InfoTurn';
import TurnFooter from './TurnFooter';
import turnWKSignal from './signal';
import FinishTurnModal, { FinishTurnModalProps } from '../FinishTurn';

const TurnStaffDetail = () => {
  const dispatch = useAppDispatch();
  const [visibleAdjustTurn, setVisibleAdjustTurn] = useState(false);
  const [verifyRef, pinCodeContext] = usePassCodeVerify();
  const staffs = turnSelectors.staffs();
  const [loading, setLoading] = useState(false);
  const onCancel = () => turnWKSignal.close();
  const [draftTicketFinishTurn, setDraftTicketFinishTurn] = useState<IStaffTurnTicketItem | null>(null);

  const detail = useMemo(() => staffs?.find(o => o.id === turnWKSignal.staffId.value) || null, [staffs, turnWKSignal.staffId.value]);

  const title = useMemo(() => {
    return <Row className='modal-custom-title'>
      <div className="staff-info" style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
        <Avatar src={detail?.urlImage || 'fail'} style={{ backgroundColor: '#f56a00', height: 40, width: 40 }}>{'A'}</Avatar>
        <div className="staff-name">{detail?.firstName}</div>
      </div>
    </Row>;
  }, [detail]);

  const onFinishTurnTicket = async (o: IStaffTurnTicketItem) => {
    if (!detail?.id) return;
    verifyRef.current?.checkAdmin(async () => {
      setDraftTicketFinishTurn(o);
    }, {
      title: 'FINISH TICKET',
      placeholder: 'Enter Admin Pin Code'
    });
  };

  const handleFinishTurnTicket: FinishTurnModalProps['onApply'] = async (bill, turnValue) => {
    if (!detail) return;
    try {
      const res: { data: { payload: boolean } } = await turnApis.finishTurnTicketByStaffId(detail?.id, bill?.billId, turnValue);
      if (res.data.payload) {
        dispatch(turnActions.getOpenTicketsByStaffId.fetch(detail?.id?.toString() || ''));
        setDraftTicketFinishTurn(null);
      }
    } catch (error) { }
  };

  const onClockOut = () => {
    if (!detail) return;

    verifyRef.current?.open(async (pinCode: string) => {
      try {
        const res: { data: { payload: boolean } } = await turnApis.checkOut(detail.id, pinCode);
        if (res.data.payload) {
          dispatch(turnActions.data.listStaffs.fetch());
          turnWKSignal.close();
          return true;
        }
        return false;
      } catch (error) {
        return false;
      }
    }, {
      title: 'CLOCK OUT',
      placeholder: 'Enter Staff Pin Code'
    });
  };

  const onFinishAllTurnTicket = () => {
    if (!detail) return;
    verifyRef.current?.checkAdmin(async () => {
      setLoading(true);
      try {
        const res: { data: { payload: boolean } } = await turnApis.finishAllTurnTicketsByStaffId(detail?.id);
        if (res.data.payload) {
          dispatch(turnActions.getOpenTicketsByStaffId.fetch(detail?.id?.toString() || ''));
        }
      } catch (error) { } finally {
        setLoading(false);
      }
    }, {
      title: 'FINISH ALL TICKETS',
      placeholder: 'Enter Admin Pin Code'
    });
  };

  const onAdjustTurn = () => {
    if (!detail) return;
    verifyRef.current?.checkAdmin(() => {
      setVisibleAdjustTurn(true);
    }, {
      title: 'ADJUST TURN',
      placeholder: 'Enter Admin Pin Code'
    });
  };

  const onRemoveLateTurn = () => {
    if (!detail) return;
    verifyRef.current?.checkAdmin(async () => {
      setLoading(true);
      try {
        const res: { data: { payload: boolean } } = await turnApis.removeLateTurn(detail.id);
        if (res.data.payload) {
          dispatch(turnActions.getOpenTicketsByStaffId.fetch(detail?.id?.toString() || ''));
        }
      } catch (error) {
      } finally {
        setLoading(false);
      }
    }, {
      title: 'REMOVE LATE TURN',
      placeholder: 'Enter Admin Pin Code',
    });
  };

  const adjustTurnHandler = (action: 'add' | 'minus') => async (turnValue: number) => {
    if (!detail?.id) return;
    const turn = action === 'minus' ? -turnValue : turnValue;
    setLoading(true);
    try {
      const res: { data: { payload: boolean } } = await turnApis.adjustTurn(detail.id, turn);
      if (res.data.payload) {
        dispatch(turnActions.getOpenTicketsByStaffId.fetch(detail?.id?.toString() || ''));
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal onCancel={onCancel} open={turnWKSignal.openSignal.value} title={title} width='1000px' height={'auto'}>
      <Spin spinning={loading}>
        <Container>
          <p className="modal-title">Tickets In Progress ...</p>
          <div className="section-body">
            <div className="divider" />
            <InfoTurn onFinishTurnTicket={onFinishTurnTicket} />
            <TurnFooter
              detail={detail}
              onAdjustTurn={onAdjustTurn}
              onClockOut={onClockOut}
              onFinishAllTurnTicket={onFinishAllTurnTicket}
              onRemoveLateTurn={onRemoveLateTurn}
            />
          </div>
        </Container>
      </Spin>
      {pinCodeContext}
      <AdjustTip visible={visibleAdjustTurn} onClose={() => setVisibleAdjustTurn(false)} onAdd={adjustTurnHandler('add')} onSubtract={adjustTurnHandler('minus')} />
      <FinishTurnModal
        visible={!!draftTicketFinishTurn}
        detail={detail}
        onApply={handleFinishTurnTicket}
        onClose={() => setDraftTicketFinishTurn(null)}
        ticketData={draftTicketFinishTurn}
      />
    </Modal>
  );
};

export default TurnStaffDetail;

const Container = styled.div`
background: #F6F6F6;
padding-top: 24px;
padding-bottom: 24px;
.modal-title {
  overflow: hidden;
  color: #F05326;
  text-align: center;
  text-overflow: ellipsis;
  font-family: Poppins;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
  margin-bottom: 24px;
}
.section-body {
  padding: 0 24px;
}
.divider {
  background-color: #CECECE;
  height: 1px;
  width: 100%;
  margin-bottom: 16px;
}

.section-title {
  color: #7B7B7B;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  text-transform: uppercase;
}

`;