import { signal } from '@preact/signals-react';
import { IStaffTurnItem } from 'Turn/services/types/staff';

const openSignal = signal<boolean>(false);
const staffId = signal<number | null>(null);

const turnWKSignal = {
  openSignal,
  staffId,
  open: (staffData: IStaffTurnItem) => {
    openSignal.value = true;
    staffId.value = staffData.id;
  },
  close: () => {
    openSignal.value = false;
    staffId.value = null;
  },
};

export default turnWKSignal;
