import { IStaffTurnItem } from 'Turn/services/types/staff';
import { Avatar } from 'antd';
import moment from 'moment';
import { useMemo } from 'react';
import styled from 'styled-components';
type StaffTurnType = 'working' | 'available' | 'non-clock-in';
type Props = {
  data: IStaffTurnItem;
  collapsed?: boolean;
  onClockOut?: () => void;
  onClockIn?: () => void;
};
const StaffTurnItem = ({ data, collapsed, onClockOut = () => undefined, onClockIn = () => undefined }: Props) => {
  // console.log(data.firstName, moment(data.timeUpdateTurn).format('MM-DD hh:mm:ss'));

  const type: StaffTurnType = useMemo(() => {
    if (data?.isBusy) return 'working';
    if (data.isLockedin) return 'available';
    return 'non-clock-in';
  }, [data]);

  const time = useMemo(() => {
    return moment(data.strClockInTime, 'hh:mm:ss A').format('HH:mm');
  }, [data]);

  if (collapsed) {
    switch (type) {
      case 'non-clock-in':
        return (
          <ContainerSM className='non-clock-in'>
            <div className="staff-info">
              <Avatar src={data?.urlImage} style={{ backgroundColor: '#f56a00' }}>{data.firstName?.[0] || 'A'}</Avatar>
            </div>
          </ContainerSM>
        );
      case 'available':
        return (
          <ContainerSM className={`available ${data.ticketNumbers?.length ? 'exist-tickets' : ''}`}>
            <div className="staff-info">
              <Avatar src={data?.urlImage} style={{ backgroundColor: '#f56a00' }}>{data.firstName?.[0] || 'A'}</Avatar>
              <div className="turn-total">
                <span>{data.turn}</span>
              </div>
            </div>
            <div className="turn-dashboard">
              <div className="turn-info-item">
                <span>B:</span>
                <span className='value'>0</span>
              </div>
              <div className="divider" />
              <div className="turn-info-item">
                <span>P:</span>
                <span className='value'>0</span>
              </div>
            </div>
          </ContainerSM>
        );
      case 'working':
        return (
          <ContainerSM className='working'>
            <div className="staff-info">
              <Avatar src={data?.urlImage} style={{ backgroundColor: '#f56a00' }}>{data.firstName?.[0] || 'A'}</Avatar>
              <div className="turn-total">
                <span>{data.turn}</span>
              </div>
            </div>
            <div className="turn-dashboard">
              <div className="turn-info-item">
                <span>B:</span>
                <span className='value'>0</span>
              </div>
              <div className="divider" />
              <div className="turn-info-item">
                <span>P:</span>
                <span className='value'>0</span>
              </div>
            </div>
          </ContainerSM>
        );
      default:
        return null;
    }
  }

  if (type === 'non-clock-in') {
    return (
      <ContainerWorking>
        <div className="top-content">
          <div className="staff-info">
            <Avatar src={data?.urlImage} style={{ backgroundColor: '#f56a00' }}>{data.firstName?.[0] || 'A'}</Avatar>
            <div className="staff-name">{data.firstName}</div>
          </div>
          <div className="btn-clock-in-wrap">
            <button className="btn-clock-in" onClick={e => [e.stopPropagation(), onClockIn()]}>
              {clockIn}
              <span>Clock-In</span>
            </button>
          </div>
        </div>

      </ContainerWorking>
    );
  }
  if (type === 'available') {
    return (
      <ContainerWorking className={data.ticketNumbers?.length ? 'exist-tickets' : ''}>
        <div className="top-content">
          <div className="staff-info">
            <Avatar src={data?.urlImage} style={{ backgroundColor: '#f56a00' }}>{data.firstName?.[0] || 'A'}</Avatar>
            <div className="staff-name">{data.firstName}</div>
          </div>
          <div style={{ flex: 1 }}>
            <div className="turn-info" style={{ flexDirection: 'row', gap: 12 }}>
              <div className="turn-dashboard" style={{ flex: 1 }}>
                <div className="turn-info-item">
                  <span>Bonus:</span>
                  <span className='value'>0</span>
                </div>
                <div className="divider" />
                <div className="turn-info-item">
                  <span>Partial:</span>
                  <span className='value'>0</span>
                </div>
              </div>
              <div className="turn-total">
                <span>{data.turn}</span>
              </div>
            </div>
            <div className="bottom-content">
              <div className="time-counting" style={{ flex: 'unset' }}>
                {clock}
                <span>{time}</span>
              </div>
              <button className="item btn-clock-out" onClick={e => [e.stopPropagation(), onClockOut()]}>
                {clockOut}
                <span>Clock-Out</span>
              </button>
            </div>
          </div>
        </div>
      </ContainerWorking>
    );
  }
  if (type === 'working') {
    return (
      <ContainerWorking className='working'>
        <div className="top-content">
          <div className="staff-info">
            <Avatar src={data?.urlImage} style={{ backgroundColor: '#f56a00' }}>{data.firstName?.[0] || 'A'}</Avatar>
            <div className="staff-name">{data.firstName}</div>
          </div>
          <div className="turn-info">
            <div className="turn-dashboard">
              <div className="turn-info-item">
                <span>Bonus:</span>
                <span className='value'>{data.totalBonus}</span>
              </div>
              <div className="divider" />
              <div className="turn-info-item">
                <span>Partial:</span>
                <span className='value'>{data.totalPartial}</span>
              </div>
            </div>

            <div className="turn-ticket-info">
              <div className="wrap-ticket-number">
                {data?.ticketNumbers?.map(o => (
                  <div key={o} className="ticket-number"><span>#{o}</span></div>
                ))}
              </div>
              <div className="ticket-services">

              </div>
            </div>
          </div>
          <div className="turn-total">
            <span>{data.turn}</span>
          </div>
        </div>
        <div className="bottom-content">
          <div className="item time-counting">
            {clock}
            <span>{time}</span>
          </div>
          <button className="item btn-clock-out" onClick={e => [e.stopPropagation(), onClockOut()]}>
            {clockOut}
            <span>Clock-Out</span>
          </button>
        </div>
      </ContainerWorking>
    );
  }
  return null;
};

export default StaffTurnItem;

const ContainerSM = styled.div`
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
align-self: stretch;
padding-top: 8px;
&:hover {
  cursor: pointer;
  opacity: 0.8;
}
&.working {
  border-radius: 5px;
  border: 2px dashed #F05326;
  background: rgb(255, 170, 146,0.3);
  box-shadow: 0px 0px 10px 0px #f0532670;
}
&.available {
  border-radius: 5px;
  border: 2px dashed #7B7B7B;
  background: #FFF;
}
&.non-clock-in {
  border-radius: 5px;
  border: 1px solid #7B7B7B;
  background: #FFF;
}
 &.exist-tickets {
    border: 2px dashed #8CC4DC;
    background: #8cc4dc57;
    box-shadow: 0px 0px 10px 0px #8CC4DC;
    .bottom-content, .top-content .turn-info .turn-dashboard {
      background: #fff;
    }
    &:hover {
      cursor: pointer;
      opacity: 0.8;
    }
  }

.staff-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 85px;
  width: 85px;
  gap: 8px;
  margin-bottom: 8px;
  position: relative;

  .turn-total {
    position: absolute;
    right: 0;
    top: 0;
    z-index: 2;
  }

  .ant-avatar {
    height: 72px;
    width: 72px;
    display: flex;
    align-items: center;
    z-index: 1;
    justify-content: center;

    .ant-avatar-string {
      font-size: 2.5rem;
    }
  }
}

.turn-dashboard {
  display: flex;
  align-items: center;
  background: #EEE;
  width: 100%;

  .divider {
    background-color: #CECECE;
    align-self: stretch;
    width: 1px;
  }

  .turn-info-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex: 1 0 0;
    padding: 0 8px;
    height: 27px;

    span {
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
      overflow: hidden;
      color: #1D2129;
      text-overflow: ellipsis;
      font-family: Poppins;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;

      &.value {
        text-align: right;
        font-size: 18px;
        font-weight: 600;
      }
    }
  }
}

.turn-total {
  display: flex;
  align-self: start;
  width: 32px;
  height: 32px;
  padding: 0px 6px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 100px;
  background: #148CF1;

  span {
    color: #FFF;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
}
`;

const Container = styled.div`
  border-radius: 5px;
  background: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  -webkit-user-select: none;
  /* Safari */
  -ms-user-select: none;
  /* IE 10 and IE 11 */
  user-select: none;
  /* Standard syntax */

  border-radius: 5px;
  border: 1px solid #7B7B7B;

  &.working {
    border: 2px dashed #F05326;
    background: rgb(255, 170, 146,0.3);
    box-shadow: 0px 0px 10px 0px #f0532670;
    .bottom-content, .top-content .turn-info .turn-dashboard {
      background: #fff;
    }
    &:hover {
      cursor: pointer;
      opacity: 0.8;
    }
  }


  &.exist-tickets {
    border: 2px dashed #8CC4DC;
    background: #8cc4dc57;
    box-shadow: 0px 0px 10px 0px #8CC4DC;
    .bottom-content, .top-content .turn-info .turn-dashboard {
      background: #fff;
    }
    &:hover {
      cursor: pointer;
      opacity: 0.8;
    }
  }

`;

const ContainerWorking = styled(Container)`
.top-content {
  display: flex;
  gap: 12px;
  width: 100%;
  padding: 12px 8px;
  padding-bottom: 0;
  margin-bottom: 8px;

  .staff-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 100px;
    width: 100px;
    gap: 8px;

    .ant-avatar {
      height: 64px;
      width: 64px;
      display: flex;
      align-items: center;
      z-index: 1;
      justify-content: center;

      .ant-avatar-string {
        font-size: 2.5rem;
      }
    }

    .staff-name {
      overflow: hidden;
      color: #1D2129;
      text-align: center;
      text-overflow: ellipsis;
      font-family: "Archivo Black";
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      text-transform: uppercase;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
      align-self: stretch;
    }
  }

  .turn-info {
    display: flex;
    flex-direction: column;
    flex: 1;

    .turn-dashboard {
      display: flex;
      align-items: center;
      background: #EEE;
      margin-bottom: 12px;

      .divider {
        background-color: #CECECE;
        align-self: stretch;
        width: 1px;
      }

      .turn-info-item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex: 1 0 0;
        padding: 0 8px;
        height: 32px;

        span {
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 1;
          overflow: hidden;
          color: #1D2129;
          text-overflow: ellipsis;
          font-family: Poppins;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;

          &.value {
            text-align: right;
            font-size: 18px;
            font-weight: 600;
          }
        }
      }
    }

    .turn-ticket-info {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 8px;

      .wrap-ticket-number {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 8px;
      }

      .ticket-number {
        display: flex;
        padding: 0px 6px;
        align-items: center;
        gap: 10px;
        border-radius: 5px;
        background: #1D2129;
        height: 37px;

        span {
          color: #fff;
          text-align: center;
          font-family: Poppins;
          font-size: 18px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
        }
      }

      .ticket-services {
        flex: 1;

        .ticket-service-item {
          display: flex;
          align-items: center;
          gap: 8px;
          align-self: stretch;

          .service-name {
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 1;
            flex: 1 0 0;
            overflow: hidden;
            color: #1D2129;
            text-overflow: ellipsis;
            font-family: Poppins;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            text-transform: capitalize;
          }

          .service-price {
            color: #1D2129;
            text-align: right;
            font-family: Poppins;
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            text-transform: capitalize;
          }
        }
      }
    }
  }

  .btn-clock-in-wrap {
    display: flex;
    padding: 4px 8px;
    align-items: center;
    gap: 12px;
    flex: 1 0 0;
    align-self: stretch;
    background: #F9F9F9;
  }

  button.btn-clock-in {
    display: flex;
    height: 40px;
    padding: 0px 8px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    flex: 1 0 0;
    border-radius: 5px;
    border: 1px solid #7B7B7B;
    background: #EEE;

    span {
      color: #1D2129;
      text-align: center;
      font-family: Poppins;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      text-transform: uppercase;
    }
  }

  .turn-total {
    display: flex;
    align-self: start;
    width: 32px;
    height: 32px;
    padding: 0px 6px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 100px;
    background: #148CF1;

    span {
      color: #FFF;
      font-family: Poppins;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
  }
}

.bottom-content {
  display: flex;
  padding: 4px 8px;
  align-items: center;
  gap: 12px;
  align-self: stretch;
  border-radius: 0px 0px 5px 5px;
  background: #EEE;

  .item {
    flex: 1;
  }

  .time-counting {
    display: flex;
    align-items: center;
    gap: 4px;
    justify-content: center;
    flex: 0.7;

    span {
      color: #1D2129;
      font-family: Poppins;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
  }

  button.item {
    display: flex;
    height: 40px;
    padding: 0px 8px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    flex: 1 0 0;

    &:hover {
      opacity: 0.8;
    }

    span {
      text-align: center;
      font-family: Poppins;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      text-transform: uppercase;
    }

    &.btn-clock-out {
      border-radius: 5px;
      border: 1px solid #F05326;
      background: #FFF0F0;

      span {
        color: #F05326;
      }
    }

    &.btn-finish {
      border-radius: 5px;
      border-radius: 5px;
      border: 1px solid #0CB76F;
      background: #F7FFF8;

      span {
        color: #0CB76F;
      }
    }
  }
}
`;
const clock = <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g clipPath="url(#clip0_4444_8652)">
    <path d="M9.67344 0H10.2574C11.7613 0.04375 13.2586 0.414844 14.5941 1.11211C16.6289 2.16523 18.2848 3.93555 19.1887 6.04258C19.6949 7.20937 19.9605 8.47422 20 9.74375V10.257C19.9551 11.7625 19.5832 13.2602 18.8855 14.5969C17.7266 16.8387 15.693 18.6164 13.3059 19.443C12.327 19.7879 11.2934 19.9648 10.257 20H9.74531C8.23164 19.9555 6.725 19.5793 5.38242 18.8738C3.3125 17.7973 1.63867 15.9758 0.751562 13.8168C0.2875 12.7016 0.0441406 11.5004 0 10.2949V9.70938C0.0480469 8.29492 0.38125 6.8875 1.00625 5.61602C2.08008 3.41289 3.98867 1.62773 6.26602 0.71875C7.34805 0.278906 8.5082 0.0484375 9.67344 0ZM9.73984 1.31055C8.17305 1.35234 6.62109 1.83438 5.30508 2.68594C3.55117 3.80859 2.22148 5.5793 1.64805 7.58281C1.30664 8.75469 1.22148 9.99883 1.38945 11.207C1.59453 12.6957 2.20039 14.1254 3.12227 15.3117C3.90352 16.3223 4.90938 17.1594 6.04688 17.741C7.7293 18.6098 9.70195 18.8965 11.5633 18.5523C13.0711 18.282 14.4988 17.5941 15.659 16.5949C16.825 15.5945 17.7223 14.282 18.2211 12.8277C18.8516 11.0113 18.8512 8.98281 18.2195 7.1668C17.4625 4.94922 15.7648 3.07773 13.6352 2.10234C12.4227 1.53867 11.0762 1.26875 9.73984 1.31055Z" fill="#1D2129" />
    <path d="M9.34802 4.03677C9.78278 4.03638 10.2172 4.03638 10.6519 4.03638C10.6539 5.9438 10.6492 7.85083 10.6543 9.75825C11.7609 11.0129 12.8633 12.2708 13.9683 13.527C13.6425 13.8149 13.3156 14.1012 12.989 14.3883C11.7883 13.0239 10.5906 11.6571 9.38903 10.2938C9.34841 10.259 9.3445 10.2051 9.34685 10.1559C9.34919 8.11606 9.34724 6.07622 9.34802 4.03677Z" fill="#1D2129" />
  </g>
  <defs>
    <clipPath id="clip0_4444_8652">
      <rect width="20" height="20" fill="white" />
    </clipPath>
  </defs>
</svg>;

const clockOut = <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
  <path d="M10.8333 17.5001C10.8333 17.7211 10.7455 17.9331 10.5892 18.0893C10.4329 18.2456 10.221 18.3334 9.99996 18.3334H2.49996C2.27895 18.3334 2.06698 18.2456 1.9107 18.0893C1.75442 17.9331 1.66663 17.7211 1.66663 17.5001V2.50008C1.66663 2.27907 1.75442 2.06711 1.9107 1.91083C2.06698 1.75455 2.27895 1.66675 2.49996 1.66675H9.99996C10.221 1.66675 10.4329 1.75455 10.5892 1.91083C10.7455 2.06711 10.8333 2.27907 10.8333 2.50008C10.8333 2.7211 10.7455 2.93306 10.5892 3.08934C10.4329 3.24562 10.221 3.33341 9.99996 3.33341H3.33329V16.6667H9.99996C10.221 16.6667 10.4329 16.7545 10.5892 16.9108C10.7455 17.0671 10.8333 17.2791 10.8333 17.5001ZM18.2666 9.68342C18.2235 9.5834 18.1612 9.4928 18.0833 9.41675L14.75 6.08342C14.5905 5.94689 14.3855 5.87555 14.1757 5.88366C13.966 5.89176 13.7671 5.9787 13.6187 6.12711C13.4702 6.27552 13.3833 6.47447 13.3752 6.6842C13.3671 6.89393 13.4384 7.099 13.575 7.25842L15.4916 9.16675H5.83329C5.61228 9.16675 5.40032 9.25455 5.24404 9.41083C5.08776 9.56711 4.99996 9.77907 4.99996 10.0001C4.99996 10.2211 5.08776 10.4331 5.24404 10.5893C5.40032 10.7456 5.61228 10.8334 5.83329 10.8334H15.4916L13.5833 12.7417C13.4961 12.8165 13.4252 12.9084 13.3752 13.0118C13.3252 13.1152 13.2971 13.2278 13.2926 13.3425C13.2882 13.4573 13.3075 13.5717 13.3494 13.6787C13.3913 13.7856 13.4549 13.8827 13.5361 13.964C13.6173 14.0452 13.7144 14.1087 13.8214 14.1506C13.9283 14.1925 14.0427 14.2118 14.1575 14.2074C14.2723 14.203 14.3849 14.1749 14.4883 14.1249C14.5917 14.0748 14.6836 14.004 14.7583 13.9167L18.0916 10.5834C18.2087 10.4672 18.2886 10.3188 18.3212 10.1571C18.3538 9.99538 18.3377 9.82763 18.275 9.67508L18.2666 9.68342Z" fill="#F05326" />
</svg>;

const clockIn = <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 21 20" fill="none">
  <path d="M9.67098 2.49992C9.67098 2.27891 9.75878 2.06694 9.91506 1.91066C10.0713 1.75438 10.2833 1.66659 10.5043 1.66659L18.0043 1.66659C18.2253 1.66659 18.4373 1.75438 18.5936 1.91066C18.7498 2.06694 18.8376 2.27891 18.8376 2.49992L18.8376 17.4999C18.8376 17.7209 18.7498 17.9329 18.5936 18.0892C18.4373 18.2455 18.2253 18.3333 18.0043 18.3333L10.5043 18.3333C10.2833 18.3333 10.0713 18.2455 9.91506 18.0892C9.75878 17.9329 9.67098 17.7209 9.67098 17.4999C9.67098 17.2789 9.75878 17.0669 9.91506 16.9107C10.0713 16.7544 10.2833 16.6666 10.5043 16.6666L17.171 16.6666L17.171 3.33325L10.5043 3.33325C10.2833 3.33325 10.0713 3.24546 9.91506 3.08918C9.75878 2.9329 9.67098 2.72093 9.67098 2.49992ZM2.23765 10.3166C2.28075 10.4166 2.34303 10.5072 2.42098 10.5833L5.75431 13.9166C5.91373 14.0531 6.11879 14.1244 6.32852 14.1163C6.53825 14.1082 6.7372 14.0213 6.88562 13.8729C7.03403 13.7245 7.12097 13.5255 7.12907 13.3158C7.13717 13.1061 7.06583 12.901 6.92931 12.7416L5.01265 10.8333L14.671 10.8333C14.892 10.8333 15.104 10.7455 15.2602 10.5892C15.4165 10.4329 15.5043 10.2209 15.5043 9.99992C15.5043 9.77891 15.4165 9.56694 15.2602 9.41066C15.104 9.25438 14.892 9.16659 14.671 9.16659L5.01265 9.16659L6.92098 7.25825C7.00821 7.18355 7.07906 7.09162 7.12908 6.98823C7.1791 6.88484 7.20721 6.77223 7.21164 6.65747C7.21608 6.5427 7.19674 6.42825 7.15484 6.32132C7.11295 6.21438 7.0494 6.11726 6.96819 6.03604C6.88698 5.95483 6.78985 5.89129 6.68292 5.84939C6.57598 5.80749 6.46153 5.78816 6.34677 5.79259C6.232 5.79702 6.11939 5.82513 6.016 5.87515C5.91262 5.92517 5.82069 5.99602 5.74598 6.08325L2.41265 9.41659C2.29562 9.53285 2.21572 9.68122 2.18309 9.84292C2.15045 10.0046 2.16654 10.1724 2.22931 10.3249L2.23765 10.3166Z" fill="#1D2129" />
</svg>;
