import turnSelectors from 'Turn/services/selectors';
import { IStaffTurnItem } from 'Turn/services/types/staff';
import { sumBy } from 'lodash';
import { useMemo } from 'react';
import styled from 'styled-components';
import { formatCurrency } from 'utils/formatCurrency';
type Props = {
  detail: IStaffTurnItem | null;
  onClockOut: () => void;
  onFinishAllTurnTicket: () => void;
  onAdjustTurn: () => void;
  onRemoveLateTurn: () => void;
};
const TurnFooter = ({ onAdjustTurn, onClockOut, onRemoveLateTurn }: Props) => {
  const staffTickets = turnSelectors.staffTickets();
  const amountDue = useMemo(() => sumBy(staffTickets, o => o.total), [staffTickets]);

  return (
    <Container>
      <div className="turn-amount-due"><span>AMOUNT DUE: {formatCurrency(amountDue)}</span></div>
      <div className="actions">
        <button className="action-item clock-out" onClick={onClockOut}><span>CLOCK-OUT</span></button>
        <button onClick={onAdjustTurn} className="action-item adjust-turn"><span>ADJUST TURN</span></button>
        <button onClick={onRemoveLateTurn} className="action-item remove-late-turn"><span>REMOVE LATE TURN</span></button>
      </div>
    </Container>
  );
};

export default TurnFooter;
const Container = styled.div`
.turn-amount-due {
  display: flex;
  height: 36px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  background: #FFF;
  margin-bottom: 16px;

  span {
    overflow: hidden;
    color: #1D2129;
    text-align: center;
    text-overflow: ellipsis;
    font-family: Poppins;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: uppercase;
  }
}

.actions {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 8px;

  .action-item {
    display: flex;
    height: 56px;
    padding: 0px 8px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    flex: 1 0 0;
    border-radius: 5px;

    span {
      color: #FFF;
      text-align: center;
      font-family: Poppins;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      text-transform: uppercase;
    }

    &.clock-out {
      background: #7B7B7B;
    }

    &.finish-ticket {
      background: #0CB76F;
    }

    &.adjust-turn {
      background: #148CF1;
    }

    &.remove-late-turn {
      background: #F56A00;
    }
  }
}
`;