import { signal } from '@preact/signals-react';
import { calendarScrollTo } from 'Calendar';
import calcTo from 'Calendar/helper';
import { isModeFull } from 'Calendar/styles';
import { DatePicker as DatePickerAnt, Row } from 'antd';
import type { Moment as MomentType } from 'moment';
import moment from 'moment';
import momentGenerateConfig from 'rc-picker/lib/generate/moment';
import { useMemo } from 'react';
import aptActions from 'services/actions/apt';
import { useAppDispatch } from 'store/hooks';
import styled from 'styled-components';
import getRangeDates from 'utils/getRangeDates';
import IconCalendar from '../assets/Icons/iconDate';

const handleScrollDate = (dateValue: moment.Moment | null) => {
  if (!dateValue) return calendarScrollTo.value(0);
  let pixel = 0;
  if (dateValue.isSame(moment(), 'date')) {
    pixel = calcTo(moment().format('MM-DD-YYYY HH:mm:ss'));
  }
  calendarScrollTo.value(pixel);
};

const DatePickerAntUI = DatePickerAnt.generatePicker<MomentType>(momentGenerateConfig);

export const dateParamSignal = signal<MomentType | null | undefined>(moment());

const DatePicker = () => {
  const dispatch = useAppDispatch();

  const _handleChange = (d: moment.Moment | null) => {
    dateParamSignal.value = d;
    dispatch(aptActions.calendar.getData.fetch({ startTime: d?.format('YYYY-MM-DD') || '' }));
    handleScrollDate(d);
  };
  const dates = useMemo(() => {
    const _date = moment();
    return getRangeDates(_date.format(), _date?.clone().add(0.5, 'year').format());
  }, []);

  const handleClickDate = (_date: string) => () => {
    const dateValue = moment(_date, 'DD-MM-YYYY');
    dateParamSignal.value = dateValue;
    dispatch(aptActions.calendar.getData.fetch({ startTime: dateValue.format('YYYY-MM-DD') }));
    handleScrollDate(dateValue);
  };

  return (
    <Row justify={'space-between'} style={{ flex: 1 }}>
      <Row style={{ flex: 1, position: 'relative', height: isModeFull.value ? '65px' : '6rem', }}>
        <Row justify={'space-between'} wrap={false} style={{ position: 'absolute', inset: 0, overflowX: 'scroll' }}>
          {dates.map(o => (
            <BoxTimeDate key={o.id} style={{
              height: isModeFull.value ? '60px' : '5.5rem',
              justifyContent: isModeFull.value ? 'center' : 'space-between',
            }} className={dateParamSignal.value?.format('DD-MM-YYYY') === o.id ? 'active' : ''} onClick={handleClickDate(o.id)}>
              {!isModeFull.value && <p>{o.ddd}</p>}
              <p className={`date ${isModeFull.value ? 'mode-full' : ''}`}>{o.D}</p>
            </BoxTimeDate>
          ))}
        </Row>
      </Row>
      <WrapDateTime align={'middle'}>
        <div className='icon-right'><IconCalendar /></div>
        <DatePickerStyled
          value={dateParamSignal.value}
          onChange={_handleChange}
          suffixIcon={false}
          clearIcon={false}
          allowClear={false}
          inputReadOnly
          format={'LL'}
          disabledDate={(current: MomentType) => current.isBefore(moment().subtract(1, 'day'))}
        />
      </WrapDateTime>
    </Row>
  );
};

export default DatePicker;

const WrapDateTime = styled(Row)`
border-radius: 5px;
border: 1px solid #7B7B7B;
background: #FFF;
display: flex;
height: 46px;
width: 15rem;
justify-content: space-between;
align-items: center;
flex-shrink: 0;
position: relative;
margin: 0 1.5rem;

.icon-right {
  position: absolute;
  left: 16px;
  display: flex;
}
.ant-picker .ant-picker-input >input {
  font-size: 1rem;
}
`;

const DatePickerStyled = styled(DatePickerAntUI)`
  background: none;
  border: none;
  padding:0;
  flex:1;
  
  input {
    text-align: right;
    color: #1D2129;
    font-family: Poppins;
    font-size: ${() => isModeFull.value ? '18px' : '16px'} !important;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 150% */
    padding: 0px 16px !important;
  }

  &.ant-picker:hover,
  &.ant-picker-focused {
    border: none;
    box-shadow: none;
  }

`;

const BoxTimeDate = styled.button`
  color: #1D2129;
  text-align: center;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  min-width: 3.5rem;
  margin-right: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  border-radius: 8px;
  padding-bottom: 8px;
  height: 5.5rem;

  &.active {
    .date {
      border-radius: 100px;
      background: #232F3E;
      color: #fff;
      height: 33px;
      width: 33px;
      display: flex;
      align-items: center;
      justify-content: center;
    }  
  } 

  .date.mode-full {
    height: 50px;
    width: 50px;
    font-size: 22px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &:hover {
    background: #e9e8e8;
  }
`;

