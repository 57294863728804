import { CALENDAR_VIEW_TYPE } from 'constants/calendar';
import moment from 'moment';
import { useCallback, useMemo } from 'react';
import { calendarSelectors } from 'services/selectors/apt';
import shopSelectors from 'services/selectors/shop';
import { IDateRangeItem } from 'utils/getRangeDates';
import StaffHeader from './StaffHeader';
import { ANYONE } from './anyone';
import { ICalendarHeader, ICalendarItemData, ICalendarSchema } from './types';

const useCalendarFilter = (): ICalendarSchema => {
  const distanceTimeline = calendarSelectors.distanceTimeline();

  const typeView = calendarSelectors.typeView();
  const dateRangeList = calendarSelectors.dateRanges();
  const staffList = shopSelectors.staff();

  const headers = useMemo(() => {
    if (typeView === CALENDAR_VIEW_TYPE.WEEK_VIEW) {
      return dateRangeList.map((item) => {
        const _date = moment(item.value);
        const result: ICalendarHeader = ({
          id: item.id,
          value: _date,
          label: _date.format('DD.MM'),
          type: 'date',
          typeColor: 0,
        });
        return result;
      });
    }

    if (typeView === CALENDAR_VIEW_TYPE.DAY_VIEW) {
      const headers: ICalendarHeader[] = [
        {
          id: ANYONE.id,
          value: ANYONE.id,
          label: ANYONE.name,
          type: 'staff',
          url: ANYONE.avatar,
          typeColor: 0,
        }
      ];
      let i = 1;
      staffList.forEach((item) => {
        const result: ICalendarHeader = ({
          id: item.id,
          value: item.id,
          label: item.name,
          type: 'staff',
          url: item.avatar,
          typeColor: i,
        });
        i++;
        if (i > 6) {
          i = 1;
        }
        headers.push(result);
      });

      return headers;
    }
    return [];
  }, [typeView, dateRangeList, staffList]);

  const renderHeader = useCallback((o: ICalendarHeader<any>) => {
    if (typeView === CALENDAR_VIEW_TYPE.WEEK_VIEW)
      return (
        <>
          <p className='subtext'>{o?.value?.format('ddd, MMMM DD')}</p>
        </>
      );

    return (
      <StaffHeader url={o.url} name={o.label} typeColor={o.typeColor} />
    );
  }, [typeView]);

  const filterAppointments = useCallback((order: ICalendarItemData, _col: ICalendarHeader<any>) => {
    if (typeView === CALENDAR_VIEW_TYPE.WEEK_VIEW) {
      const col = _col as ICalendarHeader<IDateRangeItem['value']>;
      return moment(order.startTime).isSame(col?.value, 'day');
    }

    if (typeView === CALENDAR_VIEW_TYPE.DAY_VIEW) {
      const col = _col as ICalendarHeader<any>;
      const staffId = order?.staffId;
      return staffId === col.id;
    }
    return false;
  }, [typeView]);

  return ({
    distanceTimeline,
    headers,
    typeView,
    renderHeader,
    filterAppointments,
    timeHourEnd: 21,
    timeHourStart: 9,
    timeMinuteEnd: 0,
    timeMinuteStart: 0,
  });
};

export default useCalendarFilter;
