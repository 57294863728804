import Modal from 'AppointmentBox/Modal';
import { Col, Form, Input, InputRef, Row, Select, Spin } from 'antd';
import CurrencyInput from 'components/CurrencyInput';
import { useRef, useState } from 'react';
import shopActions from 'services/actions/shop';
import apis from 'services/apis';
import shopSelectors from 'services/selectors/shop';
import { IAddNewServiceResData } from 'services/types/shop';
import { useAppDispatch } from 'store/hooks';
import styled from 'styled-components';
import { ICategoryUI } from 'types/category';
import { IServiceUI } from 'types/service';
type Props = {
  setActiveCategory: (value: ICategoryUI | null) => void;
  onChooseService: (ser: IServiceUI) => () => void;
};
const InputSearch = ({ setActiveCategory, onChooseService }: Props) => {
  const inputNameRef = useRef<InputRef>(null);
  const dispatch = useAppDispatch();
  const [form] = Form.useForm();
  const [open, setOpen] = useState(false);
  const categories = shopSelectors.categories();
  const [loading, setLoading] = useState(false);

  const handleCancel = () => {
    form.resetFields();
    setOpen(false);
  };
  const handleOpen = () => {
    form.resetFields();
    form.setFieldValue('catId', categories?.[0]?.id);
    form.setFieldValue('duration', 15);
    setOpen(true);
    setTimeout(() => { inputNameRef.current?.focus(); }, 500);
  };
  const handleFinish = async (values: any) => {
    setLoading(true);
    try {
      const res = await apis.addNewService({
        catId: values.catId,
        price: parseFloat(values.price),
        serviceName: values.serviceName,
        duration: parseFloat(values.duration),
      });
      const payload = res.data.payload as IAddNewServiceResData;

      if (payload) {
        const activeCate = categories.find(o => o.id.toString() === values.catId?.toString());
        setActiveCategory(activeCate ? { ...activeCate } : null);
        setOpen(false);
        const data: IServiceUI = {
          cateId: payload.catId?.toString(),
          duration: payload.duration || 0,
          id: payload.id?.toString(),
          image: '',
          name: payload.serviceName,
          price: payload.price,
        };
        onChooseService(data)();
        dispatch(shopActions.afterCreateService(data));
      }
    } catch (error) { }
    finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Container>
        <InputSearchStyled wrap={false} align={'middle'}>
          <Input
            prefix={<IconSearch />}
            placeholder="Service Name" />
        </InputSearchStyled>
        <button className="btn btn-add" onClick={handleOpen}>
          <IconAdd />
          <div className="label">ADD NEW SERVICE</div>
        </button>
      </Container>
      <Modal
        centered={false}
        title="ADD NEW SERVICE" height='auto' open={open} okText="ADD" onCancel={handleCancel}
        onOk={() => form.submit()}
        disabledOk={loading}
        footerStyle={{ marginTop: 0 }}
      >
        <Spin spinning={loading}>
          <FormStyled form={form} size='large' layout='vertical' onFinish={handleFinish} validateTrigger={['onSubmit']}>
            <Row gutter={[16, 16]}>
              <Col span={8}>
                <Form.Item name={'catId'} label="CATEGORY" rules={[{
                  required: true,
                  message: 'Please choose category',
                }]}>
                  <Select
                    defaultValue="none"
                    options={categories.map(o => ({
                      value: o.id, label: o.name,
                    }))}
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item name={'serviceName'} label="SERVICE NAME" rules={[{
                  required: true,
                  message: 'Please enter service name',
                }]} >
                  <Input ref={inputNameRef} placeholder='Please enter' />
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item name={'price'} label="SALE PRICE" rules={[{
                  required: true,
                  message: 'Please enter sale price',
                }]}>
                  <CurrencyInput placeholder='$0.00' />
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item name={'duration'} label="DURATION (m)" rules={[{
                  required: true,
                  message: 'Please enter duration',
                }]}>
                  <Input style={{ textAlign: 'right' }} placeholder='0' onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  />
                </Form.Item>
              </Col>
            </Row>
          </FormStyled>
        </Spin>
      </Modal>
    </>
  );
};

export default InputSearch;

const Container = styled(Row)`
  margin: 0;
  gap: 1rem;
  align-items: center;
  .btn {
    &.btn-add {
      display: flex;
      height: 46px;
      padding: 10px 24px;
      justify-content: center;
      align-items: center;
      gap: 8px;
      border-radius: 5px;
      background: #c5ddf86e;
      border: 1px solid #148CF1;
      .label {
        color: #232F3E;
        text-align: center;
        font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        text-transform: uppercase;
      }
    }
  }
`;

const InputSearchStyled = styled(Row)`
  gap: 1rem;  
  flex: 1;
`;

const IconSearch = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
    <path d="M8.21939 16.6771C9.96583 16.6771 11.5966 16.1089 12.9222 15.1621L17.909 20.1489C18.1405 20.3804 18.4456 20.4961 18.7612 20.4961C19.4451 20.4961 19.9395 19.9701 19.9395 19.2967C19.9395 18.9811 19.8343 18.6865 19.6029 18.4551L14.6476 13.4893C15.6891 12.1216 16.3099 10.4277 16.3099 8.58657C16.3099 4.13628 12.6697 0.496094 8.21939 0.496094C3.75857 0.496094 0.128906 4.13628 0.128906 8.58657C0.128906 13.0369 3.75857 16.6771 8.21939 16.6771ZM8.21939 14.9306C4.73701 14.9306 1.87536 12.0584 1.87536 8.58657C1.87536 5.11472 4.73701 2.24254 8.21939 2.24254C11.6912 2.24254 14.5634 5.11472 14.5634 8.58657C14.5634 12.0584 11.6912 14.9306 8.21939 14.9306Z" fill="#1D2129" />
  </svg>
);

const IconAdd = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="19" height="20" viewBox="0 0 19 20" fill="none">
    <path d="M19 12.2172H12.1302V19.2688H7.33221V12.2172H0.462402V7.78275H7.33221V0.731201H12.1302V7.78275H19V12.2172Z" fill="#232F3E" />
  </svg>
);
const FormStyled = styled(Form)`
  padding: 0rem 1.5rem;
  padding-top: 1rem;
  .group {
    gap: 1.5rem;
  }
  .col-item {
    flex:1;
  }
  input.ant-input {
    line-height: 2;
    height: 3.5rem;
    font-size: 18px;
    &::placeholder {
      color:#dadada;
    }
  }
  .ant-input-number input {
    height: 3.5rem;
    font-size: 18px;
  }
  .ant-select-single.ant-select-lg {
    height: 3.5rem;
  }
  &.ant-form-large .ant-form-item .ant-form-item-label>label {
    height: 24px;
  }
`;
