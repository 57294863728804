import { loadingSignal } from 'components/Loading';
import aptActions from 'services/actions/apt';
import { useAppDispatch } from 'store/hooks';
import styled from 'styled-components';
import CustomerSection from './Customer';
import Modal from './Modal';
import ServicesSection from './Services';
import StaffSection from './Staff';
import TimeSection from './Time';
import qbSignal from './signal';

const QuickBookingBox = () => {
  const dispatch = useAppDispatch();
  const handleClose = () => qbSignal.close();
  const handleBlockHours = async () => {
    const values = qbSignal.getValues();
    dispatch(aptActions.quickBooking.doQuickBooking(values));
  };

  return (
    <Modal
      title="QUICK BOOKING"
      open={qbSignal.openSignal.value}
      onCancel={handleClose}
      okText={'Booking'}
      onOk={handleBlockHours}
      height='600px'
      width='90vw'
      disabledOk={!qbSignal.services.value.length}
      loading={loadingSignal.value}
    >
      <FormStyled>
        <div className='customer-staff'>
          <div className='customer-staff-item'>
            <CustomerSection />
          </div>
          <div className='customer-staff-item'>
            <StaffSection />
          </div>
        </div>
        <TimeSection />
        <ServicesSection />
      </FormStyled>
    </Modal>
  );
};

export default QuickBookingBox;

const FormStyled = styled.div`
padding: 1rem 1.5rem;
flex: 1;
gap:24px;
display: flex;
flex-direction: column;
  .customer-staff {
    display: flex;
    gap: 24px;
    .customer-staff-item {
      flex: 1;
    }
  }
  .section-label {
   color: #7B7B7B;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 120% */
    &.mb {
    margin-bottom: 8px;
    }
  }
`;