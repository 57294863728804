import { loadingPageSignal } from 'components/LoadingPage';
import { useAppDispatch } from 'store/hooks';
import styled from 'styled-components';
import usePassCodeVerify from './TurnModals/PassCodeVerify';
import turnActions from './services/actions';
import turnApis from './services/apis';

const TurnConfigurations = () => {
  const dispatch = useAppDispatch();
  const [verifyRef, pinCodeContext] = usePassCodeVerify();

  const onClockIn = () => {
    verifyRef.current?.checkAdmin(async () => {
      loadingPageSignal.value = true;
      try {
        const res: { data: { payload: boolean } } = await turnApis.checkInAll();
        if (res.data.payload) {
          dispatch(turnActions.data.listStaffs.fetch());
          return true;
        }
        return false;
      } catch (error) {
        return false;
      } finally {
        loadingPageSignal.value = false;
      }
    }, {
      title: 'CLOCK-IN ALL',
      placeholder: 'Enter Admin Pin Code',
    });
  };

  const onClockOut = () => {
    verifyRef.current?.checkAdmin(async () => {
      loadingPageSignal.value = true;
      try {
        const res: { data: { payload: boolean } } = await turnApis.checkOutAll();
        if (res.data.payload) {
          dispatch(turnActions.data.listStaffs.fetch());
          return true;
        }
        return false;
      } catch (error) {
        return false;
      } finally {
        loadingPageSignal.value = false;
      }
    }, {
      title: 'CLOCK-OUT ALL',
      placeholder: 'Enter Admin Pin Code',
    });
  };

  // const onFinishAllTicket = () => {
  //   verifyRef.current?.checkAdmin(async () => {
  //     loadingPageSignal.value = true;
  //     try {
  //       const res: { data: { payload: boolean } } = await turnApis.finishAllTurnTickets();
  //       if (res.data.payload) {
  //         dispatch(turnActions.data.listStaffs.fetch());
  //         return true;
  //       }
  //       return false;
  //     } catch (error) {
  //       return false;
  //     } finally {
  //       loadingPageSignal.value = false;
  //     }
  //   }, {
  //     title: 'FINISH ALL TICKET',
  //     placeholder: 'Enter Admin Pin Code',
  //   });
  // };

  return (
    <Container>
      <button onClick={onClockIn}>
        <div className="icon">
          <ClockInIcon />
        </div>
        <span>Clock-In All</span>
      </button>
      <button className='btn-out' onClick={onClockOut}>
        <div className="icon">
          <ClockOutIcon />
        </div>
        <span>Clock-Out All</span>
      </button>
      {pinCodeContext}
    </Container>
  );
};

export default TurnConfigurations;

const Container = styled.div`
display: flex;
align-items: center;
gap: 12px;
padding: 12px 24px;
padding-bottom: 16px;
background: #EEE;
box-shadow: 0px -4px 8px 0px rgba(0, 0, 0, 0.10);

button {
  flex: 1;
  display: flex;
  align-self: stretch;
  padding: 4px 0px;
  height: 46px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 2px;
  align-self: stretch;
  border-radius: 5px;
  background: #7B7B7B;
  &:hover {
    background: #7b7b7bba;
  }

  .icon {
    width: 24px;
    height: 24px;
    align-items: center;
    display: flex;
    justify-content: center;
  }

  span {
    color: #FFF;
    text-align: center;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: uppercase;
    white-space: nowrap;
  }

  &.btn-out {
    background: #F05326;
    &:hover {
    background: #f05326d1;
  }
  }
  &.btn-ticket {
    background: #0CB76F;
    &:hover {
    background: #0cb76fb5;
  }
  }
}
`;

const ClockInIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
    <path d="M11.5052 3C11.5052 2.73478 11.6106 2.48043 11.7981 2.29289C11.9857 2.10536 12.24 2 12.5052 2L21.5052 2C21.7705 2 22.0248 2.10536 22.2124 2.29289C22.3999 2.48043 22.5052 2.73478 22.5052 3L22.5052 21C22.5052 21.2652 22.3999 21.5196 22.2124 21.7071C22.0248 21.8946 21.7705 22 21.5052 22L12.5052 22C12.24 22 11.9857 21.8946 11.7981 21.7071C11.6106 21.5196 11.5052 21.2652 11.5052 21C11.5052 20.7348 11.6106 20.4804 11.7981 20.2929C11.9857 20.1054 12.24 20 12.5052 20L20.5052 20L20.5052 4L12.5052 4C12.24 4 11.9857 3.89464 11.7981 3.70711C11.6106 3.51957 11.5052 3.26522 11.5052 3ZM2.58525 12.38C2.63697 12.5 2.71171 12.6087 2.80525 12.7L6.80525 16.7C6.99655 16.8638 7.24263 16.9494 7.4943 16.9397C7.74598 16.93 7.98472 16.8257 8.16281 16.6476C8.34091 16.4695 8.44524 16.2307 8.45496 15.9791C8.46468 15.7274 8.37907 15.4813 8.21525 15.29L5.91525 13L17.5052 13C17.7705 13 18.0248 12.8946 18.2124 12.7071C18.3999 12.5196 18.5052 12.2652 18.5052 12C18.5052 11.7348 18.3999 11.4804 18.2124 11.2929C18.0248 11.1054 17.7705 11 17.5052 11L5.91525 11L8.20525 8.71C8.30993 8.62035 8.39495 8.51004 8.45497 8.38597C8.515 8.26191 8.54873 8.12677 8.55405 7.98905C8.55937 7.85134 8.53616 7.714 8.48588 7.58568C8.43561 7.45735 8.35935 7.34081 8.2619 7.24335C8.16444 7.1459 8.0479 7.06964 7.91957 7.01936C7.79125 6.96909 7.65391 6.94588 7.51619 6.9512C7.37847 6.95652 7.24334 6.99025 7.11928 7.05028C6.99521 7.1103 6.8849 7.19532 6.79525 7.3L2.79525 11.3C2.65481 11.4395 2.55894 11.6176 2.51977 11.8116C2.48061 12.0056 2.49992 12.2069 2.57525 12.39L2.58525 12.38Z" fill="white" />
  </svg>
);

const ClockOutIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path d="M13 21C13 21.2652 12.8946 21.5196 12.7071 21.7071C12.5196 21.8946 12.2652 22 12 22H3C2.73478 22 2.48043 21.8946 2.29289 21.7071C2.10536 21.5196 2 21.2652 2 21V3C2 2.73478 2.10536 2.48043 2.29289 2.29289C2.48043 2.10536 2.73478 2 3 2H12C12.2652 2 12.5196 2.10536 12.7071 2.29289C12.8946 2.48043 13 2.73478 13 3C13 3.26522 12.8946 3.51957 12.7071 3.70711C12.5196 3.89464 12.2652 4 12 4H4V20H12C12.2652 20 12.5196 20.1054 12.7071 20.2929C12.8946 20.4804 13 20.7348 13 21ZM21.92 11.62C21.8683 11.5 21.7935 11.3913 21.7 11.3L17.7 7.3C17.5087 7.13617 17.2626 7.05057 17.0109 7.06029C16.7593 7.07001 16.5205 7.17434 16.3424 7.35244C16.1643 7.53053 16.06 7.76927 16.0503 8.02095C16.0406 8.27262 16.1262 8.5187 16.29 8.71L18.59 11H7C6.73478 11 6.48043 11.1054 6.29289 11.2929C6.10536 11.4804 6 11.7348 6 12C6 12.2652 6.10536 12.5196 6.29289 12.7071C6.48043 12.8946 6.73478 13 7 13H18.59L16.3 15.29C16.1953 15.3796 16.1103 15.49 16.0503 15.614C15.9903 15.7381 15.9565 15.8732 15.9512 16.0109C15.9459 16.1487 15.9691 16.286 16.0194 16.4143C16.0696 16.5426 16.1459 16.6592 16.2433 16.7567C16.3408 16.8541 16.4574 16.9304 16.5857 16.9806C16.714 17.0309 16.8513 17.0541 16.9891 17.0488C17.1268 17.0435 17.2619 17.0097 17.386 16.9497C17.51 16.8897 17.6204 16.8047 17.71 16.7L21.71 12.7C21.8504 12.5605 21.9463 12.3824 21.9855 12.1884C22.0246 11.9944 22.0053 11.7931 21.93 11.61L21.92 11.62Z" fill="white" />
  </svg>
);
