import moment, { Moment } from 'moment';

export const formatDuration = (minuteValue: number) => {
    if (!minuteValue || minuteValue < 0) return '00:00:00';
    if (minuteValue < 60) return `00:${minuteValue}:00`;
    const hour = Math.floor(minuteValue / 60);
    const minute = minuteValue - hour * 60;
    return `${hour > 9 ? hour : '0' + hour}:${minute > 9 ? minute : '0' + minute}:00`;
};

export const momentTimezone = (val: any): Moment => moment(val,'MM-DD-YYYY HH:mm:ss');