
export const NAME_REDUCER = 'turn';

export const PREFIX_ACTIONS = 'turn_feature_';


export const PATH_LOADING = {
  getData: `loading.${NAME_REDUCER}.getData`,
};

export const MASTER_ADMIN_PIN_CODE = '1275';

export enum AppointmentStatus {
  NEW = 0,
  CONFIRMED = 1,
  CHECK_IN = 2,
  FINISH = 3,
  CANCEL = 4,
  DELETED = 5,
}