import { AutoComplete, Empty, InputRef, Row, Spin } from 'antd';
import { AxiosResponse } from 'axios';
import Text from 'components/Text';
import { debounce } from 'lodash';
import { useCallback, useMemo, useState } from 'react';
import apis from 'services/apis';
import shopSelectors from 'services/selectors/shop';
import { ICustomerResItem } from 'services/types/response';
import styled from 'styled-components';
import { ICustomerUI } from 'types/customer';
import { formatPhone } from 'utils/formatPhone';
import storage from 'utils/sessionStorage';
import AddCustomer, { useAddCustomerRef } from './AddCustomer';
import PhoneInput from 'components/PhoneInput';
import Highlighter from 'react-highlight-words';

type Props = {
  onSelect: (data: ICustomerUI) => void;
  inputRef: React.RefObject<InputRef>;
  open?: boolean;
  setOpen?: (val: boolean) => void;
};
const InputSearch = ({ onSelect, inputRef, open, setOpen }: Props) => {
  const addRef = useAddCustomerRef();
  const customerStore = shopSelectors.customers();
  const [customersFilter, setCustomersFilter] = useState<ICustomerUI[]>([]);
  const [loading, setLoading] = useState(false);

  const initialOptions = useMemo(() => {
    return customerStore.map(o => {
      const phone = (o?.phone || '');
      return ({
        value: o.id,
        name: o.name,
        keywords: [o.name?.toLocaleLowerCase(), phone],
        label: renderLabel(o.name, phone, []),
      });
    });
  }, [customerStore]);

  const [searchText, setSearchText] = useState<string>('');

  const getSearchWords = (text: string) => {
    const strValue = text?.replace(/[^0-9]/g, '');
    const arr: string[] = [text, strValue];
    for (let idx = 0; idx < 10; idx++) {

      const start = strValue.padStart(strValue.length + idx, '0');
      if (10 - start.length < 0) break;

      const valueSearch = start.padEnd(10, '0');
      let result = formatPhone(valueSearch) || '';

      if (strValue.length === 4 && result.endsWith('00-0000')) {
        result = result.replace('00-0000', '');
      }
      if (strValue.length === 4 && result.endsWith('0-0000') && result.startsWith('(0')) {
        result = result.replace('0-0000', '').replace('(0', '');
      }
      if (strValue.length === 4 && result.endsWith('-0000') && result.startsWith('(00')) {
        result = result.replace('-0000', '').replace('(00', '');
      }
      if (strValue.length === 4 && result.endsWith('000') && result.startsWith('(000) ')) {
        result = result.slice(0, result.length - 3).replace('(000) ', '');
      }
      if (strValue.length === 4 && result.endsWith('00') && result.startsWith('(000) 0')) {
        result = result.slice(0, result.length - 2).replace('(000) 0', '');
      }
      if (strValue.length === 4 && result.endsWith('0') && result.startsWith('(000) 00')) {
        result = result.slice(0, result.length - 1).replace('(000) 00', '');
      }
      if (strValue.length === 4 && result.startsWith('(000) 000-')) {
        result = result.replace('(000) 000-', '');
      }

      arr.push(result);
    }
    return arr;
  };


  const optionsFilter = useMemo(() => {
    const searchWords = getSearchWords(searchText);
    return customersFilter.map(o => {
      const phone = o?.phone || '';
      return ({
        value: o.id,
        name: o.name,
        label: renderLabel(o.name, phone, searchWords),
      });
    });
  }, [customersFilter, searchText]);

  const options = useMemo(() => {
    if (!searchText) return initialOptions;
    return optionsFilter;
  }, [initialOptions, searchText, optionsFilter]);

  const handleSwitchCustomer = (data: string) => {
    inputRef?.current?.blur();
    setSearchText('');
    const result = (searchText ? customersFilter : customerStore)?.find((item) => item.id === data);
    if (!result) return;
    onSelect(result);
  };

  const getList = useCallback(debounce(async (search: string) => {
    try {
      const res: AxiosResponse<{ payload: { customers: ICustomerResItem[] } }> = await apis.getAllCustomerAutoSearchPage(storage.shopId.get(), search?.replace(/[^0-9]/g, ''));

      const customerRes = res?.data?.payload?.customers;
      if (customerRes) {
        const data: ICustomerUI[] = customerRes.map(o => ({
          id: o.id?.toString(),
          name: o.name,
          phone: o.phone,
        }));
        setCustomersFilter(data);
      }
    } catch (error) { }
    finally {
      setLoading(false);
    }
  }, 500), []);

  const onClickOpenAdd = () => {
    if (!options.length) addRef.current?.setPhone(searchText);
  };

  return (
    <InputSearchStyled wrap={false} align={'middle'}>
      <AutoComplete
        open={open}
        onDropdownVisibleChange={setOpen}
        style={{ width: '100%' }}
        options={options}
        dropdownRender={(menu) => (<>
          {searchText && loading ? <Row align={'middle'} justify={'center'}><Text py={1.5}><Spin /></Text></Row> : menu}
        </>)}
        notFoundContent={<Empty />}
        onSelect={handleSwitchCustomer}
        value={searchText}
        onChange={text => {
          if (text) setLoading(true);
          setSearchText(text);
          getList(text);
        }}
      >
        <PhoneInput
          ref={inputRef}
          placeholder="Phone Number"
        />
      </AutoComplete>
      <AddCustomer onClick={onClickOpenAdd} ref={addRef} onSelect={onSelect} />
    </InputSearchStyled>
  );
};

export default InputSearch;

const InputSearchStyled = styled(Row)`
  gap: 1rem;
  .ant-select-single {
    height: unset;
  }
`;

const renderLabel = (name: string, tel: string, searchWords: string[]) => (
  <div
    style={{
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    }}
  >
    <div
      style={{
        display: 'flex',
        alignItems: 'flex-start',
        flexDirection: 'column',
      }}
    >
      <Text variant="CONTENT_2" color="text_3">
        {name}
      </Text>
    </div>
    <div>
      <Text variant="BODY_1" color="text_3">
        <Highlighter
          searchWords={searchWords}
          autoEscape={true}
          textToHighlight={formatPhone(tel)}
        />
      </Text>
    </div>
  </div>
);
