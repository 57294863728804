import { Modal as AntModal, Row } from 'antd';
import { ReactNode, useState } from 'react';
import styled from 'styled-components';
import Text from './Text';

interface Props {
  label?: string;
  onYes?: () => void;
  onNo?: () => void;
  modalTitle?: string;
  message?: string;
  renderButton?: (onPress: () => void) => ReactNode;
}

const ButtonConfirm = ({ renderButton, label = 'btn-label', message = 'Are you sure?', modalTitle = 'modal-title', onYes = () => undefined }: Props) => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleYes = () => {
    handleClose();
    onYes();
  };
  return (
    <>
      {renderButton ? renderButton(handleOpen) : <button className='btn btn-delete' onClick={handleOpen}>
        <Text className="label">{label}</Text>
      </button>}
      {open && <AntModal
        open={open}
        onCancel={handleClose}
        centered
        title={null}
        footer={false}
        closable={false}
        destroyOnClose={false}
        width={'45vw'}
      >
        <Header justify={'space-between'}>
          <Text className="title">{modalTitle}</Text>
        </Header>
        <ContainerContent height={'auto'}>
          <Content height={'auto'}>
            <Message>{message}</Message>
          </Content>
          <Footer>
            <button className='btn btn-delete' onClick={handleClose}>
              <Text className="label">ClOSE</Text>
            </button>
            <button className='btn btn-update' onClick={handleYes}>
              <Text className="label">YES</Text>
            </button>
          </Footer>
        </ContainerContent>
      </AntModal>}
    </>
  );
};

export default ButtonConfirm;

const Header = styled(Row)`
  height: 50px;
  position: relative;
  align-items: center;
  background: #fff;
  padding: 8px 8px;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  border-bottom: 1px solid #CECECE;

  .title {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    color: #1D2129;
    text-align: center;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 120% */
  }
  button {
    background: #fff;
    align-self: stretch;
    border-radius: 5px;
    width: 80px;
    &:hover {
      background: #FFEFEB;
    }

    &:disabled {
      opacity: 0.5;
    }
  }
  .text-action {
    color: #F05326;
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 133.333% */
  }
`;

const Message = styled.p`
  color: #1D2129;
  text-align: center;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 133.333% */
`;

const ContainerContent = styled.div<{ height?: string }>`
  max-height: ${({ height }) => height || '80vh'};
  overflow-y: auto;
  position: relative;
  display: flex;
  flex-direction: column;
`;

const Content = styled.div<{ height?: string }>`
  height: ${({ height }) => height || '80vh'};
  display: flex;
  flex-direction: column;
  padding: 1.5rem;
  padding-bottom: 0;
`;

const Footer = styled(Row)`
padding: 1.5rem;
gap: 1rem;
.btn {
  display: flex;
  height: 56px;
  padding: 10px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex: 1 0 0;

  .label {
    text-align: center;

    /* Body 1 */
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
  }
  
}
  .btn-delete {
    border-radius: 2px;
    border: 1px solid #7B7B7B;
    background: #EEE;
    .label {
      color: #7B7B7B;
    }
  }

  .btn-update {
    border-radius: 2px;
    background: #F05326;
    .label {
      color: #FFF;
    }
  }
`;