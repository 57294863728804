import { CSSProperties } from 'react';
import styled from 'styled-components';
import { RefreshActiveIcon } from './icons';

type ActionProps = {
  title?: string;
  onClick?: () => void;
  icon?: () => JSX.Element;
  style?: CSSProperties;
};
const Action = ({ title, onClick, icon, style }: ActionProps) => (
  <ActionStyled onClick={onClick} style={style}>
    {icon && <div className="icon">{icon()}</div>}
    <p >{title}</p>
  </ActionStyled>
);
export const RefreshBtn = () => <Action style={{ minWidth: 'unset', gap: 'unset', padding: '0 8px' }} icon={RefreshActiveIcon} onClick={()=>window.location.reload()} />;
export default Action;

const ActionStyled = styled.button`
  border-radius: 2px;
  min-width: 12rem;
  padding: 0 1rem;
  display: flex;
  align-items: center;
  gap: 12px;
  p {
    color: #E8F3FF;
    text-align: center;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: capitalize;
  }

  .icon {
    display: flex;
    width: 32px;
    height: 32px;
    padding: 1px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 100px;
    background: #FFF;
  }
`;
