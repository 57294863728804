import { Modal as AntModal, ModalProps, Row } from 'antd';
import Text from 'components/Text';
import { CSSProperties } from 'react';
import styled from 'styled-components';

interface Props extends ModalProps {
  disabledOk?: boolean;
  visibleOk?: boolean;
  visibleClose?: boolean;
  aptFooter?: boolean;
  width?: string;
  height?: string;
  footerStyle?: CSSProperties,
  rightButton?: any;
  title?: any;
}

const Modal = ({ width = '75vw', height, onCancel = () => undefined, centered = true, rightButton, ...rest }: Props) => {
  return (
    <AntModal
      {...rest}
      centered={centered}
      title={null}
      footer={false}
      closable={false}
      destroyOnClose={false}
      width={width}
    >
      <Header justify={'space-between'}>
        <button onClick={onCancel}><p className="text-action">CLOSE</p></button>
        <Text className="title">{rest.title}</Text>
        <Row style={{ alignSelf: 'stretch', gap: 16 }}>{rightButton}</Row>
      </Header>
      <ContainerContent height={height}>
        <Content height={height}>
          {rest.children}
        </Content>
      </ContainerContent>
    </AntModal >
  );
};

export default Modal;
const Header = styled(Row)`
  height: 50px;
  position: relative;
  align-items: center;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  border-bottom: 1px solid #CECECE;
  background: #fff;

  .title {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    color: #1D2129;
    text-align: center;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 120% */
  }
  button {
    background: #fff;
    align-self: stretch;
    border-radius: 5px;
    width: 80px;
    
    &:hover {
      background: #FFEFEB;
    }

    &:disabled {
      opacity: 0.5;
    }
  }
  .text-action {
    color: #F05326;
    font-family: Poppins;
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 133.333% */
    white-space: nowrap;
  }
`;

const ContainerContent = styled.div<{ height?: string }>`
  max-height: ${({ height }) => height || '80vh'};
  overflow-y: auto;
  position: relative;
  display: flex;
  flex-direction: column;
`;

const Content = styled.div<{ height?: string }>`
  min-height: ${({ height }) => height || '80vh'};
  display: flex;
  flex:1;
  flex-direction: column;
`;
