
import { AxiosResponse } from 'axios';
import { all, call, delay, put, takeLatest } from 'redux-saga/effects';
import { ISagaFunc } from 'store/actionConfigs';
import actions from './actions';
import apis from './apis';
import { turnUIActions } from './reducers';
import { CSStoreAPI } from 'services/request';
import { IStaffTurnItem, IStaffTurnTicketItem, ITicketTurnItem } from './types/staff';

const getListStaffTurns = function* () {
  try {
    const res: AxiosResponse<{ payload: IStaffTurnItem[] }> = yield call(apis.getListStaff);
    if (res.data.payload) {
      yield put(actions.data.listStaffs.success(res.data.payload));
    }
  } catch (error) {
    yield put(actions.data.listStaffs.fail({}));
  } finally {
    yield put(turnUIActions.setLoading(false));
  }
};

const getAllTurnsData = function* () {
  try {
    const res: AxiosResponse<{ payload: ITicketTurnItem[] }> = yield call(apis.getAllTurnsData);
    if (res.data.payload) {
      yield put(actions.data.turns.success(res.data.payload));
    }
  } catch (error) {
    yield put(actions.data.turns.fail({}));
  }
};

const getOpenTicketsByStaffId: ISagaFunc<string> = function* ({ payload }) {
  if (!payload) return;
  try {
    const res: AxiosResponse<{ payload: { tickets: IStaffTurnTicketItem[] } }> = yield call(apis.getOpenTicketsByStaffId, payload);
    if (res.data.payload) {
      yield put(actions.getOpenTicketsByStaffId.success(res.data.payload.tickets));
    }
  } catch (error) {
    yield put(actions.getOpenTicketsByStaffId.fail({}));
  }
};

const initData: ISagaFunc<string> = function* ({ payload }) {
  CSStoreAPI.shopId = payload;
  yield delay(200);
  yield all([
    put(actions.data.listStaffs.fetch()),
    put(actions.data.turns.fetch()),
  ]);
};

export default function* turnServiceSagas() {
  yield takeLatest(actions.data.listStaffs.fetch, getListStaffTurns);
  yield takeLatest(actions.data.turns.fetch, getAllTurnsData);
  yield takeLatest(actions.getOpenTicketsByStaffId.fetch, getOpenTicketsByStaffId);
  yield takeLatest(turnUIActions.initData, initData);

}
