import { Button, Empty, Flex, Select, RefSelectProps } from 'antd';
import { remove } from 'lodash';
import React, { useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import uuid from 'utils/uuid';
import qbSignal from './signal';
import aptSelectors from 'services/selectors/apt';

const ServicesSection = () => {
  const selectRef = useRef<RefSelectProps>(null);
  const services = aptSelectors.quickBooking.services();

  const [open, setOpen] = useState(false);

  const options = useMemo(() => services.map(o => ({
    label: o.serviceName,
    value: o.id,
    id: o.id,
  })), [services]);

  const onSelectOption = (_value: any) => {
    const exist = options.find(o => o.value === _value);
    const newItem = {
      label: exist?.label || _value,
      value: uuid(),
      id: exist?.id || '',
    };
    const temp = [...qbSignal.services.value];
    temp.push(newItem);
    selectRef.current?.blur();
    qbSignal.services.value = temp;
  };

  const onRemove = (_value: string): React.MouseEventHandler<HTMLElement> => (e) => {

    e.preventDefault(); e.stopPropagation();
    const temp = [...qbSignal.services.value];
    remove(temp, o => o.value === _value);
    qbSignal.services.value = temp;
  };

  return (
    <ServicesContainer>
      <div className="section-label">SERVICES</div>
      <Select
        // mode="multiple"
        mode="tags"
        style={{ width: '100%' }}
        placeholder="Enter your services"
        value={qbSignal.services.value}
        options={options}
        open={open}
        ref={selectRef}
        onFocus={() => setOpen(true)}
        onBlur={() => setOpen(false)}
        notFoundContent={<Empty />}
        onSelect={onSelectOption}
        tagRender={item => (<Button className='tag-render-item' onClick={onRemove(item.value)}><span>{item.label}</span><IconClose /></Button>)}
      />
      <Flex gap="16px" wrap="wrap" className='tag-suggestions'>
        {options.map(o => (
          <Button onClick={() => onSelectOption(o.value)} key={o.value} type='text' size='small' className='tag-item'>
            <span color="#C5DDF8">{o.label}</span>
          </Button>
        ))}
      </Flex>
    </ServicesContainer>
  );
};

export default ServicesSection;

const ServicesContainer = styled.div`
display: flex;
flex-direction: column;
gap: 8px;

.tag-close {
  display: flex;
  width: 24px;
  height: 24px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 5px;
  background: #FFF;
}

.ant-select-multiple {
  min-height: 64px;
  width: 100%;

  .ant-select-arrow {
    display: none;
  }

  .ant-select-selector {
    padding: 12px;
    .ant-select-selection-search {
    width: 150px !important;
    }
    .ant-select-selection-search-input {
      color: #1D2129;
      font-family: Poppins;
      font-size: 22px;
      font-weight: 400;
      line-height: normal;
    }
    .ant-select-selection-overflow {
      gap: 12px;

      .ant-select-selection-overflow-item {
        .tag-render-item {
          display: flex;
          padding: 0;
          border: 1px solid #CECECE;
          padding: 2px 8px;
          height: 42px;
          gap: 8px;
          align-items: center;

          span {
            overflow: hidden;
            color: #1D2129;
            text-overflow: ellipsis;
            font-family: Poppins;
            font-size: 24px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
          }
        }
      }
    }
  }
}

&.ant-form-large .ant-form-item .ant-form-item-label>label {
  height: 24px;
}

.tag-suggestions {
  gap: 16px;
  .tag-item {
    border-radius: 100px;
    background: #C5DDF8;
    display: flex;
    padding: 4px 12px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    align-self: stretch;
    height: 32px;

    span {
      color: #1D2129;
      text-align: center;
      font-family: Poppins;
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: 22px;
    }
  }
}
`;

const IconClose = () => (
  <div className='tag-close'>
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <path fillRule="evenodd" clipRule="evenodd" d="M14.8186 1.18114C15.1952 1.55773 15.1952 2.16829 14.8186 2.54488L2.54491 14.8186C2.16833 15.1951 1.55776 15.1951 1.18117 14.8186C0.804584 14.442 0.804585 13.8314 1.18117 13.4548L13.4548 1.18114C13.8314 0.804552 14.442 0.804552 14.8186 1.18114Z" fill="#148CF1" />
      <path fillRule="evenodd" clipRule="evenodd" d="M1.18141 1.18114C1.558 0.804552 2.16857 0.804552 2.54515 1.18114L14.8188 13.4548C15.1954 13.8314 15.1954 14.442 14.8188 14.8186C14.4422 15.1951 13.8317 15.1951 13.4551 14.8186L1.18141 2.54488C0.804823 2.16829 0.804823 1.55773 1.18141 1.18114Z" fill="#148CF1" />
    </svg>
  </div>
);