import { Row } from 'antd';
import Text from 'components/Text';
import React from 'react';
import styled from 'styled-components';
import { IServiceUI } from 'types/service';
import { formatCurrency } from 'utils/formatCurrency';

type Props = {
  data: IServiceUI;
  active?: boolean;
  onClick?: () => void;
};

const Service = ({ data, active, onClick }: Props) => {
  return (
    <ServiceStyled className={`${active ? 'active' : ''}`} onClick={onClick}>
      {data.image ? <img src={data.image} /> : <BoxImageEmpty><ImageEmpty /></BoxImageEmpty>}
      <Content>
        <Row align={'middle'} justify={'space-between'}>
          <Text className="name">{data.name}</Text>
          {active && <IconActive />}
        </Row>
        <Row align={'middle'} justify={'space-between'}>
          <Text className="price">{formatCurrency(data.price)}</Text>
          <Text className="price">({data.duration}m)</Text>
        </Row>
      </Content>
    </ServiceStyled>
  );
};

export default Service;

const ServiceStyled = styled.button`
width: 100%;
display: flex;
align-items: center;
border-radius: 5px;
border: 1px solid #CECECE;
background: #F7F9FA;
padding: 8px;
  img {
    width: 75px;
    height: 75px;
    border-radius: 5px;
  }

  &:hover {
    background: #FFEFEB;
  }

  &.active {
    background: #c5ddf86e;
    border: 1px solid #148CF1;
  }
`;
const Content = styled.div`
  flex-grow: 1;
  padding-left: 8px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-self: stretch;
  .name {
    overflow: hidden;
    color: #1D2129;
    text-overflow: ellipsis;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-align: left;

    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    align-self: stretch;
  }

  .price {
    color: #1D2129;
    font-family: Poppins;
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
`;

const IconActive = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="33" height="33" viewBox="0 0 33 33" fill="none">
    <rect x="0.5" y="0.5" width="32" height="32" rx="2" fill="#148CF1" />
    <path d="M13.7269 22.4737C13.4068 22.4737 13.1026 22.3456 12.8785 22.1215L8.34817 17.5913C7.88394 17.127 7.88394 16.3587 8.34817 15.8944C8.8124 15.4302 9.58078 15.4302 10.045 15.8944L13.7269 19.5762L21.955 11.3482C22.4192 10.8839 23.1876 10.8839 23.6518 11.3482C24.1161 11.8124 24.1161 12.5808 23.6518 13.045L14.5753 22.1215C14.3512 22.3456 14.0471 22.4737 13.7269 22.4737Z" fill="white" />
  </svg>
);

const ImageEmpty = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="40" height="30" viewBox="0 0 40 30" fill="none">
    <g clipPath="url(#clip0_4105_2601)">
      <path fillRule="evenodd" clipRule="evenodd" d="M28.4147 5.86343C29.1455 5.86343 29.8598 6.07494 30.4674 6.4712C31.0749 6.86747 31.5485 7.43071 31.8281 8.08968C32.1078 8.74865 32.1809 9.47376 32.0384 10.1733C31.8958 10.8729 31.5439 11.5155 31.0272 12.0198C30.5105 12.5242 29.8522 12.8676 29.1355 13.0068C28.4188 13.1459 27.6759 13.0745 27.0008 12.8016C26.3257 12.5286 25.7487 12.0664 25.3427 11.4733C24.9367 10.8803 24.7201 10.183 24.7201 9.46976C24.7201 8.99617 24.8156 8.52722 25.0013 8.08968C25.187 7.65214 25.4591 7.25458 25.8022 6.9197C26.1453 6.58482 26.5526 6.31918 27.0008 6.13794C27.4491 5.95671 27.9295 5.86343 28.4147 5.86343ZM37.3112 29.5H2.6888C1.976 29.497 1.29351 29.2182 0.791016 28.7247C0.540835 28.4819 0.342188 28.1933 0.206452 27.8754C0.0707171 27.5576 0.000560176 27.2167 0 26.8723L0 3.12452C0.00012121 2.4285 0.283484 1.76103 0.78776 1.26893C1.29192 0.776707 1.97573 0.500118 2.6888 0.5L37.3112 0.5C38.0236 0.501803 38.7062 0.779497 39.209 1.2721C39.7133 1.76421 39.9966 2.43168 39.9967 3.1277V26.8723C39.9966 27.2165 39.927 27.5572 39.7918 27.8751C39.6566 28.1929 39.4586 28.4816 39.209 28.7247C38.7065 29.2182 38.024 29.497 37.3112 29.5ZM34.9186 26.5037L28.5156 16.5331C28.3831 16.3276 28.1994 16.1583 27.9816 16.041C27.7639 15.9237 27.5192 15.8621 27.2705 15.8621C27.0218 15.8621 26.7771 15.9237 26.5594 16.041C26.3416 16.1583 26.1579 16.3276 26.0254 16.5331L23.0013 21.2769L26.2923 26.5037H24.9414L15.9668 12.5232C15.14 11.4461 13.8151 11.5287 13.0762 12.5232L4.28711 26.5037H3.06641V3.4931H36.9206V26.5037H34.9186Z" fill="#7B7B7B" />
    </g>
    <defs>
      <clipPath id="clip0_4105_2601">
        <rect width="40" height="29" fill="white" transform="translate(0 0.5)" />
      </clipPath>
    </defs>
  </svg>
);

const BoxImageEmpty = styled.div`
width: 75px;
height: 75px;
border-radius: 5px;
background: #D9D9D9;
display: flex;
align-items: center;
justify-content: center;
`;