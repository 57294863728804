import { createAction, createAsyncAction } from 'store/actionConfigs';
import { ICustomerUI } from 'types/customer';
import { IServiceUI } from 'types/service';

const PREFIX_ACTIONS = 'shop_feature_';

const afterCreateCustomer = createAction<ICustomerUI>(PREFIX_ACTIONS + 'afterCreateCustomer');
const afterCreateService = createAction<IServiceUI>(PREFIX_ACTIONS + 'afterCreateService');

const shopActions = {
  staff: createAsyncAction(PREFIX_ACTIONS + 'getStaffs'),
  category: createAsyncAction(PREFIX_ACTIONS + 'category'),
  customer: createAsyncAction(PREFIX_ACTIONS + 'customer'),
  afterCreateCustomer,
  afterCreateService,
};

export default shopActions;

