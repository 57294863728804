import React from 'react';
import Modal from '../Modal';
import styled from 'styled-components';

const TurnStaffAvailable = () => {
  return (
    <Modal title='HARLEY' width='583px' height={'auto'}>
      <Container>
        <div className="turn-dashboard">
          <div className="turn-dashboard-item turn-dashboard-bonus">
            <span>Bonus:</span>
            <span className="value">2</span>
          </div>
          <div className="turn-dashboard-item turn-dashboard-partial">
            <span>Partial:</span>
            <span className="value">1,5</span>
          </div>
        </div>
        <div className="actions">
          <button className="action-item clock-out"><span>CLOCK-OUT</span></button>
          <button className="action-item adjust-turn"><span>ADJUST TURN</span></button>
          <button className="action-item remove-late-turn"><span>REMOVE LATE TURN</span></button>
        </div>
      </Container>
    </Modal>
  );
};

export default TurnStaffAvailable;

const Container = styled.div`
border-radius: 0px 0px 5px 5px;
background: #FFF;
padding: 24px 8px 40px 8px;

.turn-dashboard {
  display: flex;
  height: 36px;
  padding: 0px 8px;
  align-items: center;
  gap: 16px;
  flex-shrink: 0;
  background: #EEE;
  margin-bottom: 24px;

  .turn-dashboard-bonus {
    display: flex;
    padding-right: 16px;
    justify-content: flex-end;
    align-items: center;
    gap: 16px;
    flex: 1 0 0;
    border-right: 1px solid #CECECE;
  }

  .turn-dashboard-partial {
    display: flex;
    align-items: center;
    gap: 16px;
    flex: 1 0 0;
  }

  .turn-dashboard-item {
    span {
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
      overflow: hidden;
      color: #1D2129;
      text-overflow: ellipsis;
      font-family: Poppins;
      font-size: 24px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;

      &.value {
        font-size: 24px;
        font-weight: 600;
      }
    }
  }
}

.actions {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 8px;

  .action-item {
    display: flex;
    height: 56px;
    padding: 0px 8px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    flex: 1 0 0;

    span {
      color: #FFF;
      text-align: center;
      font-family: Poppins;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      text-transform: uppercase;
    }

    &.clock-out {
      background: #7B7B7B;
    }

    &.finish-ticket {
      background: #0CB76F;
    }

    &.adjust-turn {
      background: #148CF1;
    }

    &.remove-late-turn {
      background: #F56A00;
    }
  }

}
`;