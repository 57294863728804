import { signal } from '@preact/signals-react';
import { IDatePickerValue } from 'components/DatePicker';
import { first } from 'lodash';
import moment, { Moment } from 'moment';
import { IBlockHourItemData, IBodyAddBlockHours } from 'services/types/blockHours';
import { IStaffUI } from 'types/staff';
import { ITimelineItem } from 'utils/getTimeLines';
import { momentTimezone } from 'utils/time';
import { ALL_DAY_FLAT, FORMAT_DATE_PAYLOAD, getActiveTimeSlots, timeLogOpts } from './helpers';

const openSignal = signal<boolean>(false);
const activeDateSignal = signal<IDatePickerValue | null>(moment());
const listTimeSlotSignal = signal<ITimelineItem[]>([]);
const durationSignal = signal<number>(15);
const staffIdSignal = signal<string>('');
const startTimeSlot = signal<string>('');
const reasonSignal = signal<string>('');
const detailSignal = signal<IBlockHourItemData | null>(null);

const errorStaffSignal = signal<string>('');
const errorReasonSignal = signal<string>('');


const onChangeActiveDate = (value: IDatePickerValue | null) => {
  activeDateSignal.value = value;
  const timeSlots = getActiveTimeSlots(value);
  startTimeSlot.value = first(timeSlots)?.id || '';
  listTimeSlotSignal.value = timeSlots;
  durationSignal.value = 15;
};
const onSelectStaff = (data: IStaffUI) => {
  errorStaffSignal.value = '';
  staffIdSignal.value = data.id?.toString();
};
const onChangeDuration = (data: number) => {
  durationSignal.value = data;
};
const onChangeStartTime = (data: string) => {
  startTimeSlot.value = data;
};
const onChangeReason = (data: string) => {
  errorReasonSignal.value = '';
  reasonSignal.value = data;
};

const bhSignal = {
  openSignal,
  activeDateSignal, onChangeActiveDate,
  listTimeSlotSignal,
  staffIdSignal, onSelectStaff,
  startTimeSlot, onChangeStartTime,
  reasonSignal, onChangeReason,
  durationSignal, onChangeDuration,
  errorStaffSignal,
  errorReasonSignal,
  detailSignal,
  open: () => {
    openSignal.value = true;
    onChangeActiveDate(moment());
    staffIdSignal.value = '';
    reasonSignal.value = '';
    durationSignal.value = timeLogOpts?.[0]?.value || 15;
    detailSignal.value = null;
  },
  openNew: (staffId: string, _startTime: string) => {
    openSignal.value = true;
    const startTime = moment(_startTime);
    activeDateSignal.value = moment(startTime);
    const timeSlots = getActiveTimeSlots(startTime, true);
    listTimeSlotSignal.value = timeSlots;
    
    startTimeSlot.value = startTime?.format('hh:mm A');

    staffIdSignal.value = staffId;
    reasonSignal.value = '';
    durationSignal.value = timeLogOpts?.[0]?.value || 15;
    detailSignal.value = null;
  },
  openDetail: (detail: IBlockHourItemData) => {

    detailSignal.value = detail;
    openSignal.value = true;
    staffIdSignal.value = detail.staffId;
    reasonSignal.value = detail.note;

    const startTime = momentTimezone(detail.startTime);
    const endTime = momentTimezone(detail.endTime);

    activeDateSignal.value = startTime;
    const timeSlots = getActiveTimeSlots(startTime, true);
    listTimeSlotSignal.value = timeSlots;

    startTimeSlot.value = startTime.format('hh:mm A');
    const duration = endTime.diff(startTime, 'minutes');
    durationSignal.value = duration > 240 ? ALL_DAY_FLAT : duration;
  },
  close: () => {
    openSignal.value = false;
  },
  getPayload: () => {
    if (!staffIdSignal.value) {
      errorStaffSignal.value = 'Please select staff';
    }
    if (!reasonSignal.value) {
      errorReasonSignal.value = 'Please enter your reason';
    }
    if (!reasonSignal.value || !staffIdSignal.value) return null;
    if (!activeDateSignal.value) return null;
    if (!startTimeSlot.value) return null;
    if (!durationSignal.value) return null;

    const startDateMoment = moment(`${activeDateSignal.value?.format('MM-DD-YYYY')} ${startTimeSlot.value}`, 'MM-DD-YYYY hh:mm A');
    let endDateMoment: Moment | null = null;
    if (durationSignal.value === ALL_DAY_FLAT) {
      startDateMoment.set({ hour: 9, minute: 0, second: 0 });
      endDateMoment = startDateMoment.clone().set({ hour: 21, minute: 0, second: 0 });
    } else {
      endDateMoment = startDateMoment.clone().add(durationSignal.value, 'minute');
    }
    if (!endDateMoment) return null;


    const payload: IBodyAddBlockHours = {
      staffId: +staffIdSignal.value,
      endTime: endDateMoment.format(FORMAT_DATE_PAYLOAD),
      startTime: startDateMoment.format(FORMAT_DATE_PAYLOAD),
      reason: reasonSignal.value,
    };
    return payload;
  },
};

export default bhSignal;
