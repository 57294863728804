import { DragOverlay, useDndContext } from '@dnd-kit/core';
import React, { useMemo } from 'react';
import aptSelectors from 'services/selectors/apt';
import Appointment from '../Appointment';

export function DraggableOverlay() {
  const { active, over } = useDndContext();
  const data = aptSelectors.calendar.data();
  const item = useMemo(() => {
    const [bookingId] = active?.id?.toString()?.split('_') ?? [];
    return data.find(o => o?.id?.toString() === bookingId);
  }, [data, active]);

  return (
    <DragOverlay dropAnimation={null}>
      <p style={{ fontWeight: 600, fontSize: 16 }}>{over?.data?.current?.rowId}</p>
      {item ? <Appointment overlay key={item.id} data={item} /> : null}
    </DragOverlay>
  );
}
