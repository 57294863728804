import { IStaffTurnItem } from 'Turn/services/types/staff';
import { Avatar } from 'antd';
import styled from 'styled-components';


type Props = {
  data: IStaffTurnItem;
};
const TurnStaffTopRow = ({ data }: Props) => {
  return (
    <Container>
      <Avatar src={data.urlImage || 'fail'} style={{ backgroundColor: '#f56a00' }}>{data.firstName?.[0] || 'A'}</Avatar>
      <div>
        <p className="staff-name">{data.firstName}</p>
        <div className="turn-info">
          <div className="turn-info-item">
            <span className='apt'>A: {data?.totalAppointment || 0}</span>
          </div>
          <div className="divider" />
          <div className="turn-info-item">
            <span className='bonus'>B: {data?.totalBonus || 0}</span>
          </div>
          <div className="divider" />
          <div className="turn-info-item">
            <span className='partial'>P: {data?.totalPartial || 0}</span>
          </div>
        </div>
      </div>
      <div className="turn-count-wrap">
        <div className="turn-count-box">
          <span>{data?.turn || 0}</span>
        </div>
      </div>
    </Container>
  );
};

export default TurnStaffTopRow;

const Container = styled.div`
display: flex;
padding: 0 8px;

.ant-avatar {
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  z-index: 1;
  justify-content: center;
  margin-right: 8px;

  .ant-avatar-string {
    font-size: 20px;
  }
}

.staff-name {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  align-self: stretch;
  overflow: hidden;
  color: #1D2129;
  text-overflow: ellipsis;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
}

.turn-info {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  display: flex;
  width: 115px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  background: #EEE;
  .divider {
    background-color: #CECECE;
    width: 1px;
    height: 17px;
  }
  .turn-info-item {
    span.apt {
      color: #148CF1;
      font-family: Poppins;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }

    span.bonus {
      color: #0CB76F;
      font-family: Poppins;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }

    span.partial {
      color: #F05326;
      font-family: Poppins;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
  }
}

.turn-count-wrap {
  display: flex;
  justify-content: center;
  margin-left: 8px;
  .turn-count-box {
    display: flex;
    width: 28px;
    height: 28px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 100px;
    background: #148CF1;

    span {
      color: #FFF;
      font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
  }
}
`;