import moment from 'moment';
import noDataDefault from './no-data-default.svg';
import noData1 from './no-data-1.svg';
import noData2 from './no-data-2.svg';
import noData3 from './no-data-3.svg';
import noData4 from './no-data-4.svg';
import noData5 from './no-data-5.svg';
import noData6 from './no-data-6.svg';

export enum CALENDAR_VIEW_TYPE {
  DAY_VIEW = 'DAY_VIEW',
  WEEK_VIEW = 'WEEK_VIEW',
  MONTH_VIEW = 'MONTH_VIEW',
}

export const CALENDAR_TIME_SET = {
  open: moment().set({ hour: 9, minute: 0, second: 0 }),
  close: moment().set({ hour: 23, minute: 0, second: 0 }),
};

export const APPOINTMENT_SOURCE_TYPE = {
  WEBSITE: 1,
  CALl_IN: 0
};

export const ColorSets = {
  default: {
    background: '#FFFFFF',
    aptBg: '#FFFFFF',
    stroke: '#95B8D1',
    shadow: '#0D446C',
    text: '#1D2129',
    bottomBG: noData1,
  },
  0: {
    background: '#E5E3E6',
    aptBg: '#E5E3E6',
    stroke: '#E5E3E6',
    shadow: '#7D7573',
    text: '#1D2129',
    bottomBG: noDataDefault,
  },
  1: {
    background: '#809BCE',
    aptBg: '#EEF4FF',
    stroke: '#EEF4FF',
    shadow: '#809BCE',
    text: '#1D2129',
    bottomBG: noData1,
  },
  2: {
    background: '#E4D3E7',
    aptBg: '#E4D3E7',
    stroke: '#E4D3E7',
    shadow: '#482986',
    text: '#1D2129',
    bottomBG: noData2,
  },
  3: {
    background: '#FDFEDB',
    aptBg: '#FDFEDB',
    stroke: '#FDFEDB',
    shadow: '#F4F439',
    text: '#1D2129',
    bottomBG: noData3,
  },
  4: {
    background: '#E6FAE2',
    aptBg: '#E6FAE2',
    stroke: '#E6FAE2',
    shadow: '#6AC45F',
    text: '#1D2129',
    bottomBG: noData4,
  },
  5: {
    background: '#F2CEE6',
    aptBg: '#F2CEE6',
    stroke: '#F2CEE6',
    shadow: '#A63272',
    text: '#1D2129',
    bottomBG: noData5,
  },
  6: {
    background: '#FFFFFF',
    aptBg: '#FFFFFF',
    stroke: '#EEF4FF',
    shadow: '#0D446C',
    text: '#1D2129',
    bottomBG: noData6,
  },
};