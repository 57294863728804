import { Form, Input, InputRef, Row, Select, Spin } from 'antd';
import { AxiosResponse } from 'axios';
import DateMonthInput from 'components/BirthdayInput';
import Modal from 'components/Modal';
import PhoneInput from 'components/PhoneInput';
import moment from 'moment';
import { useRef, useState } from 'react';
import apis from 'services/apis';
import { IBodyUpdateCustomerDetail } from 'services/types/apt';
import { IAddNewCustomerResData } from 'services/types/response';
import styled from 'styled-components';
import { ICustomerInfoResData, ICustomerUI } from 'types/customer';
import { formatPhone } from 'utils/formatPhone';

interface IAddCustomerFormValues {
  name: string;
  phoneNumber: string;
  email: string;
  birthday: string;
  gender: string;
}

type Props = {
  onSelect: (data: ICustomerUI) => void;
  id: string;
};

const EditCustomer = ({ onSelect, id }: Props) => {
  const inputRef = useRef<InputRef>(null);
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const [open, setOpen] = useState(false);
  const [isWalkIn, setIsWalkIn] = useState(false);

  const fetchInfo = async (_id: string) => {
    setLoading(true);
    try {
      const res: AxiosResponse<{ payload: ICustomerInfoResData }> = await apis.getDetailCustomer(_id);
      const detail = res?.data?.payload;
      if (detail) {
        const body: IAddCustomerFormValues = {
          phoneNumber: formatPhone(detail.phone),
          email: detail.email,
          name: detail.name,
          birthday: moment(detail.birthday).format('MM/DD/YYYY'),
          gender: detail.gender || '',
        };
        setIsWalkIn(false);
        form.setFieldsValue(body);
      } else {
        setIsWalkIn(true);
      }
    } catch (error) {
      setIsWalkIn(true);
    }
    finally {
      setLoading(false);
    }
  };


  const handleFinish = async (_values: any) => {
    const values = _values as IAddCustomerFormValues;
    if (isWalkIn) {
      const newCus: ICustomerUI = {
        id: 're-update-walk-in',
        name: values.name,
        phone: values.phoneNumber,
      };
      onSelect(newCus);
      handleClose();
      return;
    }
    const body: IBodyUpdateCustomerDetail = {
      birthDay: values.birthday,
      email: values.email,
      fullName: values.name,
      gender: values.gender,
      phone: values.phoneNumber?.replace(/[^0-9]/g, '')
    };
    setLoading(true);
    try {
      const res: AxiosResponse<{ payload: IAddNewCustomerResData | null }> = await apis.updateCustomerDetail(id, body);
      const data = res?.data?.payload;

      if (data) {
        const newCus: ICustomerUI = {
          id: data.id?.toString(),
          name: data.name,
          phone: data.phone,
        };
        onSelect(newCus);
        handleClose();
      }
    } catch (error) { } finally {
      setLoading(false);
    }
  };

  const handleOpen = () => {
    setOpen(true);
    fetchInfo(id);
  };

  const handleClose = () => {
    setOpen(false);
    form.resetFields();
  };

  return (
    <>
      <ButtonChange onClick={handleOpen}><Change /></ButtonChange>
      <Modal
        open={open}
        onCancel={handleClose}
        onOk={() => form.submit()}
        title="EDIT CUSTOMER"
        width={'60vw'}
        height={'340px'}
        disabledOk={loading}
      >
        <Spin spinning={loading}>
          <FormStyled form={form} layout='vertical' size='large' onFinish={handleFinish} validateTrigger={['onSubmit']}>
            <Row className='group'>
              <Form.Item className='col-item' name={'name'} label={'CUSTOMER NAME'} rules={[{ required: true }]}
              >
                <Input ref={inputRef} placeholder='Please enter' />
              </Form.Item>
            </Row>
            <Row className='group'>
              <Form.Item className='col-item' name={'phoneNumber'} label={'PHONE NUMBER'} rules={[{ required: true }]}
              >
                <PhoneInput />
              </Form.Item>
              <Form.Item className='col-item' name={'email'} label={'EMAIL'} rules={[{ type: 'email' }]}>
                <Input placeholder='Please enter' />
              </Form.Item>
            </Row>
            <Row className='group'>
              <Form.Item className='col-item' name={'birthday'} label={'BIRTHDAY'}>
                <DateMonthInput />
              </Form.Item>
              <Form.Item className='col-item' name={'gender'} label={'GENDER'}>
                <Select
                  defaultValue="none"
                  options={[
                    { value: 'male', label: 'Male' },
                    { value: 'female', label: 'Female' },
                    { value: 'none', label: 'None' },
                  ]}
                />
              </Form.Item>
            </Row>
          </FormStyled>
        </Spin>
      </Modal>
    </>
  );
};

export default EditCustomer;

const FormStyled = styled(Form)`
  padding: 1rem 1.5rem;
  .group {
    gap: 1.5rem;
  }
  .col-item {
    flex:1;
  }
  input.ant-input {
    line-height: 2;
    &::placeholder {
      color:#dadada;
    }
  }
  .ant-select-single.ant-select-lg {
    height: 46px;
  }
  &.ant-form-large .ant-form-item .ant-form-item-label>label {
    height: 24px;
  }
`;

const ButtonChange = styled.button`
  display: flex;
  height: 30px;
  width: 40px;
  margin-left: 4px;
  align-items: center;
  justify-content: center;
  &:hover {
    background: #e9e8e8;
  }
`;

const Change = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path fillRule="evenodd" clipRule="evenodd" d="M6.69816 6.69489C7.81664 5.57642 9.19776 4.88621 10.6424 4.62156L10.282 2.6543C8.45093 2.98975 6.69852 3.86611 5.28395 5.28068C1.57397 8.99066 1.57397 15.0057 5.28395 18.7157L3.57684 20.4228H8.40527V15.5944L6.69816 17.3015C3.76923 14.3726 3.76923 9.62383 6.69816 6.69489ZM20.4243 3.57535H15.5959V8.40377L17.303 6.69667C20.2319 9.6256 20.2319 14.3743 17.303 17.3033C16.1845 18.4217 14.8034 19.1119 13.3587 19.3766L13.7191 21.3439C15.5502 21.0084 17.3026 20.132 18.7172 18.7175C22.4272 15.0075 22.4272 8.99243 18.7172 5.28245L20.4243 3.57535Z" fill="#148CF1" />
    </svg>
  );
};
