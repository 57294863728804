import turnSelectors from 'Turn/services/selectors';
import { IStaffTurnTicketItem } from 'Turn/services/types/staff';
import { Empty, Row } from 'antd';
import React from 'react';
import styled from 'styled-components';
import { formatCurrency } from 'utils/formatCurrency';
import { maskPhone } from 'utils/formatPhone';
type Props = {
  onFinishTurnTicket: (o: IStaffTurnTicketItem) => void;
};
const InfoTurn = ({ onFinishTurnTicket }: Props) => {
  const staffTickets = turnSelectors.staffTickets();
  return (
    <Container>
      <TableTickets >
        <thead>
          <tr>
            <th className="section-title ticket-number-col" style={{ textAlign: 'center' }}>Ticket#</th>
            <th className="section-title">Customer</th>
            <th className="section-title">DATE AND TIME</th>
            <th className="section-title">Services</th>
            <th className="section-title">Total</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {!staffTickets.length && <tr>
            <td colSpan={6}>
              <Empty description="Empty Ticket" style={{ marginBottom: 16 }} />

            </td>
          </tr>}
          {staffTickets.map((o, i) => (
            <React.Fragment key={o.billId}>
              <tr>
                <td className='ticket-number-col'>
                  <div className="wrap-ticket-number"><div className="ticket-number"><span>#{o.billNumber}</span></div></div>
                </td>
                <td>
                  {o.customerName} - {maskPhone(o.customerPhone)}
                </td>
                <td>{o.createdDate}</td>
                <td>{o.serviceNames.join(', ')}</td>
                <td>{formatCurrency(o.total)}</td>
                <td>
                  <Row justify={'end'}>
                    <button disabled={!!o.isFinishTurn} onClick={() => onFinishTurnTicket(o)} className="action-item finish-ticket"><span>FINISH TICKET</span></button>
                  </Row>
                </td>

              </tr>
              <tr>
                <td colSpan={6} style={{ padding: '16px 0' }}>
                  {staffTickets.length - 1 !== i && <div style={{ borderBottom: '1px solid rgb(123 123 123 / 32%)' }}></div>}
                </td>
              </tr>
            </React.Fragment>
          ))}
        </tbody>
      </TableTickets>
    </Container>
  );
};

export default InfoTurn;
const TableTickets = styled.table`
  width: 100%;
  th {
    text-align: left;
  }
  td {
    text-align: left;
    color: #1D2129;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    text-transform: uppercase;
  }
    .ticket-number-col {
      width: 70px;
      padding-right: 16px;
      .wrap-ticket-number {
        justify-content: center;
      }
    }

    .action-item {
    display: flex;
    height: 48px;
    padding: 0px 8px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    flex: 1 0 0;
    border-radius: 5px;

    span {
      color: #FFF;
      text-align: center;
      font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      text-transform: uppercase;
    }

    &.finish-ticket {
      background: #0CB76F;
      &:disabled {
        opacity: 0.7;
      }
    }
  }
`;
const Container = styled.div`
&.info-groups {
  display: flex;
  align-items: center;
  width: 100%;
  gap: 16px;
  justify-content: space-between;
}

.info-item {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
}

.wrap-ticket-number {
  display: flex;

  .ticket-number {
    display: flex;
    padding: 0px 16px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 5px;
    background: #1D2129;

    span {
      color: #FFF;
      font-family: Poppins;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      text-transform: uppercase;
    }
  }
}


.cus-info {
  color: #1D2129;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  text-transform: uppercase;
}

.staff-info {
  display: flex;
  align-items: center;
  gap: 8px;

  .ant-avatar {
    height: 40px;
    width: 40px;
    display: flex;
    align-items: center;
    z-index: 1;
    justify-content: center;

    .ant-avatar-string {
      font-size: 20px;
    }
  }

  .staff-name {
    color: #1D2129;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;
  }
}

.time-info {
  color: #1D2129;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  text-transform: uppercase;
}
`;