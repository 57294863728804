import { get } from 'lodash';
import { RootState } from 'store';
import { useAppSelector } from 'store/hooks';

type MyState = RootState['turn'];
const getCurrentState = (state: RootState): MyState => state['turn'];

const selector = <T = MyState>(key: keyof T, defaultValue?: any) => useAppSelector(state => get(getCurrentState(state), key, defaultValue));

const staffs = () => selector('staffs') as MyState['staffs'];
const staffTickets = () => selector('staffTickets') as MyState['staffTickets'];
const turns = () => selector('turns') as MyState['turns'];
const loading = () => selector('loading') as MyState['loading'];

const turnSelectors = {
  selector,
  staffs,
  staffTickets,
  turns,
  loading,
};
export default turnSelectors;
