import { loadingSignal } from 'components/Loading';
import React, { forwardRef, useImperativeHandle, useState } from 'react';
import apis from 'services/apis';
import storage from 'utils/sessionStorage';
import Modal from './Modal';
import Text from 'components/Text';
import styled from 'styled-components';
type Props = {};
type Ref = {
  open: (aptId: string) => void;
  close: () => void;
};
export const modalConfirmSMSChangeRef = React.createRef<Ref>();
const ModalConfirmSMSChange = forwardRef<Ref, Props>(({ }, ref) => {
  const [aptId, setAptId] = useState<string | null>(null);
  const [visible, setVisible] = useState(false);

  const handleClose = () => setVisible(false);
  const handleOk = async () => {
    if (!aptId) return;
    loadingSignal.value = true;
    try {
      await apis.sendSmsChangeAppointment(storage.shopId.get(), aptId);
      handleClose();
    } catch (error) {
    } finally {
      loadingSignal.value = false;
    }
  };
  useImperativeHandle(ref, () => ({
    open: (_aptId: string) => {
      setAptId(_aptId || null);
      setVisible(true);
    },
    close: () => setVisible(false),
  }));

  return (
    <Modal cancelText='NO' okText='YES' open={visible} title="SMS Confirm" onCancel={handleClose} onOk={handleOk} width='600px' height='auto'>
      <Container>
        <Text variant='CONTENT_1' textAlign='center'>Do you want to send a confirmation message to the customer about this appointment change?</Text>
      </Container>
    </Modal>
  );
});
ModalConfirmSMSChange.displayName = 'ModalConfirmSMSChange';
export default ModalConfirmSMSChange;

const Container = styled.div`
  padding: 0 16px;
  padding-top: 16px;
`;