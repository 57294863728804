import { UniqueIdentifier, useDroppable } from '@dnd-kit/core';
import aptSignal from 'AppointmentBox/signal';
import bhSignal from 'BlockHourBox/signal';
import { calendarDOM } from 'Calendar/styles';
import { ICalendarDndData } from 'Calendar/types';
import qbSignal from 'QuickBooking/signal';
import { Popover } from 'antd';
import classNames from 'classnames';
import Text from 'components/Text';
import moment from 'moment';
import React, { CSSProperties, memo, useMemo, useState } from 'react';
import styled from 'styled-components';

interface Props {
  children?: React.ReactNode;
  dragging?: boolean;
  id: UniqueIdentifier;
  className?: string,
  data: ICalendarDndData;
  distance?: number;
  rowSpan?: number;
  disabled?: boolean;
}

const overlayInnerStyle: CSSProperties = { borderRadius: 0, boxShadow: 'none', padding: 0, background: 'transparent' };

const Droppable = ({ id, disabled, dragging, className, data, distance = 0, rowSpan = 1, children }: Props) => {
  const [open, setOpen] = useState(false);
  const ChildTimes = useMemo(() => {
    const [rowTime = '', col_id = ''] = id?.toString().split('/') ?? [];
    const [hour = 0, minute = 0] = rowTime?.split(':') ?? [];
    const date = data.colData.rowTime?.clone().set({
      hour: +hour,
      minute: +minute,
    });

    const result: ICalendarDndData[] = [];
    Array.from(Array(rowSpan).keys()).forEach(() => {
      result.push(
        {
          id: date?.format('HH:mm') + '/' + col_id,
          rowId: date?.format('HH:mm'),
          colData: data.colData,
          rowTime: date?.clone(),
        }
      );
      date?.add(15, 'minute');
    });
    return result.map(o => <DropChild id={o?.id ?? ''} key={o.id} data={o} dragging={!!dragging} />);
  }, [distance, rowSpan, data, id]);

  const onDoubleClick = () => {
    if (data.colData?.headerData.id === '0') {
      if (data?.colData?.rowTime?.isBefore(moment(), 'date')) return;
      if (children) return;
      setOpen(true);
      return;
    }
    setOpen(true);
  };

  const closeDropDown = () => setOpen(false);

  const onPressAction = (type: 'new-appointment' | 'block-hour' | 'quick-booking' | 'break-time') => () => {
    closeDropDown();
    switch (type) {
      case 'new-appointment': {
        if (data?.colData?.rowTime?.isBefore(moment(), 'date')) return;
        if (children) return;
        aptSignal.open(data?.colData?.rowTime?.format(), data.colData?.headerData?.id || '');
        break;
      }
      case 'quick-booking': {
        if (data?.colData?.rowTime?.isBefore(moment(), 'date')) return;
        if (children) return;
        qbSignal.open(data.colData?.headerData?.id || '', data?.colData?.rowTime?.format());
        break;
      }
      case 'block-hour': {
        if (children) return;
        bhSignal.openNew(data.colData?.headerData?.id || '', data?.colData?.rowTime?.format());
        break;
      }
      default:
        break;
    }
  };

  return (
    <DroppableStyled
      className={`wrap ${className} ${disabled ? 'disabled' : ''}`}
      aria-label="Droppable region"
      active={!!open}
    >
      <>
        <div className='children'
          onDoubleClick={disabled ? () => undefined : onDoubleClick}
        // onTouchEnd={onDoubleClick}
        >{children}</div>
        <div className='absolute'>
          {ChildTimes}
        </div>
        <PopoverContent
          open={open}
          setOpen={setOpen}
          data={data}
          onPressAction={onPressAction}
        />
      </>
    </DroppableStyled>
  );
};


export default memo(Droppable);

type PopoverContentProps = {
  open: boolean;
  setOpen: (val: boolean) => void;
  data: ICalendarDndData;
  onPressAction: (type: 'new-appointment' | 'block-hour' | 'quick-booking' | 'break-time') => () => void
};
const PopoverContent = ({ open, setOpen, data, onPressAction }: PopoverContentProps) => {

  const content = useMemo(() => {
    const isAnyone = data.colData?.headerData.id === '0';
    return (
      <PopoverContentStyled>
        <button className='box' onClick={onPressAction('quick-booking')}>
          <Text variant='CONTENT_1' color='#1D2129' >Quick Booking</Text>
        </button>
        {!isAnyone &&
          <button className='box' onClick={onPressAction('block-hour')}>
            <Text variant='CONTENT_1' color='#1D2129' >Block Hours</Text>
          </button>}
        <button className='box' onClick={onPressAction('new-appointment')}>
          <Text variant='CONTENT_1' color='#1D2129' >Book Appointment</Text>
        </button>
      </PopoverContentStyled>
    );
  }, [data]);

  if (!open) return null;

  return (
    <>
      <Popover
        open={open}
        onOpenChange={setOpen}
        arrow={false}
        placement="right"
        content={content}
        trigger="click"
        align={{ offset: [16] }}
        overlayInnerStyle={overlayInnerStyle}
        // @ts-ignore 
        getPopupContainer={() => calendarDOM.current}
      />
    </>
  );
};

PopoverContent.displayName = 'PopoverContent';
const PopoverContentStyled = styled.div`
  border-radius: 5px;
  border: 1px solid #86909C;
  background: #FFF;
  width: 18rem;
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 8px;
  /* box */
  box-shadow: -1px 1px 4px 0px rgba(0, 0, 0, 0.10) inset, 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  .box {
    padding: 0.5rem 1rem;
    border-radius: 4px;
    text-align: left;
    .CONTENT_1 {
      font-size: 18px;
    }
    &:nth-child(even) {
      background:rgba(201, 211, 219, 0.7)
    }
    &:hover {
      background:rgba(201, 211, 219, 0.7)
    }
  }
`;


const DropChild = ({ id, dragging, data, children }: { id: string, data: ICalendarDndData, dragging: boolean; children?: React.ReactNode }) => {
  const { isOver, setNodeRef } = useDroppable({
    id,
    data
  });
  return (
    <DropChildStyled
      ref={setNodeRef}
      key={id}
      id={id}
      className={classNames(
        isOver && 'over',
        dragging && 'dragging',
        children && 'dropped',
      )}
      aria-label="Droppable region"
    >{children}
    </DropChildStyled>
  );
};

const DropChildStyled = styled.div`
  flex: 1;
  &.dragging {
    > svg {
      opacity: 0.8;
    }
  }
  &.over {
    box-shadow: inset #1eb99d 0 0 0 3px, rgba(201, 211, 219, 0.5) 20px 14px 24px;
    &.dropped {
      box-shadow: inset rgba(201, 211, 219, 0.7) 0 0 0 3px,
        rgba(201, 211, 219, 0.5) 20px 14px 24px;
    }
  }
  &.dropped {
    > svg {
      opacity: 0.2;
      transform: translate3d(-50%, 100%, 0) scale(0.8);
    }
  }
`;
type DroppableStyledProps = { active?: boolean };
const DroppableStyled = styled.div<DroppableStyledProps>`
  position: absolute;
  z-index: 1;
  inset: 0;
  .children {
    height:100%;
    width:100%;
    position: relative;
    z-index:99;
    
  }
  &:hover {
    box-shadow: inset rgba(201, 211, 219, 0.9) 0 0 0 2px;
  }

  &.disabled {
    &:hover {
      box-shadow: inset transparent 0 0 0 3px, transparent 20px 14px 24px;
    }
  }

  ${({ active }) => active ? `
  box-shadow: inset #1eb99d 0 0 0 3px, rgba(201, 211, 219, 0.5) 20px 14px 24px;
  `: ''}

  &.dragging {
    > svg {
      opacity: 0.8;
    }
  }
  &.over {
    box-shadow: inset #1eb99d 0 0 0 3px, rgba(201, 211, 219, 0.5) 20px 14px 24px;
    &.dropped {
      box-shadow: inset rgba(201, 211, 219, 0.7) 0 0 0 3px,
        rgba(201, 211, 219, 0.5) 20px 14px 24px;
    }
  }
  &.dropped {
    > svg {
      opacity: 0.2;
      transform: translate3d(-50%, 100%, 0) scale(0.8);
    }
  }
  .absolute {
    position: absolute;
    inset:0;
    display: flex;
    flex-direction: column;
  }
`;