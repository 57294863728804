import { Layout } from 'antd';
import { Content } from 'antd/es/layout/layout';
import { Outlet, useParams } from 'react-router-dom';
import styled from 'styled-components';
import TurnLayoutSidebar from './Sidebar';
import TurnNav from './TurnNav';
import { useAppDispatch } from 'store/hooks';
import { useEffect } from 'react';
import { turnUIActions } from './services/reducers';
import TurnStaffDetail from './TurnModals/TurnStaffDetail';
import TurnStaffAvailable from './TurnModals/TurnStaffAvailable';
import TurnStaffLogout from './TurnModals/TurnStaffLogout';
import TurnDashboard from './TurnModals/TurnDashboard';

const TurnLayout = () => {
  const { shop_id } = useParams();
  const dispatch = useAppDispatch();
  useEffect(() => { dispatch(turnUIActions.initData(shop_id || '')); }, []);
  return (
    <TurnLayoutStyled>
      <TurnNav />
      <Layout>
        <TurnLayoutSidebar />
        <Layout>
          <Content style={{ position: 'relative' }}>
            <Outlet />
          </Content>
        </Layout>
      </Layout>
      <TurnModals />
    </TurnLayoutStyled>
  );
};

export default TurnLayout;

const TurnModals = () => {
  return <>
    <TurnStaffDetail />
    <TurnStaffAvailable />
    <TurnStaffLogout />
    <TurnDashboard />
  </>;
};

const TurnLayoutStyled = styled(Layout)`
  position: fixed;
  inset: 0;
`;