import React, { CSSProperties } from 'react';
type Props = {
  value?: string;
  hidden?: boolean;
}
export const Word = ({ value = '', hidden = false }: Props) => {
  const getStyle = () => {
    return {
      visibility: hidden ? 'hidden' : 'visible'
    } as CSSProperties;
  };
  return (
    <div className='digital symbol'>
      <p>{value}</p>
      <p style={getStyle()}>{value}</p>
    </div>
  );
};