import { storeFetch as fetch } from 'services/request';

const getListStaff = () => {
  return fetch({
    url: '/api/v1/staff/list',
  });
};

const getAllTurnsData = () => {
  return fetch({
    url: '/api/v1/staff/turns',
  });
};

const checkPinCodeAdmin = (pinCode: string) => {
  return fetch({
    method: 'post',
    url: '/api/v1/staff/checkPinCode',
    body: { payload: { pinCode } },
    autoToast: false,
  });
};

const checkPinStaff = (staffId: number, pinCode: string) => {
  return fetch({
    method: 'post',
    url: '/api/v1/staff/checkPinStaff',
    body: { payload: { pinCode, staffId } },
    autoToast: false,
  });
};

const checkIn = (staffId: number, pin: string) => {
  return fetch({
    method: 'post',
    url: '/api/v1/clock-time/check-in',
    body: { payload: { pin, staffId } },
    autoToast: false,
  });
};

const checkOut = (staffId: number, pin: string) => {
  return fetch({
    method: 'post',
    url: '/api/v1/clock-time/check-out',
    body: { payload: { pin, staffId } },
    autoToast: false,
  });
};

const checkInAll = () => {
  return fetch({
    method: 'post',
    url: '/api/v1/clock-time/check-in-all',
    body: { payload: {} },
  });
};

const checkOutAll = () => {
  return fetch({
    method: 'post',
    url: '/api/v1/clock-time/check-out-all',
    body: { payload: {} },
  });
};

const getOpenTicketsByStaffId = (staffId: string) => {
  return fetch({
    method: 'get',
    url: `/api/v1/staff/open-tickets/${staffId}`,
  });
};

const finishAllTurnTickets = () => {
  return fetch({
    method: 'put',
    url: '/api/v1/staff/finish-turn-all',
    body: { payload: {} }
  });
};

const finishAllTurnTicketsByStaffId = (staffId: number) => {
  return fetch({
    method: 'put',
    url: '/api/v1/staff/finish-turn-all',
    body: { payload: { staffId } }
  });
};

const finishTurnTicketByStaffId = (staffId: number, billId: number, turn:number) => {
  return fetch({
    method: 'put',
    url: '/api/v1/staff/finish-turn',
    body: { payload: { staffId, billId, turn } }
  });
};

const adjustTurn = (staffId: number, turn: number) => {
  return fetch({
    method: 'put',
    url: '/api/v1/staff/adjust-turn',
    body: { payload: { staffId, turn } }
  });
};

const removeLateTurn = (staffId: number) => {
  return fetch({
    method: 'put',
    url: `/api/v1/staff/remove-late-turn/${staffId}`,
    body: {}
  });
};

const clearTurn = () => {
  return fetch({
    method: 'put',
    url: '/api/v1/staff/clear-turns',
  });
};

const turnApis = {
  getListStaff,
  getAllTurnsData,
  checkPinCodeAdmin,
  checkPinStaff,
  checkIn,
  checkOut,
  checkInAll,
  checkOutAll,
  getOpenTicketsByStaffId,
  finishAllTurnTickets,
  finishAllTurnTicketsByStaffId,
  finishTurnTicketByStaffId,
  adjustTurn,
  removeLateTurn,
  clearTurn,
};

export default turnApis;
