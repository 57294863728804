import React, { useEffect } from 'react';
import Modal from './Modal';
import { Button, Flex, Form, Input } from 'antd';
import styled from 'styled-components';
type Props = {
  visible: boolean;
  onClose: () => void;
  onConfirm: (reason: string) => void;
};
const options = ['Busy', 'Forgot', 'Sick', 'Others'];
const ModalReason = ({ visible, onConfirm, onClose }: Props) => {
  const [form] = Form.useForm();
  useEffect(() => {
    if (!visible) return;
    form.resetFields();
  }, [visible]);

  const onSelectOption = (value: string) => form.setFieldValue('reason', value);

  const handleFinish = (values: any) => {
    onConfirm(values.reason);
  };

  return (
    <Modal footerStyle={{ margin: 0 }} okText="OK" title="Reason" width='auto' height='auto' open={visible} onCancel={onClose} onOk={() => form.submit()}>
      <FormStyled form={form} onFinish={handleFinish}>
        <Form.Item noStyle name={'reason'} >
          <Input.TextArea placeholder='Please enter reason' rows={5} style={{ height: 150, width: 500, fontSize: 20 }} />
        </Form.Item>
        <Form.Item name={'reason'} rules={[{ required: true, message: 'Please enter reason' }]}>
          <Flex style={{ marginTop: 8 }} gap="16px" wrap="wrap" className='tag-suggestions'>
            {options.map(o => (
              <Button onClick={() => onSelectOption(o)} key={o} type='text' size='small' className='tag-item'>
                <span color="#C5DDF8">{o}</span>
              </Button>
            ))}
          </Flex>

        </Form.Item>
      </FormStyled>
    </Modal>
  );
};

export default ModalReason;

const FormStyled = styled(Form)`
  padding: 16px 24px;
  padding-bottom: 0px;
  .tag-suggestions {
  gap: 16px;
  .tag-item {
    border-radius: 100px;
    background: #C5DDF8;
    display: flex;
    padding: 4px 12px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    align-self: stretch;
    height: 32px;

    span {
      color: #1D2129;
      text-align: center;
      font-family: Poppins;
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: 22px;
    }
  }
}
`;