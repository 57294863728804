import React, { useMemo } from 'react';
import TurnHeadItem from './TurnHeadItem';
import styled from 'styled-components';
import TurnBodyItem from './TurnBodyItem';
import turnSelectors from 'Turn/services/selectors';
import TurnStaffTopRow from './TurnStaffTopRow';
import { flatten, get, sortBy } from 'lodash';
const turnCount = 15;

const TurnDashboardTable = () => {
  const _staffs = turnSelectors.staffs();
  const staffs = useMemo(() => {
    return sortBy(_staffs,[
      o=>!o.isBusy,
      o=>!o.isLockedin,
      o=>o.turn,
    ]);
  }, [_staffs]);
  const staffTurns = turnSelectors.turns();
  return (
    <Container>
      <table>
        <thead>
          <tr>
            <th className='staff-top-row-sticky' />
            {Array.from(Array(turnCount).keys()).map(o => (<TurnHeadItem key={o} number={o + 1} />))}
          </tr>
        </thead>
        <tbody>
          {staffs.map(o => {
            const tickets = staffTurns.filter(s => s.staffId === o.id);
            const mappingTurns = flatten(tickets.map(t => {
              const x = t.turn <= 0 ? 0 : t.turn;
              const totalFullTurn = x - x % 1;
              const halfTurn = x - totalFullTurn;
              const arrFull = Array.from(Array(totalFullTurn).keys()).map(() => ({
                ticketNumber: t.ticketNumber,
                type: 'full',
                amount: t.amount,
              }));

              if (halfTurn !== 0) {
                arrFull.push({
                  ticketNumber: t.ticketNumber,
                  type: 'half',
                  amount: t.amount,
                });
              }
              return arrFull;
            }));

            return (
              <React.Fragment key={o.id}>
                <tr>
                  <td className='staff-top-row-sticky'><TurnStaffTopRow data={o} /></td>
                  {Array.from(Array(turnCount).keys()).map((t, i) => {
                    const existTurn = get(mappingTurns, [i]);
                    return (<TurnBodyItem
                      full={existTurn?.type === 'full'}
                      half={existTurn?.type === 'half'}
                      key={`${o.id}_${t}`}
                      ticketNumber={existTurn?.ticketNumber}
                      amount={existTurn?.amount}
                    />);
                  })}
                </tr>
                <tr>
                  <td colSpan={turnCount + 1} style={{ padding: '8px 0' }}><div style={{ borderBottom: '1px solid rgb(123 123 123 / 32%)' }}></div></td>
                </tr>
              </React.Fragment>
            );
          })}
        </tbody>
      </table>
    </Container>
  );
};

export default TurnDashboardTable;

const Container = styled.div`
  table {
    border-collapse: collapse;
    thead {
      th {
        background: #F6F6F6;
        position: sticky;
        top: 0px;
        padding-top: 16px;
        z-index: 3;
        &.staff-top-row-sticky {
        z-index: 4;
        top: 0px;
        left: 0px;
        }
      }
    }

    tbody {
      td.staff-top-row-sticky {
        background: #F6F6F6;
        position: sticky;
        left: 0px;
        z-index: 2;
      }
    }
  }
`;