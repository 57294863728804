import { computed } from '@preact/signals-react';
import TabLabel from 'AppointmentBox/TabLabel';
import { ADD_APPOINTMENT_TABS } from 'AppointmentBox/helper';
import aptSignal from 'AppointmentBox/signal';
import { InputRef, Row } from 'antd';
import styled from 'styled-components';
import { ICustomerUI } from 'types/customer';
import Customer from './Customer';
import InputSearch from './InputSearch';
import shopSelectors from 'services/selectors/shop';
import { useEffect, useRef, useState } from 'react';
import InputSearchByName from './InputSearchByName';
const Label = () => {
  const activeKey = computed<string>(() => aptSignal.tabSignal.value).value;
  const customer = computed(() => aptSignal.customerSignal.value).value;
  return (
    <TabLabel
      label='Customer'
      tabIndex={1}
      completed={!!customer?.id}
      active={activeKey === ADD_APPOINTMENT_TABS.CUSTOMER}
    />
  );
};
enum ESearchBy {
  PHONE = 'PHONE',
  NAME = 'NAME'
}
const Children = () => {
  const customer = computed(() => aptSignal.customerSignal.value).value;
  const customers = shopSelectors.customers();
  const inputRef = useRef<InputRef>(null);
  const handleChooseCustomer = (o: ICustomerUI) => () => {
    aptSignal.customerSignal.value = o;
    aptSignal.next();
  };
  useEffect(() => {
    if (!aptSignal.openSignal.value) return;
    setTimeout(() => inputRef.current?.focus(), 100);
  }, [aptSignal.openSignal.value]);

  return (
    <CustomerTabStyled>
      <InputSearchBox inputPhoneRef={inputRef} />
      <Customers wrap>
        {customers.map(o => (
          <CustomerWrap key={o.id}>
            <div className='box'>
              <Customer
                active={o.id === customer?.id}
                data={o}
                onClick={handleChooseCustomer(o)}
              />
            </div>
          </CustomerWrap>
        ))}
      </Customers>
    </CustomerTabStyled>
  );
};

const InputSearchBox = ({ inputPhoneRef }: { inputPhoneRef: React.RefObject<InputRef> }) => {
  const [searchBy, setSearchBy] = useState<ESearchBy>(ESearchBy.PHONE);
  const inputRef = useRef<InputRef>(null);
  const handleChooseCustomer = (o: ICustomerUI) => () => {
    aptSignal.customerSignal.value = o;
    aptSignal.next();
  };
  const onChangeSearchBy = (val: ESearchBy) => {
    setSearchBy(val);
    setTimeout(() => {
      if (val === ESearchBy.NAME)
        inputRef.current?.focus();
      else
        inputPhoneRef.current?.focus();
    }, 100);
  };

  return (
    <SearchContainer>
      <div className="tags">
        <span>Search By: </span>
        <button onClick={() => onChangeSearchBy(ESearchBy.PHONE)} className={`tag-item ${searchBy === ESearchBy.PHONE ? 'active' : ''}`}>Phone</button>
        <button onClick={() => onChangeSearchBy(ESearchBy.NAME)} className={`tag-item ${searchBy === ESearchBy.NAME ? 'active' : ''}`}>Name</button>
      </div>
      {searchBy === ESearchBy.PHONE && <InputSearch inputRef={inputPhoneRef} onSelect={(o) => handleChooseCustomer(o)()} />}
      {searchBy === ESearchBy.NAME && <InputSearchByName inputRef={inputRef} onSelect={(o) => handleChooseCustomer(o)()} />}
    </SearchContainer>
  );
};

const CustomerTab = {
  Label,
  Children,
};

export default CustomerTab;
const CustomerTabStyled = styled.div`
  height: 100%;
`;

const Customers = styled(Row)`
  display: flex;
  margin: -6px;
  margin-top: 12px;
`;

const CustomerWrap = styled.div`
  width: 20%;
  .box {
    margin: 6px;
  }
`;

const SearchContainer = styled.div`
  .tags {
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    color: #1D2129;
    margin-bottom: 8px;
    gap: 8px;
    display: flex;
    align-items: center;
    button {
      font-family: Poppins;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      color: #7B7B7B;
      padding: 4px 16px;
      border-radius: 2px;
      border: 1px solid #7B7B7B;
      background: #EEE;
      border-radius: 8px;
      &.active {
        background: #232F3E;
        border-color: #232F3E;
        color: #fff;
      }
    }
  }
`;