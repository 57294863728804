import { ColorSets } from 'constants/calendar';
import { get } from 'lodash';
import { momentTimezone } from 'utils/time';
import { PIXEL_PER_MINUTE } from './styles';
import { IColorSetItem } from './types';

const calcTo = (currentTime: string) => {
  const open = momentTimezone(currentTime).set({ hour: 8, minute: 0, second: 0 });
  const close = momentTimezone(currentTime).set({ hour: 23, minute: 0, second: 0 });
  const now = momentTimezone(currentTime);
  const currentSpaceMinutes = now.diff(open, 'minutes');

  let space = 0;
  
  const RowHeight = (PIXEL_PER_MINUTE.value * 15) + 2;

  if (now.isBetween(open, close))
    space = (RowHeight / 15) * currentSpaceMinutes;
  return space;
};

export default calcTo;

// @ts-ignore
export const getColorSet = (type?: number | null) => (get(ColorSets, [type], null) as IColorSetItem) || ColorSets.default;
