import { calendarScrollTo } from 'Calendar';
import calcTo from 'Calendar/helper';
import { switchToZoom, zoomLevel } from 'Calendar/styles';
import { Row } from 'antd';
import moment from 'moment';
import aptActions from 'services/actions/apt';
import { useAppDispatch } from 'store/hooks';
import styled from 'styled-components';
import iconLookup from './iconLookup.svg';
import ButtonReorderStaff from './ButtonReorderStaff';

const scrollToNow = () => {
  setTimeout(() => {
    const pixel = calcTo(moment().format('MM-DD-YYYY HH:mm:ss'));
    calendarScrollTo.value(pixel - 10);
  }, 100);
};

const SettingConfigs = () => {
  const dispatch = useAppDispatch();

  const handleZoomIn = () => {
    scrollToNow();

    switch (zoomLevel.value) {
      case 2: return switchToZoom(1);
      case 1: return switchToZoom(0);
      default:
        break;
    }
  };

  const handleZoomOut = () => {
    scrollToNow();

    switch (zoomLevel.value) {
      case 0: return switchToZoom(1);
      case 1: return switchToZoom(2);
      default:
        break;
    }
  };

  const handleRefresh = () => dispatch(aptActions.calendar.refresh());

  return (
    <SettingConfigsRow>
      <ButtonReorderStaff />
      <button onClick={handleZoomIn}><IconZoomIn /></button>
      <button onClick={handleZoomOut}><IconZoomOut /></button>
      <button onClick={handleRefresh}><IconLookup /></button>
    </SettingConfigsRow>
  );
};

export default SettingConfigs;

const SettingConfigsRow = styled(Row)`
  gap: 1rem;
  button { 
    display: flex;
    
  }
`;

const IconZoomIn = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48" fill="none">
    <rect x="0.5" y="0.5" width="47" height="47" rx="23.5" fill="white" />
    <rect x="0.5" y="0.5" width="47" height="47" rx="23.5" stroke="#7B7B7B" />
    <path fillRule="evenodd" clipRule="evenodd" d="M22.4001 13.6C17.5399 13.6 13.6 17.5399 13.6 22.4001C13.6 27.2602 17.5399 31.2001 22.4001 31.2001C27.2602 31.2001 31.2001 27.2602 31.2001 22.4001C31.2001 17.5399 27.2602 13.6 22.4001 13.6ZM12 22.4001C12 16.6563 16.6563 12 22.4001 12C28.1439 12 32.8001 16.6563 32.8001 22.4001C32.8001 28.1439 28.1439 32.8001 22.4001 32.8001C16.6563 32.8001 12 28.1439 12 22.4001Z" fill="#1D2129" />
    <path fillRule="evenodd" clipRule="evenodd" d="M29.0344 29.0351C29.3468 28.7227 29.8534 28.7227 30.1659 29.0351L35.7665 34.6358C36.079 34.9482 36.079 35.4548 35.7665 35.7673C35.4541 36.0797 34.9475 36.0797 34.635 35.7673L29.0344 30.1666C28.7219 29.8542 28.7219 29.3476 29.0344 29.0351Z" fill="#1D2129" />
    <path fillRule="evenodd" clipRule="evenodd" d="M22.4004 18.3984C22.8422 18.3984 23.2004 18.7567 23.2004 19.1985V21.5988H25.6007C26.0426 21.5988 26.4008 21.957 26.4008 22.3989C26.4008 22.8408 26.0426 23.199 25.6007 23.199H23.2004V25.5993C23.2004 26.0411 22.8422 26.3993 22.4004 26.3993C21.9585 26.3993 21.6003 26.0411 21.6003 25.5993V23.199H19.2C18.7581 23.199 18.3999 22.8408 18.3999 22.3989C18.3999 21.957 18.7581 21.5988 19.2 21.5988H21.6003V19.1985C21.6003 18.7567 21.9585 18.3984 22.4004 18.3984Z" fill="#1D2129" />
  </svg>
);

const IconZoomOut = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48" fill="none">
    <rect x="0.5" y="0.5" width="47" height="47" rx="23.5" fill="white" />
    <rect x="0.5" y="0.5" width="47" height="47" rx="23.5" stroke="#7B7B7B" />
    <path fillRule="evenodd" clipRule="evenodd" d="M22.3997 13.6002C17.5391 13.6002 13.5987 17.5405 13.5987 22.4012C13.5987 27.2618 17.5391 31.2022 22.3997 31.2022C27.2604 31.2022 31.2007 27.2618 31.2007 22.4012C31.2007 17.5405 27.2604 13.6002 22.3997 13.6002ZM11.9985 22.4012C11.9985 16.6568 16.6553 12 22.3997 12C28.1441 12 32.8009 16.6568 32.8009 22.4012C32.8009 28.1456 28.1441 32.8024 22.3997 32.8024C16.6553 32.8024 11.9985 28.1456 11.9985 22.4012Z" fill="#1D2129" />
    <path fillRule="evenodd" clipRule="evenodd" d="M29.0346 29.0351C29.3471 28.7227 29.8537 28.7227 30.1661 29.0351L35.7668 34.6358C36.0792 34.9482 36.0792 35.4548 35.7668 35.7673C35.4543 36.0797 34.9477 36.0797 34.6353 35.7673L29.0346 30.1666C28.7222 29.8542 28.7222 29.3476 29.0346 29.0351Z" fill="#1D2129" />
    <path fillRule="evenodd" clipRule="evenodd" d="M18.3994 22.4017C18.3994 21.9598 18.7576 21.6016 19.1995 21.6016H25.6002C26.0421 21.6016 26.4003 21.9598 26.4003 22.4017C26.4003 22.8435 26.0421 23.2017 25.6002 23.2017H19.1995C18.7576 23.2017 18.3994 22.8435 18.3994 22.4017Z" fill="#1D2129" />
  </svg>
);

const IconLookup = () => (
  <img src={iconLookup} alt="" style={{ height: '48px', width: '48px' }} />
);
