import aptSignal from 'AppointmentBox/signal';
import { Input } from 'antd';
import Text from 'components/Text';
import React from 'react';
import styled from 'styled-components';

const InputNote = () => {
  return (
    <InputNoteStyled>
      <Text className='label'>Appointment Notes</Text>
      <Input.TextArea
        placeholder='Please enter...'
        value={aptSignal.noteSignal.value || ''}
        onChange={e => {
          aptSignal.noteSignal.value = e.target.value;
        }}
        rows={2}
      />
    </InputNoteStyled>
  );
};

export default InputNote;

const InputNoteStyled = styled.div`
  padding-bottom: 16px;
  border-bottom: 1px solid #CECECE;
  margin-bottom: 8px;
  .label {
    color: #1D2129;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 133.333% */
    margin-bottom: 8px;
  }

  textarea {
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 133.333% */
    padding: 12px;
    border-radius: 2px;
    background: #FFF;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.10) inset;

    &:focus, &:active ,&:hover {
      box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.10) inset;
    }
  }
`;
