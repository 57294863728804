import { ICalendarHeader, ICalendarItemData } from 'Calendar/types';
import { Row } from 'antd';
import Icon from 'assets/Icons';
import Text from 'components/Text';
import React, { useMemo } from 'react';
import aptActions from 'services/actions/apt';
import { useAppDispatch } from 'store/hooks';
import styled from 'styled-components';
import { FORMAT_TIME } from 'utils/format';
import { momentTimezone } from 'utils/time';
import { zoomLevel } from 'Calendar/styles';
import { IBlockHourItemData } from 'services/types/blockHours';
import bhSignal from 'BlockHourBox/signal';
import blockHourBg from './appt-block-hour.svg';

type IAppointmentProps = {
  data: ICalendarItemData;
  headerData?: ICalendarHeader;
  isMonthView?: boolean;
  dateOfMonth?: string;
  disabled?: boolean;
};
const BlockHourItem: React.FC<IAppointmentProps> = ({ data }) => {
  const dispatch = useAppDispatch();
  const time = useMemo(() => {
    const startTime = momentTimezone(data?.startTime);
    const endTime = momentTimezone(data?.endTime);
    return `${startTime.format(FORMAT_TIME)} - ${endTime.format(FORMAT_TIME)}`;
  }, [data]);
  const extraData = useMemo(() => {
    return data.data as IBlockHourItemData;
  }, [data]);

  const addMoreTimeAppointment: React.MouseEventHandler<HTMLButtonElement> = (e) => {
    e.stopPropagation();
    dispatch(aptActions.prolongedTimeBlockHour({ id: extraData.id, status: 'ADD' }));
  };
  const minusTimeAppointment: React.MouseEventHandler<HTMLButtonElement> = (e) => {
    e.stopPropagation();
    dispatch(aptActions.prolongedTimeBlockHour({ id: extraData.id, status: 'MINUS' }));
  };

  const handleOpenDetail = () => bhSignal.openDetail(extraData);

  const isSmall = useMemo(() => {
    const duration = momentTimezone(data.endTime).diff(momentTimezone(data.startTime), 'minute');
    const isSMZoom = zoomLevel.value !== 0;
    return isSMZoom && duration <= 15;
  }, [extraData, zoomLevel.value]);

  const Content = () => {
    const isSmallest = zoomLevel.value === 2;

    return (
      <>
        <div className={'appointment-box'}>
          <RowTime align={'middle'}>
            <div className='appointment-block-icon'><TimeLock /></div>
            <Text className='time block-hour-time' variant='CAPTION_3' color='text_3' style={{ fontWeight: '600'}}>{time}</Text>
          </RowTime>
          <div className={`title-group ${isSmall ? 'small' : ''}`}>
            <Text className='title block-hour-title'>Block Hours</Text>
            {extraData.note && <Text className='note block-hour-note' variant='CAPTION_3' color='text_3' textAlign='left' >
              <b>Note:</b> {extraData.note}</Text>}

          </div>
          <div className="flex-grow" />
        </div>
        <Row className="bottom" align={'middle'} justify={'center'} style={{ gap: isSmallest ? '0.5rem' : '1rem' }}>
          <Row align={'middle'} justify={'center'} ><button onClick={minusTimeAppointment}><Icon type='minusAppointment' /></button></Row>
          <Row align={'middle'} justify={'center'} ><button onClick={addMoreTimeAppointment}><Icon type='addAppointment' /></button></Row>
        </Row>
      </>
    );
  };

  return (
    <BlockHourItemStyled
      className='BlockHourItemStyled'
      onClick={((e) => [e.stopPropagation(), handleOpenDetail()]) as React.MouseEventHandler<HTMLDivElement>}
    >
      <Content />
    </BlockHourItemStyled>
  );
};

export default BlockHourItem;

const BlockHourItemStyled = styled.div`
  height: calc(100% - 12px);
  width: 100%;
  display: flex;
  position: relative;
  align-self: stretch;
  
  .appointment-box {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 0px 4px;
    padding-bottom: 1.25rem;
    flex: 1;

    .flex-grow {
      flex:1;
    }
    border-radius: 5px;
    background-image: url(${blockHourBg});
    border: 1px solid #ababab;
  }
 
  .bottom {
    position: absolute;
    bottom:0;
    width:100%;
    transform: translateY(16px);
    button {
      border-radius: 100%;
    }
  }
  .title-group {
    display: flex;
    flex-direction: column;
    .title {
      color: #1D2129;
      font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
    .note {
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
      overflow: hidden;
      color: #1D2129;
      text-align: left;
      text-overflow: ellipsis;
      font-family: Poppins;
      font-size: 12px;
      font-style: italic;
      font-weight: 600;
      line-height: normal;
    }

    &.small {
      flex-direction: row;
      .note {
        flex:1;
        text-align: right;
      }
    }
  }
  &:hover {
    cursor: pointer;
  }
`;

const RowTime = styled(Row)`
  gap: 4px;
  .time {
    color: #1D2129;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
`;

const TimeLock = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
    <path d="M10 18.75C8.26942 18.75 6.57769 18.2368 5.13876 17.2754C3.69983 16.3139 2.57832 14.9473 1.91606 13.3485C1.25379 11.7496 1.08051 9.9903 1.41813 8.29296C1.75575 6.59563 2.58911 5.03653 3.81282 3.81282C5.03653 2.58911 6.59563 1.75575 8.29296 1.41813C9.9903 1.08051 11.7496 1.25379 13.3485 1.91606C14.9473 2.57832 16.3139 3.69983 17.2754 5.13876C18.2368 6.57769 18.75 8.26942 18.75 10C18.75 12.3206 17.8281 14.5462 16.1872 16.1872C14.5462 17.8281 12.3206 18.75 10 18.75ZM10 2.5C8.51664 2.5 7.0666 2.93987 5.83323 3.76398C4.59986 4.58809 3.63856 5.75943 3.07091 7.12988C2.50325 8.50032 2.35473 10.0083 2.64411 11.4632C2.9335 12.918 3.64781 14.2544 4.6967 15.3033C5.7456 16.3522 7.08197 17.0665 8.53683 17.3559C9.99168 17.6453 11.4997 17.4968 12.8701 16.9291C14.2406 16.3614 15.4119 15.4002 16.236 14.1668C17.0601 12.9334 17.5 11.4834 17.5 10C17.5 8.01088 16.7098 6.10323 15.3033 4.6967C13.8968 3.29018 11.9891 2.5 10 2.5Z" fill="#1D2129" />
    <path d="M12.8688 13.75L9.375 10.2563V4.375H10.625V9.7375L13.75 12.8688L12.8688 13.75Z" fill="#1D2129" />
  </svg>
);
