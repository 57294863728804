import { ANYONE_ID } from 'Calendar/anyone';
import { IStaffUI } from 'types/staff';

export const ADD_APPOINTMENT_TABS = {
  CUSTOMER: 'CUSTOMER',
  STAFF: 'STAFF',
  TIME: 'TIME',
  SERVICE: 'SERVICE',
  REVIEW: 'REVIEW',
};

export const ANYONE_NAILS_ID = ANYONE_ID;

export const ANYONE_STAFF: IStaffUI = {
  id: ANYONE_NAILS_ID,
  avatar: '',
  extraData: null,
  name: 'Anyone Nails',
};
