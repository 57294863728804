import React from 'react';

const Logo = () => {
  return (
    <div style={{ height: 52 }}>
      <svg style={{ height: '100%', width: '100%' }} width="52" height="57" viewBox="0 0 52 57" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M12.6857 1.61042C14.6882 0.455358 17.0199 -0.111376 19.33 0.0181631C18.4934 0.266447 17.6082 0.336614 16.7932 0.655064C13.7382 1.66979 11.2608 3.95832 9.51201 6.61387C9.23134 7.01328 9.02084 7.46127 8.73477 7.85529C7.68227 8.4652 6.68373 9.17227 5.78775 9.99808C5.67981 9.99269 5.46391 9.9711 5.35596 9.9657C6.92122 6.5599 9.39326 3.47254 12.6857 1.61042Z" fill="white" />
        <path d="M6.05273 35.9062C6.31721 35.9062 6.57089 35.9872 6.77599 36.1545C7.48846 36.6727 8.20632 37.2178 9.08071 37.4229C10.7215 40.1001 13.0101 42.4858 15.9301 43.7326C16.6156 44.0564 17.355 44.213 18.0567 44.4882C14.9424 44.3263 12.0115 42.869 9.7608 40.7478C8.27649 39.3498 7.00269 37.7144 6.05273 35.9062Z" fill="white" />
        <path d="M33.1099 0.169434C36.2458 0.207216 39.182 1.76708 41.4436 3.85591C43.4352 5.72883 44.9843 8.04974 46.0908 10.538C46.053 10.6081 45.9774 10.7539 45.9396 10.8294C44.9789 9.96584 44.0289 9.06986 42.8685 8.48154C41.9293 6.78673 40.8606 5.1459 39.4843 3.77494C37.7355 2.03156 35.5333 0.698386 33.1099 0.169434Z" fill="white" />
        <path d="M30.6165 1.83168C32.4732 1.43227 34.4217 1.7939 36.1543 2.52256C38.3888 3.48331 40.2563 5.16732 41.6813 7.1212C40.7961 7.16438 40.035 6.60844 39.193 6.43033C37.8329 6.14966 36.4835 5.70167 35.0802 5.79343C34.1248 5.842 33.1209 5.6315 32.2141 6.02012C31.275 6.07949 30.2872 6.0525 29.4074 6.45192C27.5993 7.15898 26.2229 8.60011 25.0517 10.0898C23.848 10.6943 22.9682 11.7684 22.2018 12.8479C22.0885 13.0422 21.8672 13.107 21.6729 13.1772C22.4447 10.4352 23.8912 7.82827 25.9962 5.88518C27.7936 4.24975 30.2656 3.25122 32.7161 3.59126C33.5419 3.63444 34.3137 3.94209 35.0856 4.21197C33.2126 3.21883 30.9835 2.97055 28.927 3.47251C27.2484 3.90431 25.694 4.74632 24.3446 5.82581C25.8073 3.76398 28.1066 2.25808 30.6165 1.83168Z" fill="white" />
        <path d="M32.2141 6.02023C33.1209 5.63161 34.1248 5.84212 35.0802 5.79354C36.4835 5.70178 37.8383 6.14977 39.1931 6.43044C40.0405 6.60316 40.7961 7.1645 41.6813 7.12132C42.6151 8.39512 43.4625 9.77687 43.9698 11.2774C43.9374 11.3151 43.8619 11.3853 43.8295 11.4177C42.7176 10.6782 41.6705 9.81465 40.4075 9.33428C38.2377 8.35734 35.8088 7.97412 33.4394 8.24399L33.3854 8.24939C32.5434 7.69885 31.5071 7.40199 30.5031 7.50993C29.7205 7.61249 28.981 7.90935 28.2632 8.2224C29.3805 6.78128 31.2372 5.99864 33.0507 6.21454C32.7269 6.17136 32.403 6.12278 32.0846 6.06341L32.2141 6.02023Z" fill="white" />
        <path d="M46.825 30.1957C46.9707 29.9096 47.2946 29.9312 47.5644 29.9258C47.1596 31.6044 46.5443 33.2344 45.8049 34.7889C45.6645 34.7565 45.5188 34.6432 45.3947 34.7727C44.4555 35.3718 43.6621 36.23 42.5718 36.5701C43.1601 35.3664 43.7484 34.1628 44.1802 32.889C44.2342 32.9376 44.3422 33.0401 44.3961 33.0941C45.2759 32.1927 46.0963 31.2212 46.825 30.1957Z" fill="white" />
        <path d="M6.68945 14.4074C7.51527 11.9354 8.82685 9.60905 10.5864 7.67676C10.4731 8.006 10.3327 8.31906 10.1762 8.62671C9.51233 9.94909 9.03195 11.3524 8.60555 12.7612C7.8661 13.193 7.36414 13.8946 6.68945 14.4074Z" fill="white" />
        <path d="M5.78263 9.99826C6.67861 9.16705 7.67714 8.46538 8.72965 7.85547C7.82287 9.58806 7.03484 11.3854 6.51129 13.2799C6.25761 13.9924 6.18204 14.7534 5.93376 15.4659C5.37782 15.8329 5.1943 16.4968 4.91364 17.0528C4.18498 18.5748 3.90971 20.2589 3.74778 21.9213C3.74778 24.5121 4.49803 27.0974 5.90677 29.2726C7.14819 31.0214 8.72965 32.5705 10.6673 33.5312C12.3513 34.4812 14.2782 34.9831 16.2051 35.0047C17.2684 36.1598 18.715 37.1907 20.3666 37.072C21.2734 37.1151 22.1046 36.7211 22.9196 36.3865C21.7915 37.86 19.843 38.675 18.0025 38.2918C20.0158 39.0151 22.2557 38.2486 23.8426 36.9316C26.2822 34.9292 27.8205 32.0523 28.8622 29.1161C29.6502 26.9787 30.0173 24.7226 30.3843 22.4826C30.8269 26.9409 30.0497 31.5666 27.8313 35.4905L27.7719 35.4851C27.6748 35.4689 27.4751 35.4419 27.3725 35.4257C25.872 36.6672 24.161 37.6603 22.3151 38.281C21.0575 38.6696 19.7945 39.15 18.4559 39.1608C17.6193 39.2148 16.7827 39.3875 15.9407 39.2903C14.7964 39.1392 13.6252 39.1824 12.5187 38.8046C11.6659 38.6426 10.8832 38.1946 10.0088 38.1461C8.88078 36.7697 7.95781 35.2206 7.27233 33.5798C7.66635 34.9454 8.38421 36.1922 9.06429 37.4336C8.1953 37.2285 7.47204 36.678 6.75957 36.1652C6.55447 35.9979 6.30079 35.9169 6.03631 35.9169L5.98773 35.9115C3.33218 31.0592 2.32285 25.3972 2.76005 19.908C2.98134 16.4752 3.85033 13.0856 5.30765 9.97128L5.34004 9.97667C5.45878 9.97128 5.67468 9.99287 5.78263 9.99826Z" fill="white" />
        <path d="M33.4395 8.24384C35.8089 7.97396 38.2378 8.35718 40.4076 9.33413C41.6706 9.8145 42.7177 10.6781 43.8296 11.4175C43.862 11.3852 43.9375 11.3096 43.9699 11.2772C43.9915 11.2556 44.0293 11.2178 44.0509 11.2017C45.0602 12.3621 46.1397 13.474 46.9385 14.7964C47.1814 15.2174 47.4189 15.6438 47.6888 16.0486C47.7212 15.9784 47.7913 15.8381 47.8291 15.7679C48.3419 18.0456 48.547 20.3827 48.5308 22.7198C48.4768 25.1487 48.2393 27.5884 47.57 29.9309C47.2948 29.9363 46.9763 29.9093 46.8306 30.2007C46.0965 31.2263 45.2815 32.1978 44.4017 33.1046C44.3477 33.056 44.2398 32.9535 44.1858 32.8995C44.7148 31.4152 45.098 29.8823 45.3948 28.3332C45.5784 26.9299 45.886 25.5427 45.8806 24.1232C46.2746 20.9819 45.4974 17.6192 43.4032 15.1904C42.5396 14.2998 41.73 13.3175 40.6127 12.7291C39.2471 11.8601 37.6981 11.2988 36.1004 11.0829C35.3879 9.99262 34.4974 9.01028 33.4395 8.24384Z" fill="white" />
        <path d="M44.2018 29.3264C44.4392 29.0187 44.5958 28.5761 45.0222 28.4897C44.2665 31.6149 42.7498 34.5889 40.5854 36.9692C41.1252 35.7062 41.7675 34.4863 42.1723 33.1694C42.4692 32.4083 42.5987 31.5987 42.874 30.8322C43.3597 30.3735 43.8455 29.8985 44.2018 29.3264Z" fill="white" />
        <path d="M42.8687 8.48145C44.0345 9.06977 44.9845 9.96575 45.9398 10.8293C45.9776 10.7592 46.0532 10.6134 46.0909 10.5379C46.8682 12.2057 47.4025 13.9761 47.8235 15.7626C47.7911 15.8328 47.721 15.9731 47.6832 16.0433C47.4133 15.6385 47.1758 15.2121 46.9329 14.7911C46.1395 13.4687 45.06 12.3568 44.0507 11.2018C43.6621 10.2896 43.2789 9.37743 42.8687 8.48145Z" fill="white" />
        <path d="M12.5293 12.0977C13.1878 11.083 13.9542 10.1222 14.9258 9.38818C14.4616 10.2356 14.0136 11.0938 13.6628 11.9898C13.2849 12.0221 12.9071 12.0599 12.5293 12.0977Z" fill="white" />
        <path d="M22.1965 12.848C22.9629 11.7685 23.8427 10.6944 25.0463 10.0898C22.5851 13.6252 21.3545 17.8622 20.8363 22.0992C20.5017 19.1198 20.7607 16.0594 21.6351 13.188L21.6675 13.1718C21.8672 13.107 22.0831 13.0423 22.1965 12.848Z" fill="white" />
        <path d="M11.3097 10.8672C10.7429 12.6214 10.1492 14.3863 9.91712 16.2269C9.55549 18.2239 9.50692 20.2588 9.58788 22.2828C9.80378 22.8927 9.98189 23.5189 10.079 24.1666C10.5486 25.6347 11.477 26.9894 12.7778 27.8422C13.08 29.17 13.5388 30.4654 14.1164 31.7014C12.632 31.1185 11.1046 30.487 9.9711 29.3265C7.85529 27.3942 6.72182 24.458 6.93772 21.6082C7.02408 20.7715 7.03487 19.8756 7.52065 19.1523C7.51525 21.2519 7.43429 23.3677 7.8337 25.4404C7.48286 20.3937 8.48679 15.1312 11.3097 10.8672Z" fill="white" />
        <path d="M36.1001 11.083C37.6977 11.2989 39.2522 11.8656 40.6124 12.7292C41.7297 13.3176 42.5393 14.2999 43.4029 15.1905C45.4971 17.6139 46.2743 20.982 45.8803 24.1233C45.74 25.5968 45.4485 27.0541 45.0599 28.4844L45.0221 28.4952C44.5957 28.5816 44.4338 29.0242 44.2017 29.3319C43.8455 29.904 43.3597 30.3736 42.8793 30.8377C43.6512 27.0325 44.0182 23.0816 43.3705 19.2278C43.3597 19.4113 43.3489 19.5948 43.3435 19.7783C42.8038 18.8931 42.5015 17.873 41.8322 17.0688C40.7581 15.5791 39.2036 14.5104 37.5088 13.8411C37.1094 12.8858 36.6344 11.9682 36.1001 11.083Z" fill="white" />
        <path d="M12.5295 12.0977C12.9074 12.0545 13.2798 12.0221 13.663 11.9897C13.1071 13.3931 12.7292 14.8666 12.427 16.3401C11.9142 16.8853 11.3313 17.371 10.8887 17.9863C10.6134 18.375 10.3058 18.7366 9.98193 19.0874C10.3058 16.6208 11.2072 14.2135 12.5295 12.0977Z" fill="white" />
        <path d="M6.68964 14.408C7.36433 13.8952 7.87169 13.1935 8.60575 12.7563C8.02822 14.8506 7.77454 17.015 7.52085 19.1577C7.02968 19.881 7.01889 20.7716 6.93793 21.6136C6.72203 24.4635 7.8555 27.3997 9.97131 29.332C11.1048 30.4924 12.6323 31.1239 14.1166 31.7069C14.6563 32.8997 15.3742 34.0116 16.2162 35.0101C14.2839 34.9885 12.3624 34.4866 10.6784 33.5366C8.74608 32.5759 7.15922 31.0268 5.91781 29.278C4.50367 27.1082 3.75342 24.5174 3.75882 21.9266C3.92074 20.2642 4.19601 18.5802 4.92467 17.0581C5.21074 16.4968 5.39425 15.8329 5.94479 15.4713C5.77747 16.7397 5.5022 17.9865 5.39425 19.2603C5.30249 19.6759 5.37806 20.0969 5.45362 20.5125C5.41584 20.1455 5.35647 19.7731 5.42664 19.4114C5.76668 17.722 6.04735 16.0056 6.68964 14.408Z" fill="white" />
        <path d="M37.5039 13.8408C39.1987 14.5101 40.7532 15.5842 41.8273 17.0685C42.502 17.8727 42.7988 18.8929 43.3386 19.778C43.5167 20.5769 43.3871 21.4297 43.4033 22.2501C43.3817 23.8531 43.1173 25.4454 42.7556 27.0052C42.7125 26.7947 42.6747 26.5842 42.6315 26.3737C42.2807 27.0322 41.919 27.7015 41.3307 28.1819C41.4009 27.3561 41.5736 26.5465 41.606 25.7153C41.6761 24.5926 41.6222 23.4645 41.6869 22.3364C41.6923 22.2555 41.7085 22.0935 41.7193 22.018C41.5736 22.0774 41.4279 22.1421 41.2821 22.2015C41.3577 21.5862 41.3253 20.9547 41.0338 20.3987C40.5481 19.044 39.7385 17.7324 38.4808 16.9821C38.2488 15.9027 37.8871 14.8663 37.5039 13.8408Z" fill="white" />
        <path d="M10.8887 17.9866C11.3313 17.3766 11.9142 16.8855 12.427 16.3403C11.9574 18.9635 11.9412 21.646 12.1409 24.2962C12.3136 25.4836 12.4593 26.6873 12.7778 27.8423C11.477 26.9895 10.5486 25.6348 10.0791 24.1667C9.98191 23.519 9.80379 22.8929 9.58789 22.2829C9.67425 21.2142 9.70663 20.124 9.97651 19.0876C10.3058 18.7368 10.6134 18.3752 10.8887 17.9866Z" fill="white" />
        <path d="M38.4805 16.9824C39.7435 17.7327 40.5477 19.0497 41.0335 20.399C41.3249 20.955 41.3573 21.5865 41.2818 22.2018C41.4275 22.1424 41.5732 22.0776 41.719 22.0183C41.7136 22.0992 41.6974 22.2611 41.6866 22.3367C41.5786 23.1085 41.4923 23.8804 41.3843 24.6522L41.3519 24.6306C41.3033 24.6036 41.2008 24.5443 41.1468 24.5173C40.742 25.3755 40.375 26.3092 39.6355 26.9462C39.3171 27.1998 39.112 27.5507 38.9339 27.9123C38.9177 27.8421 38.8853 27.6964 38.8691 27.6262C39.4196 24.0963 39.3009 20.4584 38.4805 16.9824Z" fill="white" />
        <path d="M41.1417 24.5176C41.1903 24.5446 41.2928 24.6039 41.3468 24.6309C41.3468 25.5485 41.0823 26.4391 40.8718 27.3243C40.7531 27.7776 40.6613 28.2472 40.4454 28.6682C39.7491 29.1756 38.9665 29.5642 38.3242 30.1525C38.5401 29.3159 38.7452 28.4793 38.8586 27.6265C38.8748 27.6967 38.9071 27.8424 38.9233 27.9126C39.1015 27.551 39.3012 27.2001 39.625 26.9464C40.3699 26.3149 40.7369 25.3812 41.1417 24.5176Z" fill="white" />
        <path d="M41.3304 28.1768C41.9187 27.6964 42.2804 27.0271 42.6312 26.3687C42.6744 26.5792 42.7176 26.7897 42.7553 27.0002C42.1778 29.3049 41.352 31.5988 39.9648 33.5419C40.5154 31.7877 41.0821 30.0173 41.3304 28.1768Z" fill="white" />
        <path d="M38.3294 30.1523C38.9717 29.5639 39.7489 29.1753 40.4506 28.668C39.9864 30.2764 39.193 31.7769 38.2376 33.1479C37.6709 33.9089 37.0556 34.6592 36.2729 35.2097C37.1689 33.6228 37.8976 31.928 38.3294 30.1523Z" fill="white" />
        <path d="M45.3946 34.7726C45.5187 34.643 45.6645 34.7564 45.8048 34.7888C44.4932 37.4497 42.7175 39.9271 40.3588 41.7569C37.9839 43.6298 34.9505 44.828 31.8901 44.5582C34.3406 44.1965 36.7101 43.198 38.5776 41.5518C40.1968 40.17 41.5138 38.4536 42.5285 36.5861L42.5717 36.5699C43.662 36.2299 44.4554 35.3717 45.3946 34.7726Z" fill="white" />
        <path d="M27.3783 35.4253C27.4755 35.4415 27.6752 35.4685 27.7777 35.4847C26.5363 37.703 24.631 39.6407 22.2291 40.5691C20.3022 41.3247 18.1216 41.2492 16.2217 40.4395C17.9597 41.3787 20.027 41.5676 21.9539 41.206C23.7566 40.8498 25.3651 39.8944 26.844 38.8419C25.0088 41.2869 22.051 42.9871 18.9529 42.9116C15.4067 42.9548 12.1682 40.8444 10.0146 38.1402C10.889 38.1888 11.6717 38.6368 12.5245 38.7987C13.6256 39.1765 14.8022 39.1334 15.9465 39.2845C16.7885 39.3816 17.6251 39.2089 18.4617 39.1549C19.8003 39.1495 21.0633 38.6638 22.3209 38.2752C24.1668 37.6544 25.8832 36.6613 27.3783 35.4253Z" fill="white" />
        <path d="M0.892548 48.7246C1.70757 48.5842 2.58735 48.5302 3.38078 48.8001C4.07166 49.0916 4.08245 49.939 4.08785 50.5759C4.06626 52.1789 4.12023 53.7874 4.06086 55.3904C4.08245 56.0111 3.67225 56.648 3.02455 56.7182C2.24731 56.7938 1.40531 56.8801 0.665854 56.5833C-0.003432 56.2648 0.00736317 55.4228 0.0019657 54.7913C0.0235556 53.1883 -0.0304191 51.5852 0.028953 49.9876C0.0235556 49.437 0.331211 48.8649 0.892548 48.7246ZM1.39451 50.0091C1.34054 51.8011 1.35133 53.5931 1.38912 55.385C1.83171 55.4012 2.2743 55.412 2.72229 55.4174C2.78166 53.6039 2.79785 51.7849 2.7115 49.9714C2.2743 49.9768 1.83171 49.993 1.39451 50.0091Z" fill="white" />
        <path d="M5.16184 48.6329C5.58824 48.6275 6.00924 48.6221 6.43564 48.6167C7.01857 50.0254 7.38559 51.5205 8.06567 52.8861C8.15203 51.4666 8.08726 50.047 8.11965 48.6329C8.57304 48.6275 9.02103 48.6275 9.47981 48.6275C9.5014 51.337 9.496 54.0466 9.48521 56.7615C9.04801 56.7507 8.60542 56.7453 8.16823 56.7345C7.63927 55.2232 7.11572 53.7173 6.54359 52.2276C6.53279 53.7389 6.55978 55.2502 6.5274 56.7615C6.07401 56.7561 5.62062 56.7561 5.16723 56.7561C5.12945 54.0519 5.12945 51.3424 5.16184 48.6329Z" fill="white" />
        <path d="M10.2949 48.6332C11.6497 48.6224 13.0045 48.6278 14.3592 48.6332C14.3592 49.0758 14.3538 49.5238 14.3538 49.9718C13.9112 49.9772 13.4632 49.9772 13.0206 49.9826C13.026 52.2333 13.0045 54.4786 13.0314 56.7294C12.5673 56.751 12.1031 56.7672 11.6389 56.7834C11.6119 54.5272 11.6173 52.2765 11.6389 50.0149C11.1909 49.9933 10.7429 49.9718 10.2949 49.9556C10.2949 49.513 10.2949 49.0704 10.2949 48.6332Z" fill="white" />
        <path d="M15.1528 48.633C15.6062 48.6276 16.0596 48.6276 16.5184 48.633C16.5345 51.3425 16.5345 54.052 16.5184 56.7615C16.0596 56.7561 15.6008 56.7561 15.1528 56.7561C15.142 54.0466 15.1366 51.3425 15.1528 48.633Z" fill="white" />
        <path d="M17.549 48.6279C17.997 48.6279 18.4503 48.6279 18.9037 48.6333C18.9631 50.9003 18.8659 53.1726 18.9577 55.4449C19.7997 55.4233 20.6471 55.4233 21.4999 55.4395C21.4999 55.8767 21.5053 56.3139 21.5053 56.7565C20.1829 56.7835 18.8605 56.7727 17.5436 56.7727C17.5166 54.0524 17.5112 51.3375 17.549 48.6279Z" fill="white" />
        <path d="M23.3948 48.7302C24.4527 48.5737 26.331 48.2876 26.5577 49.7557C26.6657 51.5315 26.5901 53.3127 26.6009 55.0884C26.6225 55.7091 26.439 56.4756 25.7535 56.6699C24.9763 56.8318 24.1451 56.8318 23.3678 56.6645C22.6985 56.4756 22.5042 55.7253 22.5312 55.11C22.5204 53.4962 22.515 51.8823 22.5312 50.2685C22.5042 49.6478 22.7255 48.9029 23.3948 48.7302ZM23.913 50.0202C23.8644 51.8014 23.8752 53.5825 23.9022 55.3691C24.3448 55.4015 24.7928 55.4177 25.2407 55.4177C25.3001 53.6041 25.3217 51.7798 25.23 49.9662C24.7874 49.977 24.3502 49.9932 23.913 50.0202Z" fill="white" />
        <path d="M28.4736 48.7248C29.2886 48.5791 30.1792 48.5251 30.9726 48.795C31.7606 49.1458 31.6635 50.139 31.685 50.8514C31.6581 52.3627 31.7174 53.8794 31.6581 55.3907C31.6796 56.0168 31.2586 56.6537 30.6056 56.7185C29.8337 56.7887 28.9971 56.875 28.2631 56.5835C27.5884 56.2651 27.6046 55.4285 27.5938 54.7916C27.6154 53.1939 27.5614 51.5963 27.6207 50.004C27.61 49.4535 27.9068 48.8706 28.4736 48.7248ZM28.9809 50.0094C28.9269 51.8014 28.9323 53.588 28.9755 55.3799C29.4181 55.4015 29.8607 55.4123 30.3033 55.4231C30.3735 53.6041 30.3843 51.7852 30.2925 49.9662C29.8607 49.977 29.4181 49.9932 28.9809 50.0094Z" fill="white" />
        <path d="M35.7873 48.7845C36.6239 48.547 37.5414 48.5578 38.3834 48.7683C39.3658 49.2109 39.0851 50.4901 39.1445 51.3537C38.7019 51.3483 38.2593 51.3483 37.8221 51.3483C37.7843 50.8949 37.7465 50.4415 37.7034 49.9881C37.2986 49.9989 36.8991 50.0097 36.4997 50.0313C36.4296 51.8125 36.4242 53.599 36.4997 55.3802C36.8991 55.3964 37.2986 55.4072 37.7034 55.418C37.7465 54.9646 37.7897 54.5058 37.8275 54.0524C38.2647 54.047 38.7019 54.047 39.1445 54.047C39.1121 54.9538 39.3604 56.4057 38.1891 56.6972C37.196 56.7782 35.6469 57.1074 35.1666 55.9415C34.9183 54.3601 35.118 52.7408 35.0478 51.1432C35.0856 50.3336 34.8913 49.1893 35.7873 48.7845Z" fill="white" />
        <path d="M41.1036 48.7194C41.8755 48.5737 42.6905 48.5629 43.4569 48.7464C44.0884 48.9461 44.2773 49.664 44.2665 50.2577C44.2773 51.9633 44.2989 53.6743 44.2503 55.3799C44.2611 55.979 43.8995 56.6159 43.2626 56.7077C42.4854 56.8048 41.6596 56.8642 40.9039 56.6159C40.1914 56.3299 40.1914 55.4609 40.186 54.8186C40.2076 53.2047 40.1537 51.5909 40.213 49.977C40.2076 49.4157 40.5369 48.8382 41.1036 48.7194ZM41.5948 49.9716C41.5192 51.7852 41.5192 53.6095 41.6002 55.4285C42.0428 55.4177 42.4854 55.3961 42.928 55.3691C42.9603 53.5879 42.9603 51.8068 42.928 50.031C42.48 49.9986 42.0374 49.977 41.5948 49.9716Z" fill="white" />
        <path d="M45.2757 48.6437C45.8425 48.6329 46.4038 48.6275 46.9705 48.6221C47.4185 50.3169 47.6938 52.0549 48.185 53.7389C48.5628 52.0495 48.9514 50.3655 49.3022 48.6706C49.8852 48.6383 50.4735 48.6383 51.0564 48.6437C51.0726 51.3424 51.0672 54.0411 51.0618 56.7345C50.6138 56.7453 50.1658 56.7561 49.7178 56.7668C49.6639 55.153 49.7718 53.5284 49.6261 51.9199C49.313 53.5284 48.9514 55.1314 48.5952 56.7345C48.3793 56.7507 47.9583 56.7723 47.7478 56.783C47.3322 55.1962 47.0731 53.5716 46.6305 51.9901C46.5765 53.5823 46.6467 55.18 46.5819 56.7776C46.1447 56.7669 45.7075 56.7561 45.2703 56.7453C45.2595 54.0465 45.2487 51.3424 45.2757 48.6437Z" fill="white" />
        <path d="M32.7428 55.4396C33.1907 55.4342 33.6387 55.4396 34.0867 55.4504C34.0705 55.893 34.0597 56.341 34.0435 56.789C33.601 56.7674 33.1638 56.7458 32.7266 56.7188C32.7266 56.2924 32.732 55.866 32.7428 55.4396Z" fill="white" />
      </svg>
    </div>
  );
};

export default Logo;
