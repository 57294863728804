import { DatePicker as DatePickerAnt, Row } from 'antd';
import IconCalendar from 'assets/Icons/iconDate';
import Text from 'components/Text';
import type { Moment, Moment as MomentType } from 'moment';
import moment from 'moment';
import momentGenerateConfig from 'rc-picker/lib/generate/moment';
import { useMemo, useState } from 'react';
import aptActions from 'services/actions/apt';
import aptSelectors from 'services/selectors/apt';
import { useAppDispatch } from 'store/hooks';
import styled from 'styled-components';
const DATE_FORMAT = 'YYYY-MM-DD';
const time = (date: string) => moment(date, DATE_FORMAT);
const DatePickerAntUI = DatePickerAnt.generatePicker<MomentType>(momentGenerateConfig);
type ITypePicker = 'date' | 'week' | 'month';
const DatePicker = () => {
  const dispatch = useAppDispatch();
  const [typePicker, setTypePicker] = useState<ITypePicker>('date');
  const params = aptSelectors.table.params();


  const onChange = (date: Moment | null, _typePicker?: ITypePicker) => {
    if (!date) return;
    let start_time = date?.format(DATE_FORMAT);
    let end_time = date?.format(DATE_FORMAT);
    const type = _typePicker || typePicker;
    switch (type) {
      case 'week': {
        start_time = date?.startOf('week').format(DATE_FORMAT);
        end_time = date?.endOf('week').format(DATE_FORMAT);
        break;
      }
      case 'month': {
        start_time = date?.startOf('month').format(DATE_FORMAT);
        end_time = date?.endOf('month').format(DATE_FORMAT);
        break;
      }
      default:
        break;
    }
    dispatch(aptActions.table.setParams({ start_time, end_time }));
  };

  const startDate = useMemo(() => {
    return params.start_time ? time(params.start_time) : null;
  }, [params]);

  const endDate = useMemo(() => {
    return params?.end_time ? time(params?.end_time) : null;
  }, [params]);

  const onChangePickerType = (type: ITypePicker) => () => {
    onChange(moment(), type);
    setTypePicker(type);
  };

  const label = useMemo(() => {
    switch (typePicker) {
      case 'date':
        return <Text variant='CONTENT_2' color='text_3' >{startDate?.format('MMM DD, YYYY')}</Text>;
      case 'week':
        return <Text variant='CONTENT_2' color='text_3' >{startDate?.format('MMM DD')} - {endDate?.format('MMM DD, YYYY')}</Text>;
      case 'month':
        return <Text variant='CONTENT_2' color='text_3' >{startDate?.format('MMMM, YYYY')}</Text>;
      default:
        return null;
    }
  }, [typePicker, startDate, endDate]);

  return (
    <Row align={'middle'}>
      <ButtonRadioGroup>
        <ButtonRadio active={typePicker === 'date'} onClick={onChangePickerType('date')}>Day</ButtonRadio>
        <ButtonRadio active={typePicker === 'week'} onClick={onChangePickerType('week')}>Week</ButtonRadio>
        <ButtonRadio active={typePicker === 'month'} onClick={onChangePickerType('month')}>Month</ButtonRadio>
      </ButtonRadioGroup>
      <WrapDateTime align={'middle'}>
        <div className='icon-right'><IconCalendar /></div>
        <DatePickerStyled
          value={startDate}
          onChange={(date: any) => onChange(date)}
          suffixIcon={false}
          clearIcon={false}
          allowClear={false}
          picker={typePicker}
          inputReadOnly
          placement='bottomRight'
          inputRender={(props: any) => (
            <InputUI {...props} justify={'end'} align={'middle'} >
              {label}
            </InputUI>
          )}
        />
      </WrapDateTime>
    </Row >
  );
};

export default DatePicker;

const ButtonRadioGroup = styled(Row)`
  height: 56px;
  border-radius: 4px;
  border: 1px solid #CECECE;
  background: transparent;
`;

const ButtonRadio = styled.button<{ active?: boolean }>`
  display: flex;
  width: 90px;
  padding: 8px 12px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  align-self: stretch;
  
  color: var(--2, #7B7B7B);
  height: 100%;

  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 125% */

  &:hover {
    background: #232f3e73;
  }

  ${({ active }) => active && `
    background: #232F3E;
    color: #FFF;
    border-radius: 4px;
    &:hover {
      background: #232F3E;
    }
  `}
`;


const WrapDateTime = styled(Row)`
border-radius: 5px;
border: 1px solid #7B7B7B;
background: #FFF;
display: flex;
height: 56px;
width: 25vw;
justify-content: space-between;
align-items: center;
flex-shrink: 0;
position: relative;
margin-left: 1.5rem;

.icon-right {
  position: absolute;
  left: 16px;
  display: flex;
}
.ant-picker .ant-picker-input >input {
  font-size: 1rem;
}
`;

const DatePickerStyled = styled(DatePickerAntUI)`
  background: none;
  border: none;
  padding:0;
  flex:1;
  
  input {
    text-align: right;
    color: #1D2129;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 150% */
    padding: 0px 16px !important;
  }

  &.ant-picker:hover,
  &.ant-picker-focused {
    border: none;
    box-shadow: none;
  }

`;

const InputUI = styled(Row)`
flex: 1;
padding: 0.5rem;
margin-right: 0.5rem;
-webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
`;