import { computed, signal } from '@preact/signals-react';
import { CALENDAR_VIEW_TYPE } from 'constants/calendar';
import React, { CSSProperties } from 'react';
import { styled } from 'styled-components';
import { IColorSetItem } from './types';

export const calendarDOM = React.createRef<HTMLDivElement>();

const calendarWidthSignal = signal(15);
export const PIXEL_PER_MINUTE = signal(6);

export const zoomLevel = signal<0 | 1 | 2>(0);

export const isModeSM = computed(() => {
    return zoomLevel.value === 1;
});

export const isModeFull = computed(() => {
    return zoomLevel.value === 2;
});


export const switchToZoom = (level: 0 | 1 | 2) => {
    const calendar = calendarDOM.current || document.getElementById('calendar');
    switch (level) {
        case 0: {
            PIXEL_PER_MINUTE.value = 6;
            zoomLevel.value = 0;
            calendarWidthSignal.value = 15;
            if (calendar) calendar.className = calendar.className?.replace('zoom-level-0', '')?.replace('zoom-level-1', '')?.replace('zoom-level-2', '') + 'zoom-level-0';
            break;
        }

        case 1: {
            PIXEL_PER_MINUTE.value = 5;
            zoomLevel.value = 1;
            if (calendar) calendar.className = calendar.className?.replace('zoom-level-0', '')?.replace('zoom-level-1', '')?.replace('zoom-level-2', '') + 'zoom-level-1';
            calendarWidthSignal.value = 12;
            return;
        }
        case 2: {
            PIXEL_PER_MINUTE.value = 5;
            if (calendar) calendar.className = calendar.className?.replace('zoom-level-0', '')?.replace('zoom-level-1', '')?.replace('zoom-level-2', '') + 'zoom-level-2';
            zoomLevel.value = 2;
            break;
        }
        default:
            break;
    }
};
export const COL_TIME_WIDTH = `${calendarWidthSignal.value * 0.5}rem`;
export const fixedStyle: CSSProperties = { maxWidth: COL_TIME_WIDTH, minWidth: COL_TIME_WIDTH, width: COL_TIME_WIDTH, padding: 0 };
type TableStyledProps = {
    typeView: CALENDAR_VIEW_TYPE;
}
const Table = styled.table<TableStyledProps>`
    border-collapse: collapse;
    min-width: 100%;

    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
  
    td, th {
        height: ${() => (PIXEL_PER_MINUTE.value * 15)}px;
        ${({ typeView }) => {
        if (typeView === CALENDAR_VIEW_TYPE.WEEK_VIEW) return `
                min-width: auto;
                max-width: auto;
            `;

        if (typeView === CALENDAR_VIEW_TYPE.DAY_VIEW) return `
                min-width: ${isModeFull.value ? '150px' : calendarWidthSignal.value + 'rem'};
                max-width: ${isModeFull.value ? '150px' : calendarWidthSignal.value + 'rem'};
            `;
    }}
    }

    th {
        height: 56px;
    }
    background: #FFF;

`;

type HeadStyledProps = {}
const THead = styled.thead<HeadStyledProps>``;

type BodyStyledProps = {
    activeTimeRow?:string;
}
const TBody = styled.tbody<BodyStyledProps>`
    position: relative;
    transform: scale(1);
    ${({activeTimeRow})=> activeTimeRow?`

        td.${activeTimeRow} {
            box-shadow: inset 0px -3px 0px 0px #148CF1;
        }
        td.${activeTimeRow}_time .text-time{
            color: #148CF1;
            font-weight: 700 !important;
        }

    `:''}
`;

type RowStyledProps = {
}
const TRow = styled.tr<RowStyledProps>`
    &.first-row {
        .box-time {
            .text-time {
                transform: unset;
            }
        }
    }
`;

type THStyledProps = {
    fixed?: boolean;
    fixedHigh?: boolean;
    anyone?: boolean;
    colorSet?: IColorSetItem | null;
}
const TH = styled.th<THStyledProps>`
    border: 1px solid #CECECE;
    background-clip: padding-box;
    ${({ fixed }) => fixed ? `
        position: sticky;
        top: 0;
        z-index: 15;
        background: #fff;
        border:none;
        border-right: 1px solid #CECECE;
    `: ''}
    ${({ fixedHigh }) => fixedHigh ? `
        position: sticky;
        top: 0;
        left:0;
        z-index: 16;
        background: #fff;
        border:none;
        .actions {
            padding: 0 6px;
        }
    `: ''}
    ${({ anyone }) => anyone ? `
         position: sticky;
        top: 0;
        left: ${COL_TIME_WIDTH};
        z-index: 17;
        background: #fff;
        border:none;
        div.wrap {
            border-right: 0.5px solid #CECECE;
            display: flex;
            height: 100%;
            justify-content: center;
            align-items: center;
            margin-right: -1px;
        }
    `: ''}
`;

type TDStyledProps = {
    fixed?: boolean;
    time?: boolean;
    disabled?: boolean;
    anyone?: boolean;
}
const TD = styled.td<TDStyledProps>`
    position: relative;
    border: 1px solid #CECECE;
    border-top-width: 0;
    background: #F8F9FA;
    background-clip: padding-box;
    border-left: 0;
    ${({ fixed }) => fixed ? `
        position: sticky;
        left:0;
        z-index: 15;
        border:none;
        background: #FFF;
    `: ''}

    ${({ anyone }) => anyone ? `
        position: sticky;
        top: 0;
        left: ${COL_TIME_WIDTH};
        z-index: 13;
        div.wrap {
            border-right: 0.5px solid #CECECE;
        }
    `: ''}

    ${({ time }) => time ? `
        .box-time {
            height: 100%;
            border-right: 1px solid #CECECE;
            background: #F8F9FA;
            .text-time {
                transform: translateY(-16px);
                margin-right: 10px;
                color: #1D2129;
                text-align: right;
                font-family: Poppins;
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
            }
        }
    `: ''}
    &.first-col {
        border-left: none;
        border-right: none;
    }

    &.disable {
        background-color: #EAEDED;
    }

`;

const CalendarStyled = {
    Table,
    THead,
    TBody,
    TRow,
    TH,
    TD,
};

export default CalendarStyled;