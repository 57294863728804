import { Input, InputProps } from 'antd';
import { debounce } from 'lodash';
import React, { forwardRef, useCallback } from 'react';
import { toCurrency } from './currency-conversion';

// @ts-ignore
interface CurrencyInputProps extends InputProps {
  value?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onChangeDebounce?: (value: number) => void;
  bordered?: boolean;
  disabled?: boolean;
  size?: 'large' | 'medium' | 'small';
  className?: string;
  width?: React.CSSProperties['width'];
}

const CurrencyInput = forwardRef<any, CurrencyInputProps>(
  ({
    value = '0.00',
    onChange,
    onChangeDebounce,
    bordered = true,
    size = 'medium',
    className = '',
    width,
    disabled,
    ...props
  }, ref) => {
    const _debounce = useCallback(debounce((inputValue: number) => typeof onChangeDebounce === 'function' && onChangeDebounce(inputValue), 200), []);

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const rawValue = e.target.value?.replace(/[^0-9]/g, ''); // Strip non-numeric characters
      let numericValue = 0.0;

      if (rawValue) {
        const intValue = parseInt(rawValue);
        numericValue = intValue / 100;
      }
      // @ts-ignore
      e.target.value = numericValue;

      if (onChange) {
        onChange(e);
      }
      _debounce(numericValue);
    };

    const displayValue = `${toCurrency(parseFloat(value || '').toFixed(2))}`;

    // Determine the height based on the size prop
    let height = '3.5rem'; // default for 'medium'
    switch (size) {
      case 'large':
        height = '4rem';
        break;
      case 'small':
        height = '3rem';
        break;
    }

    return (
      <Input
        {...props}
        className={className}
        value={displayValue}
        onChange={handleInputChange}
        style={{ width: width || '100%', height: height, textAlign: 'right' }}
        bordered={bordered}
        disabled={disabled}
        onKeyPress={(event) => {
          if (!/[0-9]/.test(event.key)) {
            event.preventDefault();
          }
        }}

        ref={ref}
      />
    );
  }
);
CurrencyInput.displayName = 'CurrencyInput';
export default CurrencyInput;
