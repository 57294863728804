import { Avatar, Row } from 'antd';
import { get } from 'lodash';
import styled from 'styled-components';
import { getColorSet } from './helper';
import { isModeFull } from './styles';
type Props = {
  url?: string;
  name: string;
  typeColor: number;
};
const StaffHeader = ({ url, name, typeColor }: Props) => {
  const colorSet = getColorSet(typeColor);

  if (isModeFull.value) {
    return (
      <StaffBox className='StaffBox' align={'middle'} justify={'center'} style={{ flexDirection: 'column', gap: 0 }}>
        <Avatar className='staff-avatar' size={'large'} src={url} style={{ backgroundColor: colorSet?.background || '#f56a00' }}>{get(name, [0], '')}</Avatar>
        <div className='text-overflow text-ellipsis'>
          <p className='subtext' style={{ marginTop: '6px', color: colorSet?.text, fontSize: '1.6rem', fontWeight: '600' }}>{name}</p>
        </div>
      </StaffBox>
    );
  }
  return (
    <StaffBox className='StaffBox' align={'middle'} justify={'center'} style={{ gap: '10px' }} wrap={false}>
      <Avatar className='staff-avatar' size={'large'} src={url} style={{ backgroundColor: colorSet?.background || '#f56a00' }}>{get(name, [0], '')}</Avatar>
      <div className='text-overflow text-ellipsis'>
        <p className='subtext ' style={{ color: '#1D2129', fontSize: '1.5rem', fontWeight: '600' }}>{name}</p>
      </div>
    </StaffBox>
  );
};

export default StaffHeader;

const StaffBox = styled(Row)`
  gap: 10px;
  .subtext {
    overflow: hidden;
    color: #1D2129;
    text-overflow: ellipsis;
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%; /* 20px */
    }
    .staff-avatar {
      line-height: 30px;
      border: 1px solid #fff;
    }
`;