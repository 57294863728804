import { Modal as AntModal, ModalProps, Row, Spin } from 'antd';
import Text from 'components/Text';
import { CSSProperties } from 'react';
import styled from 'styled-components';

interface Props extends ModalProps {
  disabledOk?: boolean;
  visibleOk?: boolean;
  visibleClose?: boolean;
  aptFooter?: boolean;
  width?: string;
  height?: string;
  footerStyle?: CSSProperties,
  loading?:boolean;
}

const Modal = ({ loading,width = '75vw', height, onCancel = () => undefined, disabledOk, onOk = () => undefined, okText = 'Next', centered = true, footerStyle, ...rest }: Props) => {
  return (
    <AntModal
      {...rest}
      centered={centered}
      title={null}
      footer={false}
      closable={false}
      destroyOnClose={false}
      width={width}
    >
      <Header justify={'space-between'}>
        <Text className="title">{rest.title}</Text>
      </Header>
      <ContainerContent height={height}>
        <Content height={height}>
          {rest.children}
          <Footer style={footerStyle}>
            <button className='btn btn-close' onClick={onCancel}>
              <Text className="label">CLOSE</Text>
            </button>
            <button className='btn btn-update' onClick={onOk} disabled={disabledOk}>
              <Text className="label">{okText || 'NEXT'}</Text>
            </button>
          </Footer>
        </Content>
      {loading && <div className="spinning-wrapper">
        <Spin size='large' />
      </div>}
      </ContainerContent>
    </AntModal >
  );
};

export default Modal;
const Header = styled(Row)`
  height: 50px;
  position: relative;
  align-items: center;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  border-bottom: 1px solid #148CF1;
  background: #E8F3FF;

  .title {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    color: #1D2129;
    text-align: center;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 120% */
  }
  button {
    background: #fff;
    align-self: stretch;
    border-radius: 5px;
    width: 80px;
    
    &:hover {
      background: #FFEFEB;
    }

    &:disabled {
      opacity: 0.5;
    }
  }
  .text-action {
    color: #F05326;
    font-family: Poppins;
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 133.333% */
    white-space: nowrap;
  }
`;

const ContainerContent = styled.div<{ height?: string }>`
  max-height: ${({ height }) => height || '80vh'};
  overflow-y: auto;
  position: relative;
  display: flex;
  flex-direction: column;
  .spinning-wrapper {
    position: absolute;
    inset: 0;
    z-index: 2;
    display: flex;
    align-items: center;
    background: rgba(255, 255, 255, 0.6);
    justify-content: center;
  }
`;

const Content = styled.div<{ height?: string }>`
  min-height: ${({ height }) => height || '80vh'};
  display: flex;
  flex:1;
  flex-direction: column;
`;

const Footer = styled(Row)`
position: sticky;
padding: 0.75rem 1.5rem;
gap: 1rem;
bottom: 0;
right: 0;
left: 0;
background-color: #F6F6F6;
.btn {
  display: flex;
  height: 48px;
  padding: 10px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex: 1 0 0;

  .label {
    text-align: center;

    /* Body 1 */
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 150% */
  }
  
}
  .btn-delete, .btn-close {
    border-radius: 2px;
    border: 1px solid #7B7B7B;
    background: #EEE;
    .label {
      color: #7B7B7B;
    }
  }

  .btn-update {
    border-radius: 2px;
    background: #232F3E;
    .label {
      color: #FFF;
    }
  }
`;