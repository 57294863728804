import React from 'react';
import ModalReorderStaff, { useModalReorderStaffRef } from './ModalReorderStaff';

const ButtonReorderStaff = () => {
  const modal = useModalReorderStaffRef();
  const onReorder = () => modal.current?.open();
  return (
    <>
      <button onClick={onReorder}><IconReOrder /></button>
      <ModalReorderStaff ref={modal} />
    </>
  );
};

export default ButtonReorderStaff;

const IconReOrder = () => (
  <div style={{ width: '48px', height: '48px', background: '#fff', border: '1px solid #7B7B7B', display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: 100 }}>
    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
      <path opacity="0.4" fillRule="evenodd" clipRule="evenodd" d="M17.5017 2.41663C16.3443 2.41663 15.3959 3.36396 15.3959 4.54658V8.38797C15.3959 9.57059 16.3443 10.5179 17.5017 10.5179H21.3109C22.4683 10.5179 23.4168 9.57059 23.4168 8.38797V4.54658C23.4168 3.36396 22.4683 2.41663 21.3109 2.41663H17.5017ZM13.8959 4.54658C13.8959 2.54745 15.504 0.916626 17.5017 0.916626H21.3109C23.3086 0.916626 24.9168 2.54746 24.9168 4.54658V8.38797C24.9168 10.3871 23.3086 12.0179 21.3109 12.0179H17.5017C15.504 12.0179 13.8959 10.3871 13.8959 8.38797V4.54658Z" fill="#1D2129" />
      <path fillRule="evenodd" clipRule="evenodd" d="M0.916748 4.54658C0.916748 2.54746 2.52489 0.916626 4.52259 0.916626H8.3318C10.3295 0.916626 11.9376 2.54746 11.9376 4.54658V8.38797C11.9376 10.3871 10.3295 12.0179 8.3318 12.0179H4.52259C2.52489 12.0179 0.916748 10.3871 0.916748 8.38797V4.54658ZM4.52259 2.41663C3.36518 2.41663 2.41675 3.36396 2.41675 4.54658V8.38797C2.41675 9.57059 3.36518 10.5179 4.52259 10.5179H8.3318C9.4892 10.5179 10.4376 9.57059 10.4376 8.38797V4.54658C10.4376 3.36396 9.4892 2.41663 8.3318 2.41663H4.52259ZM0.916748 17.4452C0.916748 15.4461 2.52489 13.8153 4.52259 13.8153H8.3318C10.3295 13.8153 11.9376 15.4461 11.9376 17.4452V21.2866C11.9376 23.2845 10.3297 24.9166 8.3318 24.9166H4.52259C2.52471 24.9166 0.916748 23.2845 0.916748 21.2866V17.4452ZM4.52259 15.3153C3.36518 15.3153 2.41675 16.2626 2.41675 17.4452V21.2866C2.41675 22.4683 3.36537 23.4166 4.52259 23.4166H8.3318C9.48902 23.4166 10.4376 22.4683 10.4376 21.2866V17.4452C10.4376 16.2626 9.4892 15.3153 8.3318 15.3153H4.52259ZM17.5017 15.3153C16.3443 15.3153 15.3958 16.2626 15.3958 17.4452V21.2866C15.3958 22.4683 16.3445 23.4166 17.5017 23.4166H21.3109C22.4681 23.4166 23.4167 22.4683 23.4167 21.2866V17.4452C23.4167 16.2626 22.4683 15.3153 21.3109 15.3153H17.5017ZM13.8958 17.4452C13.8958 15.4461 15.504 13.8153 17.5017 13.8153H21.3109C23.3086 13.8153 24.9167 15.4461 24.9167 17.4452V21.2866C24.9167 23.2845 23.3088 24.9166 21.3109 24.9166H17.5017C15.5038 24.9166 13.8958 23.2845 13.8958 21.2866V17.4452Z" fill="#1D2129" />
    </svg>
  </div >
);
