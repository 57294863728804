import { useAppDispatch } from 'store/hooks';
import styled from 'styled-components';
import usePassCodeVerify from './TurnModals/PassCodeVerify';
import { turnDashboard } from './TurnModals/TurnDashboard/signal';
import turnActions from './services/actions';
import { turnUIActions } from './services/reducers';
type Props = {
  collapsed?: boolean;
  setCollapsed: (value: boolean) => void;
};
const TurnHeader = ({ collapsed, setCollapsed }: Props) => {
  const [verifyRef, pinCodeContext] = usePassCodeVerify();
  const dispatch = useAppDispatch();
  const onDashboard = () => {
    verifyRef.current?.checkAdmin(() => {
      dispatch(turnActions.data.turns.fetch());
      turnDashboard.open();
    });
  };

  const onRefreshList = () => {
    dispatch(turnUIActions.setLoading(true));
    dispatch(turnActions.data.listStaffs.fetch());
  };
  return (
    <Container>
      <button className='button-title' onClick={() => setCollapsed(!collapsed)}>
        <span className="title" >
          TURN
        </span>
      </button>
      <button className='btn-refresh' onClick={onRefreshList}>
        <RefreshIcon />
      </button>
      <div style={{ flex: 1 }} />
      {!collapsed && <button onClick={onDashboard}>
        <span>Dashboard</span>
      </button>}
      {pinCodeContext}
    </Container>
  );
};

export default TurnHeader;

const RefreshIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M6.41799 6.32391C7.59544 5.12701 9.04939 4.38841 10.5702 4.1052L10.1908 2C8.26318 2.35897 6.41836 3.29678 4.92921 4.81053C1.0236 8.78064 1.0236 15.2175 4.92921 19.1876L3.13208 21.0144H8.21511V15.8474L6.41799 17.6742C3.33462 14.5399 3.33462 9.4582 6.41799 6.32391ZM20.8679 2.98563H15.7849V8.1526L17.582 6.3258C20.6654 9.4601 20.6654 14.5418 17.582 17.6761C16.4046 18.873 14.9506 19.6116 13.4298 19.8948L13.8092 22C15.7368 21.641 17.5816 20.7032 19.0708 19.1895C22.9764 15.2194 22.9764 8.78254 19.0708 4.81243L20.8679 2.98563Z" fill="#2D3043" />
  </svg>
);

const Container = styled.div`
display: flex;
align-items: center;
justify-content: space-between;
padding: 16px 8px;
padding-bottom: 16px;
span.title {
  display: flex;
  align-items: center;
  gap: 6px;
  color: #1D2129;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
}
button.button-title {
  &:hover {
    background-color: #EAEDED;
    border-radius: 8px;
  }
}
button.btn-refresh {
  background: #fff;
  &:hover {
    background-color: #EAEDED;
    border-radius: 8px;
  }
}
button {
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 8px;

  span {
    color: #148CF1;
    text-align: center;
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
}
`;
