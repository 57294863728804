import { Row } from 'antd';
import React from 'react';
import StaffPicker from './StaffPicker';
import AptTypePicker from './AptTypePicker';
import aptSelectors from 'services/selectors/apt';
import { IStaffUI } from 'types/staff';
import { useAppDispatch } from 'store/hooks';
import aptActions from 'services/actions/apt';
import DatePicker from './DatePicker';

const AptListFilter = () => {
  const dispatch = useAppDispatch();
  const params = aptSelectors.table.params();

  const handleChooseStaff = (staff: IStaffUI | null) => {
    if (staff) {
      return dispatch(aptActions.table.setParams({ staffId: staff.id }));
    }
    dispatch(aptActions.table.setParams({ staffId: undefined }));
  };

  return (
    <Row style={{ marginBottom: '1rem', gap: '1.5rem', alignItems: 'center' }}>
      <StaffPicker
        staffId={params.staffId}
        onChoose={handleChooseStaff}
        onChooseAll={() => handleChooseStaff(null)}
      />
      <AptTypePicker />
      <DatePicker />
    </Row>
  );
};

export default AptListFilter;