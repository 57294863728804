import Text from 'components/Text';
import styled from 'styled-components';
type Props = {
  data: string;
  active?: boolean;
  selectAmount?: number;
  onClick: () => void;
};
const Category = ({ data, active, onClick }: Props) => {
  return (
    <CategoryStyled className={`${active ? 'active' : ''}`} onClick={onClick} >
      <Text className="name">{data}</Text>
    </CategoryStyled>
  );
};

export default Category;

const CategoryStyled = styled.button`
  display: flex;
  height: 56px;
  padding: 10px 8px;
  align-items: center;
  gap: 8px;

  .name {
    white-space: nowrap;
    color: #1D2129;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  &.active {
    background: #232F3E;
    .name {
      color: #FFF;
    }
  }

  &:hover {
    background: #232F3E;
    .name {
      color: #FFF;
    }
  }

`;
