import { signal } from '@preact/signals-react';

const openSignal = signal<boolean>(false);

export const turnDashboard = {
  openSignal,
  open: () => {
    openSignal.value = true;
  },
  close: () => {
    openSignal.value = false;
  },
};