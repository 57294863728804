import React from 'react';
import TextArea from 'antd/es/input/TextArea';
import styled from 'styled-components';
const suggestionReason = ['Sick', 'Have lunch', 'Have dinner', 'My birthday', 'Family’s Problem'];
type Props = {
  value: string;
  onChange: (value: string) => void;
};
const ReasonInput = ({ value, onChange }: Props) => {
  return (
    <ReasonInputStyled>
      <TextArea
        value={value}
        onChange={e => onChange(e.target.value)}
        rows={2}
        placeholder='Please enter...'
      />
      <SuggestionList>
        {suggestionReason.map(o => <button onClick={() => onChange(o)} key={o} className='suggestion-item'><span>{o}</span></button>)}
      </SuggestionList>
    </ReasonInputStyled>
  );
};

export default ReasonInput;
const ReasonInputStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap:8px;
  .ant-input {
    font-family: Poppins;
    font-size: 1.2rem;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 133.333% */
  }
`;
const SuggestionList = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  .suggestion-item {
    border-radius: 100px;
    background: #C5DDF8;
    display: flex;
    padding: 1px 8px;
    align-items: center;
    gap: 4px;
    span {
      color: #1D2129;
      font-family: Poppins;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px; /* 137.5% */
    }
  }
`;