import React from 'react';
import styled from 'styled-components';
import { formatCurrency } from 'utils/formatCurrency';
type Props = {
  ticketNumber?: number;
  half?: boolean;
  full?: boolean;
  amount?: number;
};
const TurnBodyItem = ({ ticketNumber, full, half, amount }: Props) => {
  return (
    <Container>
      <div className={`box-item ${full || half ? 'active' : ''}`}>
        {half && <>
          <div className="container-half">
            <div className="half-bg">
              <div>
                <svg width="60" height="40" viewBox="0 0 60 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path id="Vector 2" d="M0 40V0H60L30 20L0 40Z" fill="#1D2129" />
                </svg>
              </div>
            </div>
            <div className="ticket-number">#{ticketNumber}</div>
          </div>
          <div className="ticket-price-wrapper">
            <div className="ticket-price">$100</div>
          </div>
        </>}
        {full && <>
          <div className="container-half container-full">
            <div className="ticket-number">#{ticketNumber}</div>
          </div>
          <div className="ticket-price-wrapper">
            <div className="ticket-price">{formatCurrency(amount)}</div>
          </div>
        </>}
      </div>
    </Container >
  );
};

export default TurnBodyItem;

const Container = styled.td`

padding-right: 8px;

.box-item {
  width: 65px;
  height: 65px;
  border-radius: 5px;
  border: 1px solid #7B7B7B;
  background: #EEE;
  position: relative;
  display: flex;
  flex-direction: column;

  &.active {
    border: 1px solid #0CB76F;
    background: #EBFFF0;
  }

  .half-bg {
    position: absolute;
    inset: 0;

  }

  .container-half {
    flex: 1;
    height: 40px;
  }

  .ticket-number {
    margin-left: 4px;
    position: relative;
    color: #FFF;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
}

.ticket-price-wrapper {
  padding: 4px;
}

.ticket-price {
  color: #1D2129;
  text-align: center;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 12px;
}

.container-full {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid #0CB76F;
  .top-ticket {
    flex: 1;
    display: flex;
    align-items: center;
  }
  .ticket-number {
    position: relative;
    color: #0CB76F;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
}

`;