import styled from 'styled-components';
import AptListFilter from './Filter';
import AptListTable from './Table';
import { ConfigProvider } from 'antd';
import BlockHourTable from './Table/BlockHourTable';

const AppointmentList = () => {
  return (
    <ConfigProvider
      theme={{
        'token': {
          'colorPrimary': '#232F3E',
          'colorPrimaryBg': '#FFF1F6',
          'colorPrimaryActive': '#232F3E',
        },
      }}
    >
      <AppointmentListStyled>
        <AptListFilter />
        <BlockHourTable />
        <AptListTable />
      </AppointmentListStyled>
    </ConfigProvider>
  );
};

export default AppointmentList;

const AppointmentListStyled = styled.div`
  padding: 0 1.5rem;
  .title-section {
    color: #1D2129;
    text-overflow: ellipsis;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 120% */
    text-transform: uppercase;
  }
`;