import { Avatar, Row, Table, TableColumnsType } from 'antd';
import { ExpandableConfig } from 'antd/es/table/interface';
import { ANYONE_STAFF } from 'AppointmentBox/helper';
import Text from 'components/Text';
import { get, maxBy, minBy, set, sortBy } from 'lodash';
import moment from 'moment';
import { useMemo } from 'react';
import aptSelectors from 'services/selectors/apt';
import shopSelectors from 'services/selectors/shop';
import styled from 'styled-components';
import { signal } from '@preact/signals-react';

const time = (date: string) => moment(date, 'MM/DD/YYYY hh:mm A');
export const blockHourTableLoading = signal<boolean>(false);
type IUIBlockHourDateItem = {
  date: string;
  dateValue: number;
  day: string;
  dateStr: string;
  reasons: {
    id: string;
    time: string;
    content: string;
  }[];
};
type IUIBlockHourTableItem = {
  id: string;
  staffId: string;
  single: boolean;
  dateSummary: string;
  dates: IUIBlockHourDateItem[];
  reasonStr?: string;
};
type IUIBlockHourTableMappingItem = {
  id: string;
  staffId: string;
  dateObjects: Record<string, IUIBlockHourDateItem>;
};

const BlockHourTable = () => {
  const staffs = shopSelectors.staff();
  const blockHours = aptSelectors.table.blockHours();

  const listData = useMemo(() => {
    const objectData: Record<string, IUIBlockHourTableMappingItem> = {};
    blockHours.map(o => {
      const startTime = time(o?.startTime || '');
      const strStartTime = startTime?.format('MM-DD-YYYY');
      const endTime = time(o?.endTime || '');
      const staffId = o.staffId?.toString() || '';
      const key = staffId;
      const reasonItem = {
        id: o.id.toString(),
        content: o.reason,
        time: `${startTime.format('hh:mm A')} - ${endTime.format('hh:mm A')}`,
      };
      if (get(objectData, [key])) {
        if (get(objectData, [key, 'dateObjects', strStartTime], '')) {
          objectData[key].dateObjects[strStartTime].reasons.push(reasonItem);
        } else {
          set(objectData[key], ['dateObjects', strStartTime], {
            date: strStartTime,
            day: startTime.format('MMM DD'),
            dateStr: startTime.format('MMM DD, YYYY'),
            dateValue: startTime.valueOf(),
            reasons: [reasonItem]
          } as IUIBlockHourDateItem);
        }
      } else {
        set(objectData, [key], {
          id: key,
          staffId,
          dateObjects: {
            [strStartTime]: {
              date: strStartTime,
              day: startTime.format('MMM DD'),
              dateStr: startTime.format('MMM DD, YYYY'),
              dateValue: startTime.valueOf(),
              reasons: [reasonItem],
            }
          }
        } as IUIBlockHourTableMappingItem);
      }
    });

    return Object.values(objectData).map((o) => {
      const dates = sortBy(Object.values(o.dateObjects), 'dateValue');
      const maxDate = maxBy(dates, o => o.dateValue);
      const minDate = minBy(dates, o => o.dateValue);
      const isSameDate = !!maxDate?.dateStr.includes(minDate?.day || '');
      const reasonStr = dates.map(o => `${o.reasons.map(s => `${s.time} - ${s.content}`).join(', ')}`).join('');
      const result: IUIBlockHourTableItem = {
        staffId: o.staffId,
        id: o.id,
        dates,
        single: dates?.length === 1 && dates[0].reasons.length <= 1,
        dateSummary: isSameDate ? maxDate?.dateStr || '' : `${minDate?.day} - ${maxDate?.dateStr} `,
        reasonStr: dates?.length === 1 && dates[0].reasons.length <= 1 ? reasonStr : (reasonStr.length > 100 ? reasonStr?.slice(0, 100) + '...' : reasonStr),
      };
      return result;
    });
  }, [blockHours]);

  const columns: TableColumnsType<IUIBlockHourTableItem> = [
    {
      title: 'Staff', width: '200px', dataIndex: 'staffId', key: 'staffId',
      render: (staffId: string) => {
        const { avatar, name } = staffs.find(o => o.id.toString() === staffId.toString()) || ANYONE_STAFF;
        return (
          <Row align={'middle'}>
            <Avatar src={avatar} >{name?.[0] || ''}</Avatar>
            <Text style={{ marginLeft: '8px', fontSize: '18px' }} variant="CONTENT_2" color="text_3">
              {name}
            </Text>
          </Row>
        );
      },
    },
    { title: 'Date', width: '250px', dataIndex: 'dateSummary', key: 'date' },
    {
      title: 'Reasons', dataIndex: 'reasonStr', key: 'reasonStr',
      render(value) {
        return <div dangerouslySetInnerHTML={{ __html: value }}></div>;
      },
    },
  ];

  const expandedRowRender: ExpandableConfig<IUIBlockHourTableItem>['expandedRowRender'] = (test) => {
    if (test.single) return null;

    const columns: TableColumnsType<IUIBlockHourDateItem> = [
      { title: 'Date', className: 'date', dataIndex: 'date', key: 'date', width: '450px' },
      {
        title: 'Reasons', dataIndex: 'reasons', key: 'reasons', render(value: IUIBlockHourDateItem['reasons']) {
          return <div dangerouslySetInnerHTML={{ __html: value.map(o => `${o.time} - ${o.content}`).join('<br/> ') }}></div>;
        },
      },
    ];

    return <Table bordered className='sub-table' rowKey={'id'} columns={columns} dataSource={test.dates} pagination={false} />;
  };

  if (!blockHours.length) return null;

  return (
    <Container>
      <div className="title-section">
        Block Hours:
      </div>
      <Table
        loading={blockHourTableLoading.value}
        rowKey={'id'}
        columns={columns}
        expandable={{ expandedRowRender, expandedRowClassName: (o) => o.single ? 'single-expand' : 'expand' }}
        dataSource={listData}
        rowClassName={o => o.single ? 'single-expand-parent' : ''}
        size="small"
        pagination={false}
        className='table-block-hour'
      />

      <div className="title-section" style={{ marginTop: '16px' }}>
        Appointments:
      </div>
    </Container>
  );
};

export default BlockHourTable;
const Container = styled.div`
  display: flex;
  flex-direction: column;

.ant-table-wrapper.table-block-hour .ant-table {
    table {
      border: 1px solid #484F58;
      border-radius: 8px;
      thead td.ant-table-cell.ant-table-row-expand-icon-cell {
        background: #CECECE;
      }
      
      .ant-table-row-expand-icon.ant-table-row-expand-icon-expanded, 
      .ant-table-row-expand-icon.ant-table-row-expand-icon-collapsed {
        transform: scale(1.5);
      }

      .single-expand-parent {
        .ant-table-row-expand-icon {
          display: none;
        }
      }
      .single-expand {
        display: none;
      }
    }
    .ant-table-thead tr th {
      background: #CECECE;
    }
  }

  .ant-table-expanded-row {
    background: #E8F3FF;
    .ant-table-cell {

    }
  }
  .ant-table-wrapper.sub-table {
    padding-top: 8px;
    padding-bottom: 16px;
    padding-right: 48px;
  }
  .ant-table-wrapper.sub-table .ant-table{
    table {
      border: 1px solid #484F58;
      border-radius: 8px;
    }
    .ant-table-thead tr th {
      background: #484F58;
      color: #fff;
      font-size: 14px;
      font-weight: 600;
    }
    .ant-table-cell.date {
      vertical-align: top;
    }
  }
`;
