import { Modal as AntModal, ModalProps, Row } from 'antd';
import styled from 'styled-components';
import ButtonConfirm from './ButtonConfirm';
import Text from './Text';

interface Props extends ModalProps {
  disabledOk?: boolean;
  visibleOk?: boolean;
  visibleClose?: boolean;
  aptFooter?: boolean;
  updateAptFooter?: boolean;
  onDelete?: () => void;
  onUpdate?: () => void;
  width?: string;
  height?: string;
  disabledApt?: boolean;
}

const Modal = ({ disabledApt, visibleClose = true, width = '75vw', height, onUpdate, onDelete, updateAptFooter = false, aptFooter = false, visibleOk = true, onCancel = () => undefined, disabledOk, onOk = () => undefined, okText = 'OK', ...rest }: Props) => {
  return (
    <AntModal
      {...rest}
      centered
      title={null}
      footer={false}
      closable={false}
      destroyOnClose={false}
      width={width}
    >
      <Header justify={'space-between'}>
        {visibleClose && <button onClick={onCancel}><p className="text-action">CLOSE</p></button>}
        <Text className="title">{rest.title}</Text>
        {visibleOk && <button onClick={onOk} disabled={disabledOk}><p className="text-action">{okText}</p></button>}
        {updateAptFooter && onDelete && <ButtonConfirm
          onYes={onDelete}
          renderButton={(onPress) => <button onClick={onPress}><p className="text-action">{'DELETE'}</p></button>}
          modalTitle='DELETE APPOINTMENT'
          message='You are about to delete the appointment?'
        />}
      </Header>
      <ContainerContentModal height={height}>
        <Content height={height}>
          {rest.children}
          {!disabledApt && aptFooter && <Footer>
            {updateAptFooter ? <>
              <button className='btn btn-update' onClick={onUpdate}>
                <Text className="label">UPDATE</Text>
              </button>
            </> : <>
              <button className='btn btn-update' onClick={onOk}>
                <Text className="label">BOOK</Text>
              </button>
            </>}

          </Footer>}
        </Content>
      </ContainerContentModal>
    </AntModal>
  );
};

export default Modal;

type HeaderModalProps = {
  onCancel?: () => void;
  onOk?: () => void;
  title?: string;
  disabledOk?: boolean;
};
export const HeaderModal = ({ onCancel, onOk, title, disabledOk }: HeaderModalProps) => {
  return (
    <Header justify={'space-between'}>
      <button onClick={onCancel}><p className="text-action">CLOSE</p></button>
      <Text className="title">{title}</Text>
      <button onClick={onOk} disabled={disabledOk}><p className="text-action">{'OK'}</p></button>
    </Header>
  );
};

const Header = styled(Row)`
  height: 50px;
  position: relative;
  align-items: center;
  background: #fff;
  padding: 8px 8px;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  border-bottom: 1px solid #CECECE;

  .title {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    color: #1D2129;
    text-align: center;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 120% */
  }
  button {
    background: #fff;
    align-self: stretch;
    border-radius: 5px;
    width: 80px;
    &:hover {
      background: #FFEFEB;
    }

    &:disabled {
      opacity: 0.5;
    }
  }
  .text-action {
    color: #F05326;
    font-family: Poppins;
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 133.333% */
    white-space: nowrap;
  }
`;

export const ContainerContentModal = styled.div<{ height?: string }>`
  max-height: ${({ height }) => height || '80vh'};
  overflow-y: auto;
  position: relative;
  display: flex;
  flex-direction: column;
`;

const Content = styled.div<{ height?: string }>`
  height: ${({ height }) => height || '80vh'};
  display: flex;
  flex-direction: column;
`;

const Footer = styled(Row)`
position: sticky;
padding: 1rem 1.5rem;
gap: 1rem;
bottom: 0;
right: 0;
left: 0;
background-color: #F6F6F6;
.btn {
  display: flex;
  height: 56px;
  padding: 10px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex: 1 0 0;

  .label {
    text-align: center;

    /* Body 1 */
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
  }
  
}
  .btn-delete {
    border-radius: 2px;
    border: 1px solid #7B7B7B;
    background: #EEE;
    .label {
      color: #7B7B7B;
    }
  }

  .btn-update {
    border-radius: 2px;
    background: #F05326;
    .label {
      color: #FFF;
    }
  }
`;