import { Row } from 'antd';
import bhSignal from 'BlockHourBox/signal';
import ButtonQuickBooking from 'QuickBooking/ButtonQuickBooking';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import storage from 'utils/sessionStorage';
import Action, { RefreshBtn } from './Action';
import iconBack from './back.png';
import { IconApt, IconBlockHour } from './icons';
import Ontiloo from './Ontiloo';
type Props = {
  turn?: boolean;
};
const Nav = ({ turn }: Props) => {
  const navigate = useNavigate();
  const handleNewBlockHour = () => bhSignal.open();

  const goBack = async () => {
    // @ts-ignore
    window?.ReactNativeWebView?.postMessage('WEB_GO_BACK');
  };

  if (turn) {
    return (
      <NavTurnStyled>
        <button onClick={goBack}><BackButton /></button>
        <Ontiloo />
        <div style={{ flex: 1 }} />
        <Actions >
          <Action icon={IconBlockHour} title='Block hours' onClick={handleNewBlockHour} />
          <Action icon={IconApt} title='Appointment list' onClick={() => navigate(`/${storage.shopId.get()}/turn/list`)} />
          <ButtonQuickBooking />
          <RefreshBtn />
        </Actions>
      </NavTurnStyled>
    );
  }

  return (
    <NavTurnStyled>
      <button onClick={goBack}><BackButton /></button>
      <Ontiloo />
      <div style={{ flex: 1 }} />
      <Actions >
        <Action icon={IconBlockHour} title='Block hours' onClick={handleNewBlockHour} />
        <Action icon={IconApt} title='Appointment list' onClick={() => navigate(`/${storage.shopId.get()}/list`)} />
        <ButtonQuickBooking />
        <RefreshBtn />
      </Actions>
    </NavTurnStyled>
  );
};

export default Nav;

const NavTurnStyled = styled(Row)`
  background: #232F3E;
  align-items: center;
  padding: 8px 16px;
`;

const BackButton = () => (
  <BackButtonStyled>
    <img src={iconBack} />
  </BackButtonStyled>
);

const BackButtonStyled = styled.button`
  width: 32px;
  height: 32px;
  flex-shrink: 0;
  img {
    width: 100%;
    height: 100%;
  }
  margin-right: 1.5rem;
`;

const Actions = styled(Row)`
  gap: 1rem;
  align-self: stretch;
`;
