import { createSlice } from '@reduxjs/toolkit';
import { CALENDAR_VIEW_TYPE } from 'constants/calendar';
import moment from 'moment';
import { IStateAppointment } from 'services/types/apt';
import { momentTimezone } from 'utils/time';
import actions from '../actions/apt';
import { TABLE_PAGE_SIZE } from 'constants/table';

const NAME_REDUCER = 'apt';

const initialState: IStateAppointment = {
  calendar: {
    distanceTimeLine: 15,
    typeView: CALENDAR_VIEW_TYPE.DAY_VIEW,
    dateRanges: [],
    data: [],
    blockHours: [],
    params: {
      startTime: moment().format(),
    },
  },
  table: {
    data: [],
    params: {
      start_time: moment().format('YYYY-MM-DD'),
      end_time: moment().format('YYYY-MM-DD'),
      pageSize: TABLE_PAGE_SIZE,
    },
    total: 0,
    blockHours: [],
  },
  quickBooking: {
    services: [],
  },
  activeTimeRow: '',
};

export const Slice = createSlice({
  name: NAME_REDUCER,
  initialState,
  reducers: {
    activeTimeRow: (state, { payload }: { payload: string }) => {
      if (state.activeTimeRow === payload) {
        state.activeTimeRow = '';
        return;
      }
      state.activeTimeRow = payload;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(actions.table.blockHours.success, (state, { payload }) => {
        state.table.blockHours = payload;
      })
      .addCase(actions.quickBooking.getServices.success, (state, { payload }) => {
        state.quickBooking.services = payload;
      })
      .addCase(actions.table.setParams, (state, { payload }) => {
        const _params = {
          ...state.table.params || {},
          ...payload || {},
        };

        if (_params.staffId === undefined) {
          delete _params.staffId;
        }

        if (_params.type === -1) {
          delete _params.type;
        }

        state.table.params = _params;
      })
      .addCase(actions.table.getData.success, (state, { payload }) => {
        state.table.data = payload.data;
        state.table.total = payload.total;
      })
      .addCase(actions.calendar.getData.success, (state, { payload }) => {
        state.calendar.data = payload;
      })
      .addCase(actions.calendar.setBlockHoursData, (state, { payload }) => {
        state.calendar.blockHours = payload;
      })
      .addCase(actions.prolongedTimeBlockHour, (state, { payload }) => {
        state.calendar.blockHours = state.calendar.blockHours.map(o => {
          if (o.id === payload.id) {
            const endTime = momentTimezone(o.endTime);
            if (payload.status === 'ADD') {
              endTime.add(15, 'minutes');
            } else {
              endTime.subtract(15, 'minutes');
            }
            return ({
              ...o,
              endTime: endTime.format('MM-DD-YYYY HH:mm:ss')
            });
          }
          return o;
        });
      })
      .addCase(actions.calendar.prolongedTime, (state, { payload }) => {
        state.calendar.data = state.calendar.data.map(o => {
          if (o.id === payload.id) {
            const endTime = momentTimezone(o.endTime);
            if (payload.status === 'ADD') {
              endTime.add(15, 'minutes');
            } else {
              endTime.subtract(15, 'minutes');
            }
            return ({
              ...o,
              endTime: endTime.format('MM-DD-YYYY HH:mm:ss')
            });
          }
          return o;
        });
      })
      .addCase(actions.calendar.updateDrop.fetch, (state, { payload }) => {
        state.calendar.data = state.calendar.data.map(o => {
          if (o.id === payload.id) {
            const endTime = momentTimezone(payload.startTime).add(payload.distance, 'minutes');
            return ({
              ...o,
              staffId: payload.staffId || o.staffId,
              startTime: payload.startTime,
              endTime: endTime.format('MM-DD-YYYY HH:mm:ss')
            });
          }
          return o;
        });
      })
      .addCase(actions.calendar.setDistanceTimeline, (state, { payload }) => {
        state.calendar.distanceTimeLine = payload;
      })
      .addCase(actions.calendar.setTypeView, (state, { payload }) => {
        state.calendar.typeView = payload;
      })
      .addCase(actions.calendar.setDateRanges, (state, { payload }) => {
        state.calendar.dateRanges = payload;
      })
      .addCase(actions.initData, (state) => {
        state.table = {
          data: [],
          params: {
            start_time: moment().format('YYYY-MM-DD'),
            end_time: moment().format('YYYY-MM-DD'),
            pageSize: TABLE_PAGE_SIZE,
          },
          total: 0,
          blockHours: [],
        };
      })
      ;
  },
});
export const aptUIActions = Slice.actions;
const aptReducer = Slice.reducer;
export default aptReducer;
