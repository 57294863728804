import { ADD_APPOINTMENT_TABS, ANYONE_NAILS_ID, ANYONE_STAFF } from 'AppointmentBox/helper';
import { Checkbox, Col, Row } from 'antd';
import shopSelectors from 'services/selectors/shop';
import styled from 'styled-components';
import { IStaffUI } from 'types/staff';
import InputSearch from './InputSearch';
import Staff from './Staff';
import aptSignal from 'AppointmentBox/signal';
import { computed } from '@preact/signals-react';
import TabLabel from 'AppointmentBox/TabLabel';
import Text from 'components/Text';

const Label = () => {
  const activeKey = computed<string>(() => aptSignal.tabSignal.value).value;
  const staffs = shopSelectors.staff();
  const staff = computed<IStaffUI | null>(() => {
    if (aptSignal.staffIdSignal.value === ANYONE_NAILS_ID) return ANYONE_STAFF;
    return staffs.find(o => o.id === aptSignal.staffIdSignal.value) || null;
  }).value;
  return (
    <TabLabel
      label='Staff'
      tabIndex={2}
      completed={!!staff?.id}
      active={activeKey === ADD_APPOINTMENT_TABS.STAFF}
    />
  );
};


const Children = () => {
  const staffs = shopSelectors.staff();
  const staff = computed<IStaffUI | null>(() => {
    if (aptSignal.staffIdSignal.value === ANYONE_NAILS_ID) return ANYONE_STAFF;
    return staffs.find(o => o.id === aptSignal.staffIdSignal.value) || null;
  }).value;

  const handleChooseStaff = (o: IStaffUI) => () => {
    aptSignal.staffIdSignal.value = o.id;
    aptSignal.next();
  };

  return (
    <StaffTabStyled>
      <Text mb={0.5}>
        <Checkbox
          checked={aptSignal.requestStaff.value}
          className='value-section checkbox-request-staff'
          onChange={(e) => {
            aptSignal.requestStaff.value = e.target.checked;
          }}
        >
          Request Staff
        </Checkbox>
      </Text>
      <InputSearch onSelect={(o) => handleChooseStaff(o)()} />
      <Staffs wrap gutter={[16, 16]}>
        <StaffWrap span={4} sm={6} xl={3} >
          <Staff anyone
            active={staff?.id === ANYONE_NAILS_ID}
            onClick={handleChooseStaff(ANYONE_STAFF)}
          />
        </StaffWrap>
        {
          staffs.map(o => (
            <StaffWrap key={o.id} span={4} sm={6} xl={3} >
              <Staff data={o}
                active={staff?.id === o.id}
                onClick={handleChooseStaff(o)}
              />
            </StaffWrap>
          ))
        }
      </Staffs>
    </StaffTabStyled>
  );
};

const StaffTab = {
  Label,
  Children,
};

export default StaffTab;

const StaffTabStyled = styled.div`
  height: 100%;
`;

const Staffs = styled(Row)`
display: flex;
margin-top: 12px;
`;

const StaffWrap = styled(Col)`
  width: 20%;
`;