import { createAsyncAction } from 'store/actionConfigs';
import { PREFIX_ACTIONS } from './constants';

const data = {
  listStaffs: createAsyncAction(PREFIX_ACTIONS + 'getListStaffs'),
  turns: createAsyncAction(PREFIX_ACTIONS + 'getAllTurnByStaff'),
};

const getOpenTicketsByStaffId = createAsyncAction<string>(PREFIX_ACTIONS + 'getOpenTicketsByStaffId');

const turnActions = {
  data,
  getOpenTicketsByStaffId,
};

export default turnActions;

