import React from 'react';
import styled from 'styled-components';

const AppointmentHint = () => {
  return (
    <Container>
      <div className="hint-item">
        <div className="icon">{confirmIcon}</div>
        <p className="text">Confirmed Book</p>
      </div>
      <div className="hint-item">
        <div className="icon">{requestIcon}</div>
        <p className="text">Request Staff</p>
      </div>
    </Container>
  );
};

export default AppointmentHint;

const Container = styled.div`
  position: absolute;
  padding: 0 1.5rem;
  right:0;
  bottom: 0;
  display: inline-flex;
  align-items: flex-start;
  gap: 24px;
  .hint-item {
    display: flex;
    align-items: center;
    gap: 8px;
    p.text {
      color: #1D2129;
      font-family: Poppins;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
  }
`;

const confirmIcon = <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
  <rect y="0.5" width="24" height="24" rx="12" fill="#148CF1" />
  <path d="M9.72692 18.5C9.40676 18.5 9.10261 18.3661 8.8785 18.1317L4.34817 13.3936C3.88394 12.9081 3.88394 12.1045 4.34817 11.6189C4.8124 11.1334 5.58078 11.1334 6.04501 11.6189L9.72692 15.4697L17.955 6.86414C18.4192 6.37862 19.1876 6.37862 19.6518 6.86414C20.1161 7.34967 20.1161 8.1533 19.6518 8.63882L10.5753 18.1317C10.3512 18.3661 10.0471 18.5 9.72692 18.5Z" fill="white" />
</svg>;

const requestIcon = <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
  <rect y="0.5" width="24" height="24" rx="5" fill="#148CF1" />
  <path d="M4.5 10.353C4.5 14.0002 7.51457 15.9437 9.7213 17.6834C10.5 18.2972 11.25 18.8752 12 18.8752C12.75 18.8752 13.5 18.2972 14.2787 17.6834C16.4854 15.9437 19.5 14.0002 19.5 10.353C19.5 6.7058 15.3748 4.11928 12 7.62566C8.62512 4.11928 4.5 6.7058 4.5 10.353Z" fill="white" />
</svg>
  ; 