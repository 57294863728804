import { debounce } from 'lodash';
import { useEffect } from 'react';
import { Outlet, useParams } from 'react-router-dom';
import { useSocketContext } from 'services/useSocket';

function ShopWrapperLayout() {
  const { shop_id = '' } = useParams();
  const socket = useSocketContext();
  const initConnectSocket = debounce(() => socket.connect(shop_id), 100);
  useEffect(() => {
    initConnectSocket();
    return () => {
      socket.unsubscribeAll();
    };
  }, []);

  return (
    <>
      <Outlet />
    </>
  );
}

export default ShopWrapperLayout;
