import { forwardRef, useImperativeHandle, useRef } from 'react';
import MaskedInput from 'react-text-mask';
import styled from 'styled-components';

const PhoneInput = forwardRef((props: any, ref) => {
  const inputRef = useRef<MaskedInput>(null);

  useImperativeHandle(ref, () => ({
    focus: () => inputRef.current?.inputElement?.focus(),
    blur: () => inputRef.current?.inputElement?.blur(),
  }));

  return (
    <PhoneInputStyled>
      <MaskedInput
        {...props}
        ref={inputRef}
        className={'phone-input ' + props.className}
        mask={
          ['(', /[0-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]
        }
        placeholder='(000) 000-0000'
      />
    </PhoneInputStyled>
  );
});

PhoneInput.displayName = 'PhoneInput';

export default PhoneInput;

export const PhoneInputStyled = styled.div`
display: flex;
flex:1;
input {
  border-radius: 6px;
  border: 1px solid #86909C;
  background: #FFF;
  display: flex;
  width: 100%;
  height: 44px;
  align-items: center;
  padding: 0px 11px;

  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  line-height: 1.5;
  outline: none;
  &:focus, &:hover {
    outline: none;
    border-color: #fc7c51;
  }
  &::placeholder {
    color:#dadada;
  }
}

`;