import Text from 'components/Text';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import aptActions from 'services/actions/apt';
import { useAppDispatch } from 'store/hooks';
import styled from 'styled-components';
import storage from 'utils/sessionStorage';
import Calendar from '../Calendar';
import { isModeFull, switchToZoom } from 'Calendar/styles';

const TurnSchedulerPage = () => {
  const { shop_id } = useParams();

  const dispatch = useAppDispatch();
  useEffect(() => {
    switchToZoom(1);
    dispatch(aptActions.initData(shop_id || storage.shopId.get()));
  }, []);
  return (
    <PageStyled>
      <Text mb={isModeFull.value ? 1 : 1.5} />
      <Calendar />
    </PageStyled>
  );
};

export default TurnSchedulerPage;

const PageStyled = styled.div`
  display: flex;
  flex-direction: column;
  background: #EAEDED;
  // position: fixed;
  position: absolute;
  inset: 0;
  margin-bottom: 10px;
`;
