import { Col, Form, Row, Select } from 'antd';
import DatePicker from 'components/DatePicker';
import { useEffect } from 'react';
import shopSelectors from 'services/selectors/shop';
import styled from 'styled-components';
import InputSearchAvatar, { useInputSearchRef } from 'widgets/Staff/InputSearchAvatar';
import Modal from './Modal';
import ReasonInput from './ReasonInput';
import TimeSlot from './TimeSlot';
import { timeLogOpts } from './helpers';
import bhSignal from './signal';
import { useAppDispatch } from 'store/hooks';
import aptActions from 'services/actions/apt';

const BlockHoursModal = () => {
  const dispatch = useAppDispatch();
  const handleClose = () => bhSignal.close();
  const staffList = shopSelectors.staff();
  const inputSearchStaff = useInputSearchRef();
  useEffect(() => {
    if (!bhSignal.openSignal.value) return;
    const staff = bhSignal.staffIdSignal.value ? staffList.find(o => o.id === bhSignal.staffIdSignal.value) : null;
    inputSearchStaff.current?.setStaff(staff || null);
  }, [bhSignal.openSignal.value]);

  const handleBlockHours = async () => {
    const payload = bhSignal.getPayload();
    if (!payload) return null;
    bhSignal.openSignal.value = false;

    const id = bhSignal.detailSignal.value?.id;
    if (id) {
      dispatch(aptActions.updateBlockHour({ ...payload, id: +id }));
    } else {
      dispatch(aptActions.addBlockHour(payload));
    }
  };

  const handleDelete = async () => {
    const id = bhSignal.detailSignal.value?.id;
    if (!id) return;
    bhSignal.openSignal.value = false;
    dispatch(aptActions.deleteBlockHour(id));
  };

  return (
    <Modal
      title="BLOCK HOURS"
      open={bhSignal.openSignal.value}
      onCancel={handleClose}
      okText={bhSignal.detailSignal.value?.id ? 'UPDATE' : 'BLOCK HOURS'}
      onOk={handleBlockHours}
      height='80vh'
      updateAptFooter={!!bhSignal.detailSignal.value?.id}
      onDelete={handleDelete}
      onUpdate={handleBlockHours}
    >
      <FormStyled>
        <Row gutter={[16, 16]}>
          <Col span={8}>
            <Form.Item label='STAFF' validateStatus={bhSignal.errorStaffSignal.value ? 'error' : undefined} help={bhSignal.errorStaffSignal.value}>
              <InputSearchAvatar ref={inputSearchStaff} onSelect={bhSignal.onSelectStaff} />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label='DATE'>
              <DatePicker value={bhSignal.activeDateSignal.value} onChange={bhSignal.onChangeActiveDate} />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label='DURATION'>
              <Select
                size='large'
                defaultValue={15}
                options={timeLogOpts}
                value={bhSignal.durationSignal.value}
                onChange={bhSignal.onChangeDuration}
              />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item label='REASON' validateStatus={bhSignal.errorReasonSignal.value ? 'error' : undefined} help={bhSignal.errorReasonSignal.value}>
          <ReasonInput value={bhSignal.reasonSignal.value} onChange={bhSignal.onChangeReason} />
        </Form.Item>
        <Form.Item label='START TIME'>
          <TimeSlot value={bhSignal.startTimeSlot.value} onChange={bhSignal.onChangeStartTime} />
        </Form.Item>
      </FormStyled>
    </Modal>
  );
};

export default BlockHoursModal;

const FormStyled = styled.div`
  padding: 1rem 1.5rem;
  flex: 1;
  .group {
    gap: 1.5rem;
  }
  .col-item {
    flex:1;
  }
  input.ant-input {
    line-height: 2;
    &::placeholder {
      color:#dadada;
    }
  }
  .ant-form-item-row {
    display: flex;
    flex-direction: column;
    .ant-form-item-label {
      text-align: left;
    }
  }

  .ant-select-single.ant-select-lg {
    height: 56px;
  }
  .ant-form-item .ant-form-item-label >label {
    color: #7B7B7B;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 120% */
  }
  &.ant-form-large .ant-form-item .ant-form-item-label>label {
    height: 24px;
    color: #7B7B7B;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 120% */
  }

  .ant-select-single.ant-select-lg .ant-select-selector {
    font-size: 1.2rem;
    font-weight: 500;
    font-family: Poppins;
  }
`;