import { AutoComplete, Empty, Input, InputRef, Row, Spin } from 'antd';
import { AxiosResponse } from 'axios';
import { PhoneInputStyled } from 'components/PhoneInput';
import Text from 'components/Text';
import { debounce } from 'lodash';
import { useCallback, useMemo, useState } from 'react';
import Highlighter from 'react-highlight-words';
import apis from 'services/apis';
import shopSelectors from 'services/selectors/shop';
import { ICustomerResItem } from 'services/types/response';
import styled from 'styled-components';
import { ICustomerUI } from 'types/customer';
import { formatPhone } from 'utils/formatPhone';
import storage from 'utils/sessionStorage';
import AddCustomer from './AddCustomer';

type Props = {
  onSelect: (data: ICustomerUI) => void;
  inputRef: React.RefObject<InputRef>;
  open?: boolean;
  setOpen?: (val: boolean) => void;
};
const InputSearchByName = ({ onSelect, inputRef, open, setOpen }: Props) => {
  const customerStore = shopSelectors.customers();
  const [customersFilter, setCustomersFilter] = useState<ICustomerUI[]>([]);
  const [loading, setLoading] = useState(false);

  const initialOptions = useMemo(() => {
    return customerStore.map(o => {
      const phone = (o?.phone || '');
      return ({
        value: o.id,
        name: o.name,
        keywords: [o.name?.toLocaleLowerCase(), phone],
        label: renderLabel(o.name, phone, []),
      });
    });
  }, [customerStore]);

  const [searchText, setSearchText] = useState<string>('');

  const optionsFilter = useMemo(() => {
    const searchWords = [searchText];
    return customersFilter.map(o => {
      const phone = o?.phone || '';
      return ({
        value: o.id,
        name: o.name,
        label: renderLabel(o.name, phone, searchWords),
      });
    });
  }, [customersFilter, searchText]);

  const options = useMemo(() => {
    if (!searchText) return initialOptions;
    return optionsFilter;
  }, [initialOptions, searchText, optionsFilter]);

  const handleSwitchCustomer = (data: string) => {
    inputRef?.current?.blur();
    setSearchText('');
    const result = (searchText ? customersFilter : customerStore)?.find((item) => item.id === data);
    if (!result) return;
    onSelect(result);
  };

  const getList = useCallback(debounce(async (search: string) => {
    try {
      const res: AxiosResponse<{ payload: { customers: ICustomerResItem[] } }> = await apis.getAllCustomerAutoSearchPage(storage.shopId.get(), search);

      const customerRes = res?.data?.payload?.customers;
      if (customerRes) {
        const data: ICustomerUI[] = customerRes.map(o => ({
          id: o.id?.toString(),
          name: o.name,
          phone: o.phone,
        }));
        setCustomersFilter(data);
      }
    } catch (error) { }
    finally {
      setLoading(false);
    }
  }, 500), []);

  return (
    <InputSearchStyled wrap={false} align={'middle'}>
      <AutoComplete
        style={{ width: '100%' }}
        options={options}
        open={open}
        onDropdownVisibleChange={setOpen}
        dropdownRender={(menu) => (<>
          {searchText && loading ? <Row align={'middle'} justify={'center'}><Text py={1.5}><Spin /></Text></Row> : menu}
        </>)}
        notFoundContent={<Empty />}
        onSelect={handleSwitchCustomer}
        value={searchText}
        onChange={text => {
          if (text) setLoading(true);
          setSearchText(text);
          getList(text);
        }}
      >
        <PhoneInputStyled>
          <Input
            ref={inputRef}
            placeholder='Enter name' />
        </PhoneInputStyled>
      </AutoComplete>
      <AddCustomer onSelect={onSelect} />
    </InputSearchStyled>
  );
};

export default InputSearchByName;

const InputSearchStyled = styled(Row)`
  gap: 1rem;
  .ant-select-single {
    height: unset;
  }
`;

const renderLabel = (name: string, tel: string, searchWords: string[]) => (
  <div
    style={{
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    }}
  >
    <div
      style={{
        display: 'flex',
        alignItems: 'flex-start',
        flexDirection: 'column',
      }}
    >
      <Text variant="CONTENT_2" color="text_3">
        <Highlighter
          searchWords={searchWords}
          autoEscape={true}
          textToHighlight={name}
        />
      </Text>
    </div>
    <div>
      <Text variant="BODY_1" color="text_3">
        {formatPhone(tel)}
      </Text>
    </div>
  </div>
);
