import { Avatar, Checkbox } from 'antd';
import { useMemo } from 'react';
import shopSelectors from 'services/selectors/shop';
import styled from 'styled-components';
import qbSignal from './signal';
import ModalStaff, { useModalStaffRef } from './ModalStaff';
import { IStaffUI } from 'types/staff';
import { ANYONE } from 'Calendar/anyone';

const StaffSection = () => {
  const staffModalRef = useModalStaffRef();
  const _staffStore = shopSelectors.staff();
  const staffStore = useMemo(() => [ANYONE].concat(_staffStore), [_staffStore]);
  const staffSelected = useMemo(() => {
    return staffStore.filter(o => (qbSignal.staffs.value.includes(o.id)));
  }, [staffStore, qbSignal.staffs.value]);

  const handleChooseStaff = (staff: IStaffUI, extraData?: IStaffUI) => {
    const temp = [...qbSignal.staffs.value];
    // Add new
    if (!extraData) {
      if (qbSignal.staffs.value?.includes(staff.id)) return;
      temp.push(staff.id);
      qbSignal.staffs.value = temp;
      return;
    }
    // update

    const existIdx = qbSignal.staffs.value.indexOf(extraData.id);
    if (existIdx === -1) return;
    temp[existIdx] = staff.id;
    qbSignal.staffs.value = temp;
  };

  return (
    <Container>
      <div className="top-filter">
        <div className="section-label">STAFF</div>
        <Checkbox
          checked={qbSignal.requestStaff.value}
          className='value-section checkbox-request-staff'
          onChange={(e) => {
            qbSignal.requestStaff.value = e.target.checked;
          }}
        >
          Request Staff
        </Checkbox>
      </div>
      <div className='staffs-list-container'>
        <div className="staffs-list">
          {staffSelected.map(o => (
            <div key={o.id} className="staff-item">
              <Avatar size={'small'} src={o.avatar} style={{ backgroundColor: '#148CF1' }}>{o.name?.[0] || 'A'}</Avatar>
              <span className="staff-name">{o.name}</span>
              <button onClick={() => staffModalRef.current?.open(o)} type='button' className='btn-change'>{iconChange}</button>
            </div>
          ))}
        </div>
        <button type='button' className='button-add-more' onClick={() => staffModalRef.current?.open()}>
          {iconPlus}
          {staffSelected.length > 3 ? null : <span>ADD MORE STAFF</span>}
        </button>
      </div>
      <ModalStaff ref={staffModalRef} staffList={staffStore} selected={qbSignal.staffs.value} onChange={handleChooseStaff} />
    </Container>
  );
};

export default StaffSection;
const iconPlus = (
  <svg xmlns="http://www.w3.org/2000/svg" width="46" height="46" viewBox="0 0 46 46" fill="none">
    <rect x="0.335938" y="0.335938" width="44.8641" height="44.8641" rx="5" fill="#C5DDF8" />
    <path d="M32.3745 25.322H25.5047V32.3735H20.7067V25.322H13.8369V20.8875H20.7067V13.8359H25.5047V20.8875H32.3745V25.322Z" fill="#1D2129" />
  </svg>
);

const Container = styled.div`
  .top-filter {
    display: flex;
    align-items: center;
    height: 36px;
    margin-bottom: 8px;
    gap: 24px;
  }

  .staffs-list-container {
    display: flex;
    align-items: center;
    gap: 16px;
    .staffs-list {
      flex: 1;
      gap: 16px;
      display: flex;
      align-items: center;
    }
    .staff-item {
      display: flex;
      align-items: center;
      .ant-avatar.ant-avatar-sm {
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        .ant-avatar-string {
          font-size: 20px;
        }
      }

      .staff-name {
        margin-left: 8px;
        color: #1D2129;
        font-family: Poppins;
        font-size: 22px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }

      .btn-change {
        width: 46px;
        height: 46px;
      }
    }

    .button-add-more {
      display: flex;
      padding-right: 12px;
      justify-content: center;
      align-items: center;
      border-radius: 5px;
      background: #C5DDF8;
      span {
        color: #1D2129;
        text-align: center;
        font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        text-transform: uppercase;
      }
    }
  }
`;

const iconChange = <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
  <path fillRule="evenodd" clipRule="evenodd" d="M8.9309 8.92701C10.4222 7.43572 12.2637 6.51544 14.1899 6.16257L13.7094 3.53955C11.2679 3.98682 8.93137 5.15531 7.04528 7.0414C2.09864 11.988 2.09864 20.0081 7.04528 24.9548L4.76913 27.2309H11.207V20.793L8.93089 23.0692C5.02565 19.1639 5.02565 12.8323 8.9309 8.92701ZM27.2324 4.76762H20.7945V11.2055L23.0707 8.92938C26.9759 12.8346 26.9759 19.1663 23.0707 23.0715C21.5794 24.5628 19.7379 25.4831 17.8116 25.836L18.2922 28.459C20.7336 28.0117 23.0702 26.8432 24.9563 24.9571C29.9029 20.0105 29.9029 11.9904 24.9563 7.04376L27.2324 4.76762Z" fill="#148CF1" />
</svg>;
