import React from 'react';
import styled from 'styled-components';
import Logo from './Logo';
import AlarmClockUI from './AlarmClockUI';

const Ontiloo = () => {
  return (
    <Container>
      <Logo />
      <div className="divider"/>
      <AlarmClockUI />
    </Container>
  );
};

export default Ontiloo;
const Container = styled.div`
  display: flex;
  align-items: center;
  .divider {
    background: #FFEFEB;
    width: 1px;
    height: 40px;
    margin: 0 24px;
  }
`;