import { Col, Input, Row } from 'antd';
import Modal from 'components/Modal';
import Staff from 'components/Staff';
import { useMemo, useState } from 'react';
import shopSelectors from 'services/selectors/shop';
import styled from 'styled-components';
import { IStaffUI } from 'types/staff';
import InputSearch from 'widgets/Staff/InputSearch';
type Props = {
  staffId?: string;
  onChoose?: (o: IStaffUI) => void;
  onChooseAll?: () => void;
};
const StaffPicker = ({ onChoose = () => undefined, onChooseAll = () => undefined, staffId }: Props) => {
  const staffs = shopSelectors.staff();
  const [open, setOpen] = useState(false);

  const staff = useMemo(() => staffs.find(o => o.id === staffId), [staffId, staffs]);

  const handleChoose = (o: IStaffUI) => () => {
    onChoose(o);
    setOpen(false);
  };

  const handleChooseAllStaff = () => {
    onChooseAll();
    setOpen(false);
  };

  return (
    <>
      <StaffControl>
        <Input disabled value={staff ? staff.name : 'All staff'} prefix={<LockupIcon />} suffix={<DropdownIcon />} />
        <button onClick={() => setOpen(true)} />
      </StaffControl>
      <Modal
        open={open}
        title={'SEARCH STAFF'}
        visibleOk={false}
        onCancel={() => setOpen(false)}>
        <StaffTabStyled>
          <InputSearch onSelect={(o) => handleChoose(o)()} />
          <Staffs wrap gutter={[16, 16]}>
            <StaffWrap span={4} sm={6} xl={3} >
              <Staff allStaff
                onClick={handleChooseAllStaff}
              />
            </StaffWrap>
            {
              staffs.map(o => (
                <StaffWrap key={o.id} span={4} sm={6} xl={3} >
                  <Staff data={o} onClick={handleChoose(o)} />
                </StaffWrap>
              ))
            }
          </Staffs>
        </StaffTabStyled>
      </Modal>
    </>
  );
};

export default StaffPicker;

const StaffControl = styled(Row)`
  flex: 1;
  &:hover {
    cursor: pointer !important;
  }
  input {
    &:hover {
      cursor: pointer !important;
    }
    color: #1D2129 !important;
    text-overflow: ellipsis;
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .ant-input-affix-wrapper {
    height: 56px;
    background: #fff;
  }
  .ant-input-affix-wrapper.ant-input-affix-wrapper-disabled {
    &:hover {
      cursor: pointer !important;
    }
  }
  .ant-input-affix-wrapper >input.ant-input {
    font-size: 18px;
  }
  position: relative;
  button {
    position: absolute;
    inset: 0;
    z-index:10;
  }
`;


const StaffTabStyled = styled.div`
  height: 100%;
  padding: 1.5rem;
  flex-grow: 1;
`;

const Staffs = styled(Row)`
display: flex;
margin-top: 12px;
flex-grow: 1;
`;

const StaffWrap = styled(Col)``;

const LockupIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
    <path d="M8.09048 16.181C9.83693 16.181 11.4676 15.6128 12.7933 14.666L17.7801 19.6528C18.0116 19.8843 18.3167 20 18.6323 20C19.3161 20 19.8106 19.474 19.8106 18.8006C19.8106 18.485 19.7054 18.1904 19.474 17.959L14.5187 12.9932C15.5602 11.6255 16.181 9.93161 16.181 8.09048C16.181 3.64019 12.5408 0 8.09048 0C3.62967 0 0 3.64019 0 8.09048C0 12.5408 3.62967 16.181 8.09048 16.181ZM8.09048 14.4345C4.6081 14.4345 1.74645 11.5623 1.74645 8.09048C1.74645 4.61862 4.6081 1.74645 8.09048 1.74645C11.5623 1.74645 14.4345 4.61862 14.4345 8.09048C14.4345 11.5623 11.5623 14.4345 8.09048 14.4345Z" fill="#1D2129" />
  </svg>
);

const DropdownIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path fillRule="evenodd" clipRule="evenodd" d="M3.49219 9.42969L4.9064 8.01547L11.9775 15.0865L19.0485 8.01548L20.4627 9.42969L12.6846 17.2078C12.6846 17.2078 12.6846 17.2079 11.9775 16.5008L12.6846 17.2078L11.9775 17.915L3.49219 9.42969Z" fill="#7B7B7B" />
  </svg>
);

