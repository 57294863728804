import { InputRef } from 'antd';
import React from 'react';
import styled from 'styled-components';
type Props = {
  value?: string;
  onChange?: (value: string) => void;
  inputRef: React.RefObject<InputRef>;
};
const NumberPad = ({ value = '', onChange = () => undefined, inputRef }: Props) => {
  const handleChange = (text: string) => () => {
    if (text.length > 4) return;
    onChange(text);
    inputRef.current?.focus();
  };
  return (
    <Container>
      <button type='button' className="number-pad-item" onClick={handleChange(value + '1')} ><span>1</span></button>
      <button type='button' className="number-pad-item" onClick={handleChange(value + '2')} ><span>2</span></button>
      <button type='button' className="number-pad-item" onClick={handleChange(value + '3')} ><span>3</span></button>
      <button type='button' className="number-pad-item" onClick={handleChange(value + '4')} ><span>4</span></button>
      <button type='button' className="number-pad-item" onClick={handleChange(value + '5')} ><span>5</span></button>
      <button type='button' className="number-pad-item" onClick={handleChange(value + '6')} ><span>6</span></button>
      <button type='button' className="number-pad-item" onClick={handleChange(value + '7')} ><span>7</span></button>
      <button type='button' className="number-pad-item" onClick={handleChange(value + '8')} ><span>8</span></button>
      <button type='button' className="number-pad-item" onClick={handleChange(value + '9')} ><span>9</span></button>
      <button type='button' className="number-pad-item btn-clear" onClick={handleChange('')}><span>CLEAR</span></button>
      <button type='button' className="number-pad-item" onClick={handleChange(value + '0')} ><span>0</span></button>
      <button type='submit' className="number-pad-item btn-enter"><span>ENTER</span></button>
    </Container>
  );
};

export default NumberPad;
const Container = styled.div`

display: grid;
grid-template-columns: 1fr 1fr 1fr;
grid-gap: 8px;
padding: 8px;
background: #E2E7EB;

.number-pad-item {
  display: flex;
  height: 5rem;
  width: 12rem;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex: 1 0 0;
  border-radius: 5px;
  background: #FFF;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25), -1px 1px 2px 0px rgba(0, 0, 0, 0.10) inset;

  span {
    color: #1D2129;
    text-align: center;
    font-family: Poppins;
    font-size: 40px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  &.btn-clear,
  &.btn-enter {
    span {
      color: #FFF;
      text-align: center;
      font-family: Poppins;
      font-size: 32px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
  }

  &.btn-clear {
    border-radius: 5px;
    background: #FF6F00;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25), -1px 1px 2px 0px rgba(0, 0, 0, 0.10) inset;
  }

  &.btn-enter {
    border-radius: 5px;
    background: #148CF1;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25), -1px 1px 2px 0px rgba(0, 0, 0, 0.10) inset;
  }
}

`;