import Sidebar from 'antd/es/layout/Sider';
import { useState } from 'react';
import styled from 'styled-components';
import TurnList from './TurnList';
import TurnConfigurations from './Configurations';
import TurnHeader from './TurnHeader';

const TurnLayoutSidebar = () => {
  const [collapsed, setCollapsed] = useState(false);
  return (
    <SidebarStyled width={500} trigger={null} collapsible collapsed={collapsed}>
      <Container>
        <ContentContainer>
          <TurnHeader collapsed={collapsed} setCollapsed={setCollapsed} />
          <ContainerTurnList>
            <TurnList collapsed={collapsed}  />
          </ContainerTurnList>
          {!collapsed && <TurnConfigurations />}
        </ContentContainer>
        <button className='btn-collapsed' type='button' onClick={() => setCollapsed(!collapsed)}>
          <ArrowBtn />
          <div className='arrow'>{!collapsed ? <ArrowLeft /> : <ArrowRight />}</div>
        </button>
      </Container>
    </SidebarStyled>
  );
};

export default TurnLayoutSidebar;
const ContainerTurnList = styled.div`
  flex: 1;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    width: 8px;
    border-radius: 8px;
    height: 8px;
  }
`;
const ContentContainer = styled.div`
  flex: 1;
  align-self: stretch;
  background: #fff;
  display: flex;
  flex-direction: column;
`;
const Container = styled.div`
  display: flex;
  position: absolute;
  inset: 0;
  padding-right: 8px;
  align-items: center;  
  .btn-collapsed {
    position: relative;
    .arrow {
      position: absolute;
      top: 50%;
      transform: scale(1.5) translateY(-50%);
      margin-top: 5px;
    }
  }
`;
const SidebarStyled = styled(Sidebar)`
  &.ant-layout-sider {
    background-color: #EAEDED;
    &.ant-layout-sider-collapsed {
      max-width: 156px !important;
      min-width: 156px !important;
      width: 156px !important;
      }
    .ant-layout-sider-children {
      position: relative; 
      z-index: 1;
    }
  }

  `;

const ArrowBtn = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="15" height="124" viewBox="0 0 15 124" fill="none">
    <path d="M0 0L2.16114 12.0523C2.97025 16.5646 5.30493 20.6619 8.7743 23.6584L11.5364 26.044C13.7358 27.9435 15 30.7059 15 33.612V91.1212C15 94.2522 13.5335 97.2025 11.0376 99.0929L9.14306 100.528C4.90889 103.735 2.12427 108.494 1.40334 113.757L0 124V0Z" fill="#7B7B7B" />
  </svg>
);

const ArrowLeft = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
    <path fillRule="evenodd" clipRule="evenodd" d="M8.45836 11.207L4.25125 6.99993L8.45836 2.79282L9.16547 3.49992L5.66547 6.99992L9.16547 10.4999L8.45836 11.207Z" fill="white" />
  </svg>
);

const ArrowRight = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
    <path fillRule="evenodd" clipRule="evenodd" d="M5.54158 2.79297L9.74869 7.00008L5.54158 11.2072L4.83447 10.5001L8.33447 7.00008L4.83447 3.50008L5.54158 2.79297Z" fill="white" />
  </svg>
);
