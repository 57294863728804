import { get } from 'lodash';
import { RootState } from 'store';
import { useAppSelector } from 'store/hooks';
type MyState = RootState['shop'];

const getCurrentState = (state: RootState): MyState => state['shop'];

const selector = <T = MyState>(key: keyof T, defaultValue?: any) => useAppSelector(state => get(getCurrentState(state), key, defaultValue));

const shopSelectors = {
  staff: () => selector('staffs') as MyState['staffs'],
  customers: () => selector('customers') as MyState['customers'],
  categories: () => selector('categories') as MyState['categories'],
  allServices: () => selector('allServices') as MyState['allServices'],
  scheduleBooking: () => selector('scheduleBooking') as MyState['scheduleBooking'],
};
export default shopSelectors;
