import React from 'react';
import styled from 'styled-components';
type Props = {
  number?: number;
};
const TurnHeadItem = ({ number }: Props) => {
  return (
    <Container>
      <div className="box-item">
        <div className='box-turn'><span>{number}</span></div>
      </div>
    </Container>
  );
};

export default TurnHeadItem;

const Container = styled.th`
  padding-right: 4px;
  padding-bottom: 8px;
  .box-item {
    width: 65px;
    display: flex;
    align-items: center; 
    justify-content: center;
  }
  .box-turn {
    width: 45px;
    height: 45px;
    border-radius: 40px;
    background-color: #CECECE;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;