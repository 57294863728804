import { signal } from '@preact/signals-react';
import { Spin } from 'antd';
import React from 'react';
import { styled } from 'styled-components';

export const loadingPageSignal = signal(false);

const LoadingPage = () => {
  if (!loadingPageSignal.value) return null;

  return (
    <LoadingStyled ><Spin spinning /></LoadingStyled>
  );
};

export default LoadingPage;

const LoadingStyled = styled.div`
position: fixed;
inset: 0;
z-index: 9999;
display: flex;
align-items: center;
background: rgba(255, 255, 255, 0.6);
justify-content: center;
`;