import { computed } from '@preact/signals-react';
import { Tabs, TabsProps } from 'antd';
import Modal from './Modal';
import { memo, useMemo, useState } from 'react';
import aptActions from 'services/actions/apt';
import { IPayloadBooking } from 'services/types/request';
import { useAppDispatch } from 'store/hooks';
import styled from 'styled-components';
import storage from 'utils/sessionStorage';
import CustomerTab from './CustomerTab';
import ReviewTab from './ReviewTab';
import ServiceTab from './ServiceTab';
import StaffTab from './StaffTab';
import TimeTab from './TimeTab';
import { ADD_APPOINTMENT_TABS, ANYONE_NAILS_ID } from './helper';
import aptSignal from './signal';
import { loadingSignal } from 'components/Loading';
import ModalReason from './ModalReason';
import { AppointmentStatus } from 'Turn/services/constants';
import { modalConfirmSMSChangeRef } from './ModalConfirmSMSChange';
import { set } from 'lodash';

const NewAppointment = () => {
  const dispatch = useAppDispatch();
  const [visibleReason, setVisibleReason] = useState(false);
  const activeKey = computed<string>(() => aptSignal.tabSignal.value).value;

  const isValid = computed(() => {
    const { customerSignal, staffIdSignal, timeSignal, servicesSignal, tabSignal } = aptSignal;

    return !!customerSignal.value?.id
      && !!staffIdSignal.value && !!timeSignal.value
      && !!servicesSignal.value?.length
      && tabSignal.value === ADD_APPOINTMENT_TABS.REVIEW;
  }).value;

  const items: TabsProps['items'] = useMemo(() => {
    const CUSTOMER = { key: ADD_APPOINTMENT_TABS.CUSTOMER, label: <CustomerTab.Label />, children: <CustomerTab.Children /> };
    const STAFF = { key: ADD_APPOINTMENT_TABS.STAFF, label: <StaffTab.Label />, children: <StaffTab.Children /> };
    const TIME = { key: ADD_APPOINTMENT_TABS.TIME, label: <TimeTab.Label />, children: <TimeTab.Children /> };
    const SERVICES = { key: ADD_APPOINTMENT_TABS.SERVICE, label: <ServiceTab.Label />, children: <ServiceTab.Children /> };
    const REVIEW = { key: ADD_APPOINTMENT_TABS.REVIEW, label: <ReviewTab.Label />, children: <ReviewTab.Children /> };
    if (aptSignal.detailIdSignal.value) {
      return ([STAFF, TIME, SERVICES, REVIEW]);
    }
    return ([CUSTOMER, STAFF, TIME, SERVICES, REVIEW]);
  }, [aptSignal.detailIdSignal.value]);

  const handleDone = () => {
    const values = aptSignal.getValues();

    const startTime = values.time?.format('MM/DD/YYYY HH:mm') || '';
    const endTime = values.time?.clone().add(values.totalDuration, 'minute')?.format('MM/DD/YYYY HH:mm') || '';
    const staffId = values.staffId?.toString();
    const data: IPayloadBooking = {
      type: 'FEEDBACK/DASHBOARD/ON_CREATE_APPOITMENT',
      payload: {
        customerId: +(values.customer?.id || ''),
        customerName: values.customer?.name || '',
        type: 1,
        serviceIds: values.services.map(o => +o),
        staffId: staffId === ANYONE_NAILS_ID ? null : +(values.staffId || ''),
        totalPeople: values.totalPeople,
        startTime,
        endTime,
        note: values.note,
        isAnybody: staffId === ANYONE_NAILS_ID ? 1 : 0,
        apiKey: storage.shopId.get(),
        requestStaff: values.requestStaff,
      }
    };

    dispatch(aptActions.bookApt(data));
  };

  const handleClose = () => {
    aptSignal.close();
  };

  const handleDelete = () => {
    if (!aptSignal.detailIdSignal.value) return;
    dispatch(aptActions.deleteApt(aptSignal.detailIdSignal.value));
  };

  const handleUpdate = () => {
    if (!aptSignal.detailIdSignal.value) return;
    const values = aptSignal.getValues();

    const startTime = values.time?.format('MM/DD/YYYY HH:mm') || '';
    const endTime = values.time?.clone().add(values.totalDuration, 'minute')?.format('MM/DD/YYYY HH:mm') || '';
    const staffId = values.staffId?.toString();
    const isReUpdateWalkIn = values.customer?.id === 're-update-walk-in';
    const data: IPayloadBooking = {
      type: 'FEEDBACK/DASHBOARD/ON_EDIT_APPOITMENT',
      payload: {
        id: +aptSignal.detailIdSignal.value,
        customerId: values.customer?.id !== 're-update-walk-in' ? +(values.customer?.id || 0) : 0,
        customerName: values.customer?.name || '',
        type: 1,
        serviceIds: values.services.map(o => +o),
        staffId: staffId === ANYONE_NAILS_ID ? null : +(values.staffId || ''),
        totalPeople: values.totalPeople,
        startTime,
        endTime,
        note: values.note,
        isAnybody: staffId === ANYONE_NAILS_ID ? 1 : 0,
        apiKey: storage.shopId.get(),
        requestStaff: values.requestStaff,
      }
    };
    if (isReUpdateWalkIn && values.customer?.phone) {
      set(data.payload, 'phone', values.customer?.phone);
    }
    dispatch(aptActions.updateApt(data));
    modalConfirmSMSChangeRef.current?.open(aptSignal.detailIdSignal.value);
  };

  const onCancelApt = () => setVisibleReason(true);

  const onConfirmApt = () => {
    if (!aptSignal.detailIdSignal.value) return;
    dispatch(aptActions.confirmApt(aptSignal.detailIdSignal.value));
  };

  const handleCancelApt = (reason: string) => {
    setVisibleReason(false);
    if (!aptSignal.detailIdSignal.value) return;
    dispatch(aptActions.cancelApt({ aptId: aptSignal.detailIdSignal.value, reason }));
  };


  return (
    <>
      <Modal
        title={aptSignal.detailIdSignal.value ? 'DETAIL APPOINTMENT' : 'BOOK APPOINTMENT'}
        open={aptSignal.openSignal.value}
        okText={isValid ? 'BOOK APPOINTMENT' : 'NEXT'}
        onOk={isValid ? handleDone : aptSignal.next}
        disabledOk={aptSignal.getDisabled()}
        visibleOk={!isValid}
        aptFooter={isValid}
        updateAptFooter={isValid && !!aptSignal.detailIdSignal.value}
        onCancel={handleClose}
        onDelete={handleDelete}
        onUpdate={handleUpdate}
        onCancelApt={onCancelApt}
        onConfirmApt={onConfirmApt}
        confirmed={aptSignal.detailStatusSignal.value === AppointmentStatus.CONFIRMED}
        width='90vw'
        className='modal-max-height-unset'
        loading={loadingSignal.value}
      >
        <TabsStyled
          defaultActiveKey="1"
          items={items}
          activeKey={activeKey}
          className='cs-ant-tabs'
          onChange={aptSignal.setActiveKey}
          // @ts-ignore
          indicatorSize={(origin: number) => origin - 16}
          centered
        />
      </Modal>
      <ModalReason visible={visibleReason} onClose={() => setVisibleReason(false)} onConfirm={handleCancelApt} />
    </>
  );
};

export default memo(NewAppointment);

const TabsStyled = styled(Tabs)`
  padding-top: 1rem;
  flex-grow: 1;
  &.cs-ant-tabs.ant-tabs .ant-tabs-ink-bar {
    background: #232F3E;
  }
`;
