import { Header } from 'antd/es/layout/layout';
import Nav from 'components/Nav';
import NavAptList from 'components/Nav/NavAptList';
import React from 'react';
import { useLocation } from 'react-router-dom';

const TurnNav = () => {
  const _location = useLocation();
  return (
    <Header style={{ padding: 0, height: 'unset' }}>
      {_location.pathname.includes('/turn/list') ? <NavAptList turn /> : <Nav turn />}
    </Header>
  );
};

export default TurnNav;
