import moment, { Moment } from 'moment';
export type ITimelineItem = {
  id: string;
  value: Moment;
}

export const setTime = (hour: number, minute?: number, second?: number) => moment().set({ hour, minute: minute || 0, second: second || 0 });

const getTimeLines = (startTime: Moment, endTime: Moment, distance?: number) => {
  const _distance = distance || 30;
  const times: ITimelineItem[] = [];
  let time = startTime;

  while (time <= endTime) {
    times.push({
      id: time.format('HH:mm'),
      value: time,
    });
    time = time.clone().add(_distance, 'minute');
  }
  return times;
};

export default getTimeLines;
