import { ANYONE } from 'Calendar/anyone';
import { AutoComplete, Avatar, Empty, Input, InputRef, Row } from 'antd';
import Text from 'components/Text';
import { forwardRef, useImperativeHandle, useMemo, useRef, useState } from 'react';
import shopSelectors from 'services/selectors/shop';
import styled from 'styled-components';
import { IStaffUI } from 'types/staff';
type Props = {
  onSelect: (data: IStaffUI) => void;
}
type IInputSearchRef = {
  setStaff: (staff: IStaffUI | null) => void;
};
export const useInputSearchRef = () => useRef<IInputSearchRef>(null);
const InputSearchAvatar = forwardRef<IInputSearchRef, Props>(({ onSelect }, ref) => {
  const inputRef = useRef<InputRef>(null);
  const staffs = shopSelectors.staff();
  const [staff, setStaff] = useState<IStaffUI | null>(null);

  const initialOptions = useMemo(() => {
    return staffs.map(o => {
      return ({
        value: o.id,
        name: o.name,
        keywords: [o.name?.toLocaleLowerCase()],
        label: renderLabel(o.name, o.avatar),
      });
    });
  }, [staffs]);

  const [searchText, setSearchText] = useState<string>('');

  const options = useMemo(() => {
    if (!searchText) return initialOptions;
    return initialOptions.filter(o => o?.name?.toLowerCase()?.includes(searchText.toLowerCase()));
  }, [initialOptions, searchText]);

  const handleSwitchCustomer = (data: string) => {
    inputRef.current?.blur();
    const result = staffs?.find((item) => item.id === data);
    if (!result) return;
    onSelect(result);
    setStaff(result);
    setSearchText(result.name);
  };

  // const staff = useMemo(() => staffId ? staffs.find(o => o.id === staffId) : ANYONE, [staffId, staffs]);

  useImperativeHandle(ref, () => ({
    setStaff: _staff => {
      setStaff(_staff);
      setSearchText(_staff?.name || '');
    },
  }));

  return (
    <InputSearchStyled wrap={false} align={'middle'}>
      <AutoComplete
        style={{ width: '100%' }}
        options={options}
        notFoundContent={<Empty />}
        onSelect={handleSwitchCustomer}
        value={searchText}
        onChange={setSearchText}
      >
        <Input
          ref={inputRef}
          prefix={
            staff ?
              <Avatar src={staff?.avatar} >{staff?.name?.[0] || ''}</Avatar>
              :
              <Avatar src={ANYONE?.avatar} >{ANYONE?.name?.[0] || ''}</Avatar>
          }
          placeholder="Staff Name"
        />
      </AutoComplete>
    </InputSearchStyled>
  );
});
InputSearchAvatar.displayName = 'InputSearchAvatar';
export default InputSearchAvatar;

const InputSearchStyled = styled(Row)`
  gap: 1rem;  
  .ant-select-single {
    height: 56px;
    .ant-select-selector {
      height: 100%;
      .ant-select-selection-search-input {
        height: 100%;
        font-size: 1.2rem;
        font-weight: 500;
        font-family: Poppins;
      }
    }
  }
`;

const renderLabel = (name: string, avatar: string) => (
  <Row align={'middle'}>
    <Avatar src={avatar} >{name?.[0] || ''}</Avatar>
    <Text ml={1} variant="CONTENT_2" color="text_3">
      {name}
    </Text>
  </Row>
);
