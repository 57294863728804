import SockJS from 'sockjs-client';
import { Client, over } from 'stompjs';

export const initSocket = (onClose?: () => void): Client => {
    const socket = new SockJS('https://api.ontiloo.com/api/v1/gs-guide-websocket');
    if (onClose) socket.addEventListener('close', onClose);
    return over(socket);
};

export const APPOINTMENT_TOPIC = '/topic/book-appointment-completed';
export const SYNC_TURN = '/topic/turn';