import AppointmentList from 'AppointmentList';
import NavAptList from 'components/Nav/NavAptList';
import Text from 'components/Text';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import aptActions from 'services/actions/apt';
import { useAppDispatch } from 'store/hooks';
import styled from 'styled-components';
import storage from 'utils/sessionStorage';

const AppointmentListPage = () => {
  const { shop_id } = useParams();

  const dispatch = useAppDispatch();
  useEffect(() => {
    // @ts-ignore
    window.parent.document.body.style.zoom = 1;
    dispatch(aptActions.initData(shop_id || storage.shopId.get()));
  }, []);
  return (
    <PageStyled>
      <NavAptList />
      <Text mb={1.5} />
      <AppointmentList />
    </PageStyled>
  );
};

export default AppointmentListPage;

const PageStyled = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background: #EAEDED;
`;
