import { DatePicker as DatePickerAnt, Row } from 'antd';
import React from 'react';
import styled from 'styled-components';
import type { Moment as MomentType } from 'moment';
import momentGenerateConfig from 'rc-picker/lib/generate/moment';
import moment from 'moment';
import IconCalendar from 'assets/Icons/iconDate';
const DatePickerAntUI = DatePickerAnt.generatePicker<MomentType>(momentGenerateConfig);
export type IDatePickerValue = MomentType | null | undefined;
type Props = {
  value?: IDatePickerValue;
  onChange?: (value: IDatePickerValue) => void;
};
const DatePicker = ({ value, onChange = () => undefined }: Props) => {
  const _handleChange = (d: moment.Moment | null) => {
    onChange(d);
  };
  return (
    <WrapDateTime align={'middle'}>
      <DatePickerStyled
        value={value}
        onChange={_handleChange}
        suffixIcon={false}
        clearIcon={false}
        allowClear={false}
        inputReadOnly
        format={'LL'}
        placement='bottomLeft'
        disabledDate={(current: MomentType) => current.isBefore(moment().subtract(1, 'day'))}
      />
      <div className='icon-right'><IconCalendar /></div>
    </WrapDateTime>
  );
};

export default DatePicker;

const WrapDateTime = styled(Row)`
border-radius: 5px;
border: 1px solid #7B7B7B;
background: #FFF;
display: flex;
height: 56px;
justify-content: space-between;
align-items: center;
flex-shrink: 0;
position: relative;

.icon-right {
  position: absolute;
  right: 16px;
  display: flex;
}
.ant-picker .ant-picker-input >input {
  font-size: 1.2rem;
  text-align: left;
}
`;


const DatePickerStyled = styled(DatePickerAntUI)`
  background: none;
  border: none;
  padding:0;
  flex:1;
  
  input {
    text-align: right;
    color: #1D2129;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 150% */
    padding: 0px 16px !important;
  }

  &.ant-picker:hover,
  &.ant-picker-focused {
    border: none;
    box-shadow: none;
  }

`;
