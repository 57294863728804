import { signal } from '@preact/signals-react';
import { Spin } from 'antd';
import React from 'react';
import { styled } from 'styled-components';

export const loadingSignal = signal(false);

const LoadingCalendar = () => {
  if (!loadingSignal.value) return null;

  return (
    <LoadingStyled ><Spin spinning /></LoadingStyled>
  );
};

export default LoadingCalendar;

const LoadingStyled = styled.div`
position: absolute;
inset: 0;
z-index: 99;
display: flex;
align-items: center;
background: rgba(255, 255, 255, 0.6);
justify-content: center;
`;