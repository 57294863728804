import { computed } from '@preact/signals-react';
import TabLabel from 'AppointmentBox/TabLabel';
import { ADD_APPOINTMENT_TABS, ANYONE_NAILS_ID } from 'AppointmentBox/helper';
import aptSignal from 'AppointmentBox/signal';
import { Col, Row, Spin } from 'antd';
import { AxiosResponse } from 'axios';
import moment from 'moment';
import { useCallback, useEffect, useState } from 'react';
import apis from 'services/apis';
import styled from 'styled-components';
import { FORMAT_TIME } from 'utils/format';
import storage from 'utils/sessionStorage';
import DatePicker, { IDatePickerValue } from './DatePicker';
import Time from './Time';
import Text from 'components/Text';

const Label = () => {
  const activeKey = computed<string>(() => aptSignal.tabSignal.value).value;
  const time = computed(() => aptSignal.timeSignal.value).value;

  return (
    <TabLabel
      label='Time'
      tabIndex={3}
      completed={!!time}
      active={activeKey === ADD_APPOINTMENT_TABS.TIME}
    />
  );
};
const setActiveDate = (date: IDatePickerValue) => {
  aptSignal.activeDateSignal.value = date;
};
const Children = () => {
  const activeDate = computed(() => aptSignal.activeDateSignal.value).value;
  const [loading, setLoading] = useState<boolean>(false);
  const [slots, setSlots] = useState<string[]>([]);

  const selected = aptSignal.timeSignal.value?.format(FORMAT_TIME);

  const handleChooseDate = (timeStr: string) => () => {
    const time = moment(timeStr, FORMAT_TIME);
    const newDateValue = activeDate?.clone().set({
      hour: time.get('hour'),
      minute: time.get('minute'),
      second: time.get('second'),
    });
    aptSignal.timeSignal.value = newDateValue;
    aptSignal.next();
  };

  const fetchData = async () => {
    setLoading(true);
    const staffId = aptSignal.staffIdSignal.value === ANYONE_NAILS_ID ? '' : aptSignal.staffIdSignal.value;
    try {
      const res: AxiosResponse<{ payload: { timeSlots: string[] } }> = await apis.getTimeSlot(storage.shopId.get(), activeDate?.format('YYYY-MM-DD') || '', staffId || '');
      const timeSlots = res?.data?.payload?.timeSlots;
      if (timeSlots) setSlots(timeSlots);
    } catch (error) { }
    finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [activeDate, aptSignal.staffIdSignal.value]);

  const TimeSlots = useCallback(() => {
    if (loading) return <Row align={'middle'} justify={'center'} style={{ flex: 1 }}><Spin spinning /></Row>;
    return (
      <>
        {slots.map(o => (
          <Col key={o} span={4} sm={3}>
            <Time data={o}
              onClick={handleChooseDate(o)} />
          </Col>
        ))}
      </>
    );
  }, [slots, loading]);

  return (
    <TimeTabStyled>
      <DatePicker
        value={activeDate}
        onChange={setActiveDate}
      />
      {selected && <>
        <Text mt={1} variant='LABEL_SECTION' >YOUR TIME: </Text>
        <Times gutter={[16, 16]}>
          <Col span={4} sm={3}><Time data={selected} active /></Col>
        </Times>
      </>}
      <Text mb={1} />
      <Times gutter={[16, 16]}>
        <TimeSlots />
      </Times>
    </TimeTabStyled>
  );
};

const TimeTab = {
  Label,
  Children,
};
export default TimeTab;

const TimeTabStyled = styled.div`
  height: 100%;
`;

const Times = styled(Row)`
  margin-top: 12px;
`;
