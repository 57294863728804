import { signal } from '@preact/signals-react';
import { IDatePickerValue } from 'components/DatePicker';
import moment from 'moment';
import { ICustomerUI } from 'types/customer';
import { IBodyApiQuickBooking } from 'services/types/quickBooking';
import { ANYONE_ID } from 'Calendar/anyone';
const openSignal = signal<boolean>(false);
const openDropDownCs = signal<boolean>(false);
const staffs = signal<string[]>([]);
const requestStaff = signal<boolean>(false);
const currentDate = signal<IDatePickerValue | null>(moment());
const startTime = signal<string>('');
const duration = signal<number>(15);
const customer = signal<ICustomerUI | null>(null);
const services = signal<{ value: string, label: string, id: number | string }[]>([]);

const qbSignal = {
  openSignal,
  requestStaff,
  customer,
  startTime,
  duration,
  staffs,
  services,
  currentDate,
  openDropDownCs,
  open: (staffId: string, _startTime: string) => {
    openSignal.value = true;
    currentDate.value = moment(_startTime);
    startTime.value = currentDate.value.format('hh:mm A');
    duration.value = 30;
    staffs.value = [staffId];
    requestStaff.value = false;
    customer.value = null;
    services.value = [];
  },
  close: () => {
    openSignal.value = false;
  },
  getValues: () => {
    const timeStr = `${currentDate.value?.format('MM-DD-YYYY')} ${startTime.value}`;
    const _startTime = moment(timeStr, 'MM-DD-YYYY hh:mm A').format('MM/DD/YYYY HH:mm');
    return staffs.value.map(o => ({
      customerId: parseInt(customer.value?.id || '') || '',
      bookingType: 0,
      duration: duration.value,
      isAnybody: o === ANYONE_ID ? 1 : 0,
      note: '',
      requestStaff: requestStaff.value,
      services: services.value.map(s => ({
        id: s.id || 0,
        serviceName: s.label,
        price: 0,
      })),
      staffId: parseInt(o),
      startTime: _startTime,
      totalPeople: 1,
    } as IBodyApiQuickBooking));
  },
};

export default qbSignal;
