import { ANYONE } from 'Calendar/anyone';
import Action from 'components/Nav/Action';
import { IconNewApt } from 'components/Nav/icons';
import moment from 'moment';
import qbSignal from './signal';
import { CALENDAR_TIME_SET } from 'constants/calendar';

const ButtonQuickBooking = () => {
  const getCurrentTimeSlot = () => {
    const now = moment().add(15, 'minute');
    const nowTime = [0, 15, 30, 45, 60].map(o => ({ key: o, value: (now.get('minute') - o) })).filter(o => o.value >= 0);
    const minute = nowTime.find(o => o.value === Math.min(...nowTime.map(o => o.value)))?.key;
    const startTime = moment().set({
      hour: now.get('hour'),
      minute: minute ?? now.get('minute'),
      second: 0,
    });
    const { close, open } = CALENDAR_TIME_SET;
    if (startTime.isBetween(open, close))
      return startTime.format();
    return open.clone().add(1, 'day').format();
  };

  const handleQuickBooking = () => qbSignal.open(ANYONE.id, getCurrentTimeSlot());
  return (
    <>
      <Action icon={IconNewApt} title='Quick Booking' onClick={handleQuickBooking} />
    </>
  );
};

export default ButtonQuickBooking;
