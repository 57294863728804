import React, { memo, useEffect, useState } from 'react';
import { Number } from './Number';
import { Word } from './Word';
import styled from 'styled-components';
import moment from 'moment';

const AlarmClockUI = ({ h24 = false }: { h24?: boolean }) => {

  const [hour, setHour] = useState(0);
  const [minute, setMinute] = useState(0);
  const [pm, setPm] = useState(false);
  useEffect(() => {

    const update = () => {
      const date = new Date();
      let hour = date.getHours();
      if (!h24) {
        hour = (hour % 12) || 12;
      }
      setHour(hour);
      setMinute(date.getMinutes());
      setPm(date.getHours() >= 12);
    };

    update();

    const interval = setInterval(() => {
      update();
    }, 30000);

    return () => clearInterval(interval);
  }, []);

  return (
    <WrapperAlarm>
      <div className='clock'>
        <div className='row'>
          <div className='hour'>
            <Number value={hour} />
            <Word value={':'} />
            <Number value={minute} />
            <Word value={':'} hidden />
            <div className="digital">{pm ? 'PM' : 'AM'}</div>
          </div>
        </div>
      </div>
    </WrapperAlarm>
  );
};

export default memo(AlarmClockUI);
export const Today = () => (
  <WrapperAlarm>
    <div className='calendar' style={{ marginLeft: '0.5rem' }}>
      <Word value={moment().format('ddd, MMM D, YYYY')} />
    </div>
  </WrapperAlarm>
);

const WrapperAlarm = styled.div`
.clock {
  border-radius: 10px;
  display: flex;
  align-items: start;
  overflow: hidden;
}

.calendar {
  font-size: 24px;
  line-height: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 8px;
  justify-content: center;
  text-align: center;
  align-self: center;
  -webkit-user-select: none;
  /* Safari */
  -ms-user-select: none;
  /* IE 10 and IE 11 */
  user-select: none;
  /* Standard syntax */
  .digital {
    p {
      // font-weight: 800;
    }
  }
}

.row {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.hour {
  flex: 1;
  margin: 0;
  padding: 0;
  top: 0;
  -webkit-user-select: none;
  /* Safari */
  -ms-user-select: none;
  /* IE 10 and IE 11 */
  user-select: none;
  /* Standard syntax */
  display: flex;
  align-items: center;
}

.digital {
  position: relative;
  font-family: 'Digital-7';
  color: #FFDBA7;
  font-size: 40px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
  &.symbol {
  }
}

.digital :first-child {
  width: 100%;
  position: absolute;
  color: transparent;
}

.digital :last-child {
  position: relative;
  color: #FFDBA7;
  font-weight: 500;
}

.digital p {
  margin: 0;
}
 `;