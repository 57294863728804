import InputSearch from 'AppointmentBox/CustomerTab/InputSearch';
import InputSearchByName from 'AppointmentBox/CustomerTab/InputSearchByName';
import { InputRef } from 'antd';
import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import { ICustomerUI } from 'types/customer';
import { formatPhone } from 'utils/formatPhone';
import qbSignal from './signal';
export const inputPhoneRef = React.createRef<InputRef>();
enum ESearchBy {
  PHONE = 'PHONE',
  NAME = 'NAME'
}

const CustomerSection = () => {
  const [searchBy, setSearchBy] = useState<ESearchBy>(ESearchBy.PHONE);
  const inputRef = useRef<InputRef>(null);

  const open = qbSignal.openDropDownCs.value;
  const setOpen = (val: boolean) => {
    qbSignal.openDropDownCs.value = val;
  };

  const handleChooseCustomer = (o: ICustomerUI) => {
    qbSignal.customer.value = o;
  };
  const onClear = () => {
    qbSignal.customer.value = null;
  };

  const onChangeSearchBy = (val: ESearchBy) => {
    setSearchBy(val);
    setOpen(true);
    setTimeout(() => {
      if (val === ESearchBy.NAME)
        inputRef.current?.focus();
      else
        inputPhoneRef.current?.focus();
    }, 100);
  };

  return (
    <SearchContainer>
      <div className='top-filter'>
        <span className="customer-label">CUSTOMER</span>
        <div className="tags">
          <span>Search By: </span>
          <button onClick={() => onChangeSearchBy(ESearchBy.PHONE)} className={`tag-item ${searchBy === ESearchBy.PHONE ? 'active' : ''}`}>Phone</button>
          <button onClick={() => onChangeSearchBy(ESearchBy.NAME)} className={`tag-item ${searchBy === ESearchBy.NAME ? 'active' : ''}`}>Name</button>
        </div>
      </div>
      <div className='wrapper-input'>
        {searchBy === ESearchBy.PHONE && <InputSearch open={open} setOpen={setOpen} inputRef={inputPhoneRef} onSelect={handleChooseCustomer} />}
        {searchBy === ESearchBy.NAME && <InputSearchByName open={open} setOpen={setOpen} inputRef={inputRef} onSelect={handleChooseCustomer} />}
        <div className='customer-selected'>
          {qbSignal.customer.value?.name || 'Walk-In - (000) 000-0000'} {qbSignal.customer.value?.phone ? ' - ' + formatPhone(qbSignal.customer.value?.phone || '') : ''}
          {qbSignal.customer.value &&
            <button type='button' className='btn-clear-staff' onClick={onClear}>
              <span>
                <IconClear />
              </span>
            </button>}
        </div>
      </div>
    </SearchContainer>
  );
};

export default CustomerSection;

const SearchContainer = styled.div`
  .wrapper-input {
    .ant-input, .phone-input.ant-select-selection-search-input {
      font-size: 22px;
      height: 50px;
    }

    position: relative;
    .customer-selected {
      display: flex;
      justify-content: center;
      align-items: center;
      .btn-clear-staff {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 38px;
        height: 38px;
        span {
          display: flex;
          width: 24px;
          height: 24px;
          justify-content: center;
          align-items: center;
          gap: 10px;
          border-radius: 5px;
          border: 1px solid #CECECE;
          background: #FFF;
        }
      }
      background: #fff;
      padding: 0 8px;
      font-family: Poppins;
      font-size: 22px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      color: #1D2129;
      position: absolute;
      top: 50%;
      right: 64px;
      transform: translateY(-50%);
    }
  }
  .top-filter {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 36px;
    margin-bottom: 8px;
    .customer-label {
      color: #7B7B7B;
      font-family: Poppins;
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px; /* 120% */}
  }
  .tags {
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    color: #1D2129;
    gap: 8px;
    display: flex;
    align-items: center;
    align-self: stretch;
    button {
      align-self: stretch;  
      font-family: Poppins;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      color: #7B7B7B;
      padding: 0px 16px;
      border-radius: 2px;
      border: 1px solid #7B7B7B;
      background: #fff;
      border-radius: 8px;
      &.active {
        background: #232F3E;
        border-color: #232F3E;
        color: #fff;
      }
    }
  }
`;
const IconClear = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <path fillRule="evenodd" clipRule="evenodd" d="M14.8186 1.18089C15.1951 1.55748 15.1951 2.16805 14.8186 2.54464L2.54488 14.8183C2.1683 15.1949 1.55773 15.1949 1.18114 14.8183C0.804554 14.4417 0.804555 13.8312 1.18114 13.4546L13.4548 1.18089C13.8314 0.804307 14.442 0.804307 14.8186 1.18089Z" fill="#148CF1" />
    <path fillRule="evenodd" clipRule="evenodd" d="M1.18146 1.18089C1.55804 0.804307 2.16861 0.804307 2.5452 1.18089L14.8189 13.4546C15.1955 13.8312 15.1955 14.4417 14.8189 14.8183C14.4423 15.1949 13.8317 15.1949 13.4551 14.8183L1.18146 2.54464C0.804869 2.16805 0.804869 1.55748 1.18146 1.18089Z" fill="#148CF1" />
  </svg>
);
