import usePassCodeVerify from 'Turn/TurnModals/PassCodeVerify';
import turnWKSignal from 'Turn/TurnModals/TurnStaffDetail/signal';
import turnActions from 'Turn/services/actions';
import turnApis from 'Turn/services/apis';
import turnSelectors from 'Turn/services/selectors';
import { IStaffTurnItem, IStaffTurnTicketItem } from 'Turn/services/types/staff';
import { AxiosResponse } from 'axios';
import { loadingPageSignal } from 'components/LoadingPage';
import { useAppDispatch } from 'store/hooks';
import styled from 'styled-components';
import StaffTurnItem from './StaffTurnItem';
import { Spin } from 'antd';
import { useMemo } from 'react';
import { sortBy } from 'lodash';
type Props = {
  collapsed?: boolean;
};
const TurnList = ({ collapsed }: Props) => {
  const [verifyRef, pinCodeContext] = usePassCodeVerify();
  const _staffs = turnSelectors.staffs();

  const staffs = useMemo(() => {
    return sortBy(_staffs,[
      o=>!o.isBusy,
      o=>!o.isLockedin,
      o=>o.turn,
    ]);
  }, [_staffs]);

  const loading = turnSelectors.loading();
  const dispatch = useAppDispatch();
  const handleClickStaff = (o: IStaffTurnItem) => async () => {
    if (o.isBusy || o.isLockedin) {
      loadingPageSignal.value = true;
      try {
        const res: AxiosResponse<{ payload: { tickets: IStaffTurnTicketItem[] } }> = await turnApis.getOpenTicketsByStaffId(o.id.toString());
        if (res.data.payload) {
          dispatch(turnActions.getOpenTicketsByStaffId.success(res.data.payload.tickets));
          turnWKSignal.open(o);
        }
      } catch (error) {
      } finally {
        loadingPageSignal.value = false;
      }
    }
  };

  const onClockIn = (o: IStaffTurnItem) => () => {
    verifyRef.current?.open(async (pinCode: string) => {
      try {
        const res: { data: { payload: boolean } } = await turnApis.checkIn(o.id, pinCode);
        if (res.data.payload) {
          dispatch(turnActions.data.listStaffs.fetch());
          return true;
        }
        return false;
      } catch (error) {
        return false;
      }
    }, {
      title: 'CLOCK-IN',
      placeholder: 'Enter Staff Pin Code',
    });
  };

  const onClockOut = (o: IStaffTurnItem) => () => {
    verifyRef.current?.open(async (pinCode: string) => {
      try {
        const res: { data: { payload: boolean } } = await turnApis.checkOut(o.id, pinCode);
        if (res.data.payload) {
          dispatch(turnActions.data.listStaffs.fetch());
          return true;
        }
        return false;
      } catch (error) {
        return false;
      }
    }, {
      title: 'CLOCK-OUT',
      placeholder: 'Enter Staff Pin Code',
    });
  };

  return (
    <ContainerList className={`${collapsed && 'collapsed'}`}>
      {loading && <Spin />}
      {staffs.map(o => (
        <div key={o.id} onClick={handleClickStaff(o)}>
          <StaffTurnItem onClockIn={onClockIn(o)} onClockOut={onClockOut(o)} collapsed={collapsed} data={o} />
        </div>
      ))}
      {pinCodeContext}
    </ContainerList>
  );
};

export default TurnList;

const ContainerList = styled.div`
  padding: 16px;
  padding-right: 12px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  &.collapsed {
  padding: 8px;
  padding-right: 6px;
  }
`;