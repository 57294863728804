import React from 'react';
import Modal from '../Modal';
import styled from 'styled-components';

const TurnStaffLogout = () => {
  return (
    <Modal title='HARLEY' width='583px' height={'auto'}>
      <Container>
        <div className="actions">
          <button className="action-item clock-in"><span>CLOCK-IN</span></button>
        </div>
      </Container>
    </Modal>
  );
};

export default TurnStaffLogout;

const Container = styled.div`
background: #FFF;
padding: 24px 8px 40px 8px;

.actions {
  .action-item {
    display: flex;
    height: 56px;
    padding: 0px 8px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    width: 100%;
    flex: 1 0 0;

    span {
      color: #FFF;
      text-align: center;
      font-family: Poppins;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      text-transform: uppercase;
    }

    &.clock-in {
      background: #7B7B7B;
    }
  }
}
`;